.obrou-portal {
    .messages-menu {
        ul.dropdown-menu {
            width: 450px;
            min-height: 240px;
            .notifications {
                overflow-y: visible;
                li {
                    &.unread {
                        background-color: #f4f4f4;
                    }
                    position: relative;
                }
                .time {
                    position: absolute;
                    bottom: -1px;
                    right: 4px;
                    padding-right: 5px;
                    color: #999;
                }
            }
        }
    }
    .messages {
        .conversations {
            .item {
                &.unread {
                    background-color: #d2d6de;
                }
                &.actual {
                    border: 1px solid #3c8dbc;
                }
            }
            &.products-list > .item {
                padding: 10px 5px 10px 2px;
            }
        }
        .memembersList{
            max-height: 40px;
            overflow: auto;
        }
        .box.direct-chat {
            .direct-chat-messages {
                height: 100%;
                max-height: 1020px;
                .subject {
                    h3 {
                        margin-top: 6px;
                    }
                }
                .sended {
                    a.subject {
                        color: #d2d6de;
                        &:hover {
                            color: #72afd2;
                        }
                    }
                }
                .direct-chat-text {
                    a {
                        //color: #00c0ef;
                    }
                    .attachments {
                        border-top: 1px solid white;
                    }
                }
                .direct-chat-msg.center
                {
                    margin-bottom: 4px;
                    font-size: smaller;
                    .direct-chat-info{
                        font-size: smaller;
                        margin-bottom: 0px;
                    }
                    .direct-chat-text {
                        margin: 0;
                        background: none;
                        text-align: center;
                        padding: 2px 10px;
                        border-color: #ebecef;
                        &:before,&:after{
                            content: none;
                        }
                    }
                }
            }
        }
        .messageForm {
            margin: 1px 26px 20px;
            form {
                table {
                    width: 100%;
                    tr td:first-of-type {
                        width: 30%;
                    }
                    tr td:nth-of-type(2) {
                        width: 60px;
                    }
                    tr td:last-of-type {
                        text-align: right;
                    }
                }
                .form-group {
                    margin-bottom: 5px;
                    label {
                        margin-bottom: 0px;
                    }
                }
                input[type='text'], select {
                    width: 100%;
                    display: inline-block;
                    height: 34px;
                }
                select {
                    width: 100%;
                }
                textarea {
                    height: 80px;
                    width: 100%;
                }
                .dropdown {
                    display: inline-block;
                    .dropdown-menu[role=input] {
                        padding: 10px 1px 5px 20px;
                        input {
                            margin-right: 10px;
                        }
                    }
                    button {
                        height: 35px;
                    }
                }
            }
        }
    }
}