.obrou-portal {
    .photoAlbum {
        margin-right: 5px;
        img{
            width:32%;
        }
    }
    .ug-gallery-wrapper.ug-lightbox {
        z-index: 1500;
    }
}