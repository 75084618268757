.lastdiscussions{
    .discussionItem{
        li div{
            position: relative;
            max-height: 60px;
            overflow: hidden;
            mix-blend-mode: hard-light;
            &:after {
                content: '';
                position: absolute;
                top: 5px;
                height: 60px;
                width: 100%;
                background: linear-gradient(transparent, gray);
            }
        }
        h4{
            margin-top: -5px;
            margin-bottom: 0px;
        }
        i.commentsCount{
            top: -10px;
            position: relative;
        }
    }
}