// Colours
@c011: #fff;
@c012: #f8f8f8;
@c013: #e2e5e8;
@c014: #c5c8cb;
@c015: #999999;
@c016: #666666;
@c017: #333333;
@c018: #000000;

@c021: #007bf9;
@c022: #005ce5;
@c023: #2490ff;
@c024: #e5f6ff;
    
@c031: #1eb94a;
@c032: #179d4c;
@c033: #32c350;
@c034: #e9fceb;
    
@c041: #e72c23;
@c042: #da001a;
@c043: #ff5d5d;
@c044: #ffeded;
    
@c051: #ff9016;
@c052: #f37200;
@c053: #ffb15b;
@c054: #fff4e8;
    
@c061: #ffe116;
@c062: #f7c600;
@c063: #ffea5b;
@c064: #fffce5;



.bg{
    &.c012{
        background-color: @c012;
    }
}

    

