@import "colours.less";
.uploader-wrap{
    margin-top: 10px;
    .form-error-message:hover{
        cursor: pointer;   
    }
    .delete-icon-style{
        margin: 0px 0 -4px 4px;
    }
    .drop-zone{
        width: 310px;
        height: 80px;
        border: 2px solid @c013;
        margin: 10px 0 10px 0px;
        line-height: 80px;
        color: @c014;
        text-align: center;
        .drop-zone-icon{
            margin: 0 10px -10px 0;
        }

    }
    .delete, .delete-default{
        cursor: pointer;
    }
	
    table.files{
        width: 100%;
        .name{
            font-weight: bold;
            font-size: 12px;
        }
        td{
            border: none;
        }
        tr:first-child {
            td{
				padding-top: 12px;
            } 
        }
        tr:last-child{
            td{
                padding-bottom: 8px;
            }
        }
    }


    .button-holder{
        &.extra-margin{
			margin-bottom: 8px;
        }
    }

    .fileinput-button {
        position: relative;
        overflow: hidden;
        input {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            opacity: 0;
            filter: alpha(opacity=0);
            transform: translate(-300px, 0) scale(4);
            font-size: 23px;
            direction: ltr;
            cursor: pointer;
        }
    }



    .fileupload-buttonbar{
        .btn,
        .toggle {
            margin-bottom: 5px;
        }
    }

    .fileupload-processing .fileupload-loading {
        display: block;
    }
}