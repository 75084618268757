body.obrou-portal {
    table.notificationsSetting {
        tr td, tr th {
            padding: 5px 10px;
        }
        tr th {
            text-align: center;
        }
    }
    .notifications-menu {
        ul.dropdown-menu {
            width: 450px;
            min-height: 240px;
            .notifications {
                overflow-y: visible;
                li {
                    &.unread {
                        background-color: #f4f4f4;
                    }
                    position: relative;
                }
                .time {
                    position: absolute;
                    bottom: -1px;
                    right: 4px;
                    padding-right: 5px;
                    color: #999;
                }
            }
        }
    }
    .navbar-nav>.notifications-menu>.dropdown-menu>li .menu.notifications>li>a{
        padding-bottom: 16px;
        white-space: normal;
    }
}