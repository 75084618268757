.following-events{
    li{
        h6{
            width:100%;
            position: absolute;
            top: -12px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            a{
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: ~"calc(100% - 6px)";
                display: inline-block;
            }
        }
        position: relative;
        margin-top: 10px;
        &.date{
            list-style-image:url(../img/obicons/event.png);
        }
    }
}