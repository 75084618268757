.following-races{
    hr{
        margin: 6px 0 8px -30px;
    }
    li{
        h6{
            width:100%;
            position: absolute;
            top: -12px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            a{
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: ~"calc(100% - 46px)";
                display: inline-block;
            }
        }
        position: relative;
        margin-top: 10px;
        &.OB{
            list-style-image:url(../img/obicons/ob.png);
        }
        &.LOB{
            list-style-image:url(../img/obicons/lob.png);
        }
        &.MTBO{
            list-style-image:url(../img/obicons/mtbo.png);
        }
        &.OST{
            list-style-image:url(../img/obicons/ost.png);
        }
        &.TRAIL{
            list-style-image:url(../img/obicons/trail.png);
        }
        &.training_1{
            list-style-image:url(../img/obicons/map.png);
        }
        &.training_2{
            list-style-image:url(../img/obicons/ob.png);
        }
        &.training_3{
            list-style-image:url(../img/obicons/psy.png);
        }
        &.training_4{
            list-style-image:url(../img/obicons/ost.png);
        }
        &.date{
            list-style-image:url(../img/obicons/event.png);
        }
        &.check{
            list-style-image:url(../img/obicons/check.png);
            line-height: 1.2;
        }
        .green{
            color:#00e400;
        }
    }
    .red{
        color:red;
    }
    .larger{
        font-size: larger;
    }
}