body.obrou-portal .searchResults {
    .widget-user-2 {
        .widget-user-header {
            min-height: 102px;
            .widget-user-image img {
                height: 65px;
            }
        }
    }
    .widget-race {
        .nav-stacked > li > .widget-row {
            border-radius: 0;
            border-top: 0;
            border-left: 3px solid transparent;
            color: #444;
            padding: 10px 15px;
            position: relative;
            display: block;
        }
    }
}

body.obrou-portal #searchForm {
    overflow: visible;
    z-index: 811;
    .easy-autocomplete-container ul {
        top: 36px;
        .eac-category{
            text-align: center;
            border-top: 1px solid black;
            &:first-of-type{
                border-top: none;
            }
        }
        li{
            border-top: 1px solid lightgrey;
            font-size: small;
        }
    }

}