.obrou-portal .discussionPreview {
    table {
        td:last-of-type {
            width: 170px;
        }
        td:nth-of-type(2) {
            width: 100px;
        }
    }
    .desc-wrapper {
        i {
            margin-left: 10px;
        }
    }
}

.obrou-portal .discussionDetail {

    .info {
        margin: 10px 5px 40px 20px;
        table {
            th {
                vertical-align: top;
            }
            td,th{
                padding:2px 4px;
            }
        }
    }
}