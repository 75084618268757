/* @activeColor: #c0392b; //red */
.bodineChecks {
    /* $activeColor: #c0392b; //red */
    /* $background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/13460/dark_wall.png'); */
    /* .slideOne */
    .slideOne {
        width: 50px;
        height: 10px;
        background: #333;
        margin: 20px auto;
        position: relative;
        border-radius: 50px;
        box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
    }
    .slideOne label {
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: -3px;
        left: -3px;
        cursor: pointer;
        background: #fcfff4;
        background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        border-radius: 50px;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }
    .slideOne input[type=checkbox] {
        visibility: hidden;
    }
    .slideOne input[type=checkbox]:checked + label {
        left: 37px;
    }

    /* end .slideOne */
    /* .slideTwo */
    .slideTwo {
        width: 80px;
        height: 30px;
        background: #333;
        margin: 20px auto;
        position: relative;
        border-radius: 50px;
        box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
    }
    .slideTwo:after {
        content: '';
        position: absolute;
        top: 14px;
        left: 14px;
        height: 2px;
        width: 52px;
        background: #111;
        border-radius: 50px;
        box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
    }
    .slideTwo label {
        display: block;
        width: 22px;
        height: 22px;
        cursor: pointer;
        position: absolute;
        top: 4px;
        z-index: 1;
        left: 4px;
        background: #fcfff4;
        border-radius: 50px;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
        background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
    }
    .slideTwo label:after {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: 6px;
        left: 6px;
        background: #333;
        border-radius: 50px;
        box-shadow: inset 0px 1px 1px black, 0px 1px 0px rgba(255, 255, 255, 0.9);
    }
    .slideTwo input[type=checkbox] {
        visibility: hidden;
    }
    .slideTwo input[type=checkbox]:checked + label {
        left: 54px;
    }
    .slideTwo input[type=checkbox]:checked + label:after {
        background: #27ae60;
        /*activeColor*/
    }

    /* end .slideTwo */
    /* .slideThree */
    .slideThree {
        width: 80px;
        height: 26px;
        background: #333;
        margin: 20px auto;
        position: relative;
        border-radius: 50px;
        box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
    }
    .slideThree:after {
        content: 'OFF';
        color: #000;
        position: absolute;
        right: 10px;
        z-index: 0;
        font: 12px/26px Arial, sans-serif;
        font-weight: bold;
        text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.15);
    }
    .slideThree:before {
        content: 'ON';
        color: #27ae60;
        position: absolute;
        left: 10px;
        z-index: 0;
        font: 12px/26px Arial, sans-serif;
        font-weight: bold;
    }
    .slideThree label {
        display: block;
        width: 34px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        top: 3px;
        left: 3px;
        z-index: 1;
        background: #fcfff4;
        background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        border-radius: 50px;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
    }
    .slideThree input[type=checkbox] {
        visibility: hidden;
    }
    .slideThree input[type=checkbox]:checked + label {
        left: 43px;
    }

    /* end .slideThree */
    /* .roundedOne */
    .roundedOne {
        width: 28px;
        height: 28px;
        position: relative;
        margin: 20px auto;
        background: #fcfff4;
        background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        border-radius: 50px;
        box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
    }
    .roundedOne label {
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        left: 4px;
        top: 4px;
        background: -webkit-linear-gradient(top, #222222 0%, #45484d 100%);
        background: linear-gradient(to bottom, #222222 0%, #45484d 100%);
        border-radius: 50px;
        box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
    }
    .roundedOne label:after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        top: 2px;
        left: 2px;
        background: #27ae60;
        background: -webkit-linear-gradient(top, #27ae60 0%, #145b32 100%);
        background: linear-gradient(to bottom, #27ae60 0%, #145b32 100%);
        opacity: 0;
        border-radius: 50px;
        box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
    }
    .roundedOne label:hover::after {
        opacity: 0.3;
    }
    .roundedOne input[type=checkbox] {
        visibility: hidden;
    }
    .roundedOne input[type=checkbox]:checked + label:after {
        opacity: 1;
    }

    /* end .roundedOne */
    /* .roundedTwo */
    .roundedTwo {
        width: 28px;
        height: 28px;
        position: relative;
        margin: 20px auto;
        background: #fcfff4;
        background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        border-radius: 50px;
        box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
    }
    .roundedTwo label {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 4px;
        left: 4px;
        cursor: pointer;
        background: -webkit-linear-gradient(top, #222222 0%, #3c8dbc 100%);
        background: linear-gradient(to bottom, #222222 0%, #3c8dbc 100%);
        border-radius: 50px;
        box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
    }
    .roundedTwo label:after {
        content: '';
        width: 9px;
        height: 5px;
        position: absolute;
        top: 6px;
        left: 5px;
        border: 3px solid #fcfff4;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    .roundedTwo label:hover::after {
        opacity: 0.3;
    }
    .roundedTwo input[type=checkbox],.roundedTwo input[type=radio] {
        visibility: hidden;
    }
    .roundedTwo input[type=checkbox]:checked + label:after,.roundedTwo input[type=radio]:checked + label:after {
        opacity: 1;
    }

    /* end .roundedTwo */
    /* .squaredOne */
    .squaredOne {
        width: 28px;
        height: 28px;
        position: relative;
        margin: 20px auto;
        background: #fcfff4;
        background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
    }
    .squaredOne label {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 4px;
        left: 4px;
        cursor: pointer;
        background: -webkit-linear-gradient(top, #222222 0%, #45484d 100%);
        background: linear-gradient(to bottom, #222222 0%, #45484d 100%);
        box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
    }
    .squaredOne label:after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        top: 2px;
        left: 2px;
        background: #27ae60;
        background: -webkit-linear-gradient(top, #27ae60 0%, #145b32 100%);
        background: linear-gradient(to bottom, #27ae60 0%, #145b32 100%);
        box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
        opacity: 0;
    }
    .squaredOne label:hover::after {
        opacity: 0.3;
    }
    .squaredOne input[type=checkbox] {
        visibility: hidden;
    }
    .squaredOne input[type=checkbox]:checked + label:after {
        opacity: 1;
    }

    /* end .squaredOne */
    /* .squaredTwo */
    .squaredTwo {
        width: 28px;
        height: 28px;
        position: relative;
        margin: 20px auto;
        background: #fcfff4;
        background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
    }
    .squaredTwo label {
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        left: 4px;
        top: 4px;
        background: -webkit-linear-gradient(top, #222222 0%, #45484d 100%);
        background: linear-gradient(to bottom, #222222 0%, #45484d 100%);
        box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
    }
    .squaredTwo label:after {
        content: '';
        width: 9px;
        height: 5px;
        position: absolute;
        top: 4px;
        left: 4px;
        border: 3px solid #fcfff4;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    .squaredTwo label:hover::after {
        opacity: 0.3;
    }
    .squaredTwo input[type=checkbox] {
        visibility: hidden;
    }
    .squaredTwo input[type=checkbox]:checked + label:after {
        opacity: 1;
    }

    /* end .squaredTwo */
    /* .squaredThree */
    .squaredThree {
        width: 20px;
        position: relative;
        margin: 20px auto;
    }
    .squaredThree label {
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        background: -webkit-linear-gradient(top, #222222 0%, #45484d 100%);
        background: linear-gradient(to bottom, #222222 0%, #45484d 100%);
        border-radius: 4px;
        box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.4);
    }
    .squaredThree label:after {
        content: '';
        width: 9px;
        height: 5px;
        position: absolute;
        top: 4px;
        left: 4px;
        border: 3px solid #fcfff4;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    .squaredThree label:hover::after {
        opacity: 0.3;
    }
    .squaredThree input[type=checkbox] {
        visibility: hidden;
    }
    .squaredThree input[type=checkbox]:checked + label:after {
        opacity: 1;
    }

    /* end .squaredThree */
    /* .squaredFour */
    .squaredFour {
        width: 20px;
        position: relative;
        margin: 20px auto;
    }
    .squaredFour label {
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        background: #fcfff4;
        background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        border-radius: 4px;
        box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
    }
    .squaredFour label:after {
        content: '';
        width: 9px;
        height: 5px;
        position: absolute;
        top: 4px;
        left: 4px;
        border: 3px solid #333;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    .squaredFour label:hover::after {
        opacity: 0.5;
    }
    .squaredFour input[type=checkbox] {
        visibility: hidden;
    }
    .squaredFour input[type=checkbox]:checked + label:after {
        opacity: 1;
    }

    /* end .squaredFour */
    * {
        box-sizing: border-box;
    }

    body {
        background: #3498db;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
    }
    body h1, body h2, body em {
        color: #eee;
        font-size: 30px;
        text-align: center;
        margin: 20px 0 0 0;
        -webkit-font-smoothing: antialiased;
        text-shadow: 0px 1px #000;
    }
    body em {
        font-size: 14px;
        text-align: center;
        display: block;
        margin-bottom: 50px;
    }
    body .ondisplay {
        text-align: center;
        padding: 20px 0;
    }
    body .ondisplay section {
        width: 100px;
        height: 100px;
        background: #555;
        display: inline-block;
        position: relative;
        text-align: center;
        margin-top: 5px;
        border: 1px solid gray;
        border-radius: 5px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    }
    body .ondisplay section:after {
        /*         visibility: hidden; */
        content: attr(title);
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 3px;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        text-shadow: 0px 1px #000;
    }
}

//  ---- cuctom ----
.inlineCheckboxes{
    .inlineCheckbox {
        display: inline-block;
        position: relative;
        text-align: center;
        min-width: 60px;
        section {
            display: block;
        }
        span.label {
            position: relative;
            color: black;
            top: -10px;
        }
    }
}