.following-trainings{
    li{
        h6{
            width:100%;
            position: absolute;
            top: -12px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            a{
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: ~"calc(100% - 46px)";
                display: inline-block;
            }
        }
        position: relative;
        margin-top: 10px;

        &.training_1{
            list-style-image:url(../img/obicons/map.png);
        }
        &.training_2{
            list-style-image:url(../img/obicons/ob.png);
        }
        &.training_3{
            list-style-image:url(../img/obicons/psy.png);
        }
        &.training_4{
            list-style-image:url(../img/obicons/ost.png);
        }

        .green{
            color:#00e400;
        }
    }

}