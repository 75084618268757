.obrou-portal {

    // ------------------ Calendar -----------------
    .fc-day-grid-event .fc-content{
        white-space: normal;
    }

    // ------------------ Tooltip------------------
    .tooltip{
        .tooltip-inner{
            max-width:400px;
        }
    }

    // --------------------- AdminLTE ---------
    .user-panel>.image>img {
        width: 45px;
        height: 45px;
    }

    .profile-user-img {
        height: 100px;
    }

    // ------------------------ Datatables -----------
    .dataTables_paginate{
        text-align: right;
    }
}