.obrou-portal .fc {
    .fc-event {
        &[data-before] {
            min-height: 28px;
        }
        &:before {
            float: left;
            font: normal normal normal 10px/1 FontAwesome;
            margin-right: 4px;
            margin-left: 1px;
            position: relative;
            top: 2px;
            word-wrap: break-word;
            width: 10px;
            line-height: 14px;

        }
    }
    .race {
        border: 1px solid #3a87ad;
        background-color: #3a87ad;
        &:before {
            content: "\f091" attr(data-before);
        }
    }

    .training {
        border: 1px solid #00A614;
        background-color: #00A65A;
        &:before {
            content: "\f21d" attr(data-before);
        }
    }

    .user {
        border: 1px solid #3a87ad;
        background-color: #3a87ad;
        &:before {
            content: "\f007";
        }
    }

    .event {
        border: 1px solid #3207ad;
        background-color: #3207ad;
        &:before {
            content: "\f073";
        }
    }

    .trainingPlan {
        background-color: #6f5f62;
        &.done{
            background-color: #1eb94a;
        }
        &.undone{
            background-color: orangered;
        }
        .tranings_plan_checkbox{
            position: absolute;
            right: 2px;
            top: -2px;
        }
        .trainings_plan_delete{
            position: absolute;
            right: 20px;
            top: 1px;
            display: none;
            color:white;
            z-index: 100;
        }
        &:hover .trainings_plan_delete{
            display: inline;
        }

        .fc-content{
            width: calc(100% - 15px);
        }
    }
    td {
        .fc-past {
            background-color: #EEE;
        }
        .fc-today {
            background-color: #FDD912;
        }
    }
}