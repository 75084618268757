.obrou-portal form {
    &.form-horizontal{
        input[type=checkbox] {
            margin: 0;
        }
    }

    .checkbox {
        label > div {
            left: -10px;
        }
    }
    input[type=checkbox] {
        height: 20px;
        width: 20px;
        /*margin: 0;*/
        vertical-align: middle;
    }
    input[required] {
        border-color: #555;
    }
    input::-webkit-calendar-picker-indicator {
        display: none;
    }
    input[type="date"]::-webkit-input-placeholder {
        visibility: hidden !important;
    }
    .fileUploader {
        padding-right: 15px;
        min-width: 250px;
        .loading{
            width: 100%;
            text-align: center;
            color: #f5f5f5;
            position: absolute;
            padding-right: 32px;
        }
        .filesList {
            min-height: 20px;
        }
    }
}
