.rssfeed {
    .item {
        margin-bottom: 14px;
        border-bottom: 1px solid lightgray;
        padding-bottom: 5px;
        position: relative;

        &:last-of-type {
            border-bottom: none;
            padding-bottom: 0px;
        }
        a:hover {
            text-decoration: none;
        }
        h4 {
            font-size: 16px;
            color: #1B548D;
        }
        p {
            font-weight: normal;
        }
        .source {
            font-weight: bold;
            font-size: 12px;
        }
        .date {
            font-weight: bold;
            position: absolute;
            right: 10px;
            font-size: 12px;
        }
    }
}