/* SECTIONS */
.icon-races:before {
    content: "\f091";
}

.icon-trainings:before {
    content: "\f21d";
}

.icon-actualities:before {
    content: "\f0a2";
}

.icon-statistics:before {
    content: "\f080";
}

.icon-gallery:before {
    content: "\f1c5";
}

.icon-money:before {
    content: "\f155";
}

.icon-forum:before {
    content: "\f0e6";
}

.icon-links:before {
    content: "\f064";
}

.icon-timeline:before {
    content: "\f143";
}

.icon-other:before {
    content: "\f097";
}

.icon-eshop:before {
    content: "\f07a";
}

.icon-settings:before {
    content: "\f085";
}

.icon-users:before {
    content: "\f007";
}

.icon-events:before {
    content: "\f073";
}

/* FILES */
.fileIcon {
    &.type-image:before {
        content: "\f1c5";
    }
    &.type-pdf:before {
        content: "\f1c1";
    }
    &.type-document:before {
        content: "\f1c2";
    }
    &.type-archive:before {
        content: "\f1c6";
    }
    &.type-text:before {
        content: "\f0f6";
    }
    &.type-other:before {
        content: "\f016";
    }
}

/*  OTHERS */

.toggle-group {
    .toggle-on, .toggle-on:active {
        color: black;
    }
}

.tooltipHolder {
    font-size: 10px;
    background-color: #E5E5E5;
    color: #A7A7A7;
    width: 16px;
    position: relative;
    display: inline;
    top: -4px;
    left: 5px;
    padding-left: 3px;
    height: 16px;
    text-align: center;
    border: 1px solid #A7A7A7;
    border-radius: 200px 200px 200px 200px;
    -moz-border-radius: 200px 200px 200px 200px;
    -webkit-border-radius: 200px 200px 200px 200px;
}