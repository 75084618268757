.datagrid_container {
    margin-bottom: 25px;
    table.datagrid {
        width: 100%;
        thead {
            background: #d2e3ef;
            .sorting:after, .sorting_asc:after, .sorting_desc:after {
                top: 5px;
            }
        }
        td, th {
            padding: 6px;
            border: 1px solid #ebebeb;
            &:first-of-type {
                width: 40px;
                input[type=checkbox] {
                    width: 35px;
                }
            }
            .btn{
                padding: 6px 2px;
            }
            &.actions {
                width: 1%;
                text-align: right;
                white-space: nowrap;
            }
        }
        .btn {
            display: inline;
        }
        tbody{
            .loading{
                text-align: center;
            }
        }
        .daterange-filter-dropup {
            li input {
                margin-left: 6px;
            }
            li .Zebra_DatePicker_Icon_Wrapper button {
                transform: translate(0px, 10px);
            }
            .filters .Zebra_DatePicker_Icon_Wrapper input {
                width: 110px;
            }
        }
        .numberrange-filter-dropup {
            li input {
                margin-left: 6px;
            }
        }
    }
    .dataTables_filter {
        display: none;
    }
    div.dataTables_paginate a {
        margin-left: 10px;
    }
    a {
        cursor: pointer;
    }
}
