.trainingsGrid {
    table.datagrid {
        td:first-of-type, td:first-of-type {
            width: 20%;
        }
        td:nth-of-type(3) {
            max-width: 20%;
        }
        .filters {
            .dropdown-menu {
                bottom: auto;
                top: 100%;
            }
        }
        .raceTypeIcon {
            width: 20px;
            height: 20px;
            background-size: 15px 15px;
            background-repeat: no-repeat;
            background-position: 3px 5px;
            display: inline-block;
            &.OB {
                background-image: url(../img/obicons/ob.png);
            }
            &.LOB {
                background-image: url(../img/obicons/lob.png);
            }
            &.MTBO {
                background-image: url(../img/obicons/mtbo.png);
            }
            &.OST {
                background-image: url(../img/obicons/ost.png);
            }
            &.TRAIL {
                background-image: url(../img/obicons/trail.png);
            }
        }
    }
}

.trainingDetail {
    table.details {
        width: 100%;
        th {
            vertical-align: top;
            padding: 2px 8px 2px 4px;
        }
        table.membersOnRace {
            td{
                &:first-of-type{
                    white-space: nowrap;
                    width:1%;
                }
                &:nth-of-type(2){
                    white-space: nowrap;
                    width:1%;
                }
            }
            width: 100%;
            margin-top: -3px;
        }
    }
}

.application {
    .applicationTable {
        width: 100%;
    }
}