body.obrou-portal {
    .toast-top-right {
        top: 54px;
    }
    .content-wrapper {
        position: relative;
        .flashes {
            position: absolute;
            top: 2px;
            width: ~"calc(100% - 30px)";
            z-index: 1;
            opacity: 0.85;
            .box-body {
                padding: 0px;
                .alert {
                    margin-bottom: 0px;
                }
            }
        }

        .content-header {
            height: 28px;
            ol.breadcrumb {
                top: 5px;
            }

            h1 {
                position: relative;
                top: -5px;
            }
        }
    }
    .main-header {
        .logo {
            height: 40px;
            line-height: 40px;
        }
        .navbar {
            min-height: 40px;
            .sidebar-toggle {
                padding: 10px;
            }
            .navbar-custom-menu {
                .messages-menu {
                    li.unread {
                        background-color: #DDD;
                    }
                }
                .badge {
                    position: relative;
                    top: -10px;
                    left: -10px;
                }
                .dropdown:hover .dropdown-menu {
                    display: block;
                }
                .nav > li > a {
                    padding: 10px 15px;
                }
            }
        }
    }

    table {
        td,th {
            padding: 2px;
        }
    }
    i.fa {
        margin-right: 4px;
    }
    .sidebar {
        li.treeview:not([href]) {
            cursor: cell;
        }
        li.treeview {
            a {
                position: relative;
            }
            .fa-angle-up, .fa-angle-down {
                position: absolute;
                right: 6px;
                top: 14px;
                cursor: alias;
            }
            .fa-angle-up {
                display: none;
            }
            .fa-angle-down {
                display: block;
            }
            &.active {
                .fa-angle-up {
                    display: block;
                }
                .fa-angle-down {
                    display: none;
                }
            }
        }
    }

    .larger {
        font-size: larger;
    }

    #timeline-calendar-tabs {
        min-height: 500px;
    }

    .main-footer {
        padding: 5px 20px;
        font-size: 0.7em;
    }

    .back-to-top {
        position: fixed;
        bottom: 20px;
        right: 25px;
        width: 40px;
        height: 40px;
        color: rgb(238, 238, 238);
        line-height: 40px;
        text-align: center;
        background-color: rgb(34, 45, 50);
        cursor: pointer;
        border-radius: 5px;
        z-index: 99999;
        opacity: 0.7;
        display: none;
        text-decoration: none;

        i {

        }

    }

}