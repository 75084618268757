

.ug-gallery-wrapper .ug-theme-panel{
	position:absolute;
	xbackground-color:red;
	z-index:11;		
}

.ug-theme-panel .ug-strip-panel{
	z-index:6;
}

/* --- fullscreen button -- */

.ug-theme-default .ug-default-button-fullscreen{
	position:absolute;
	background-image:url('/bower_components/unitegallery/dist//themes/default/images/button_fullscreen.png');
    width:53px;
    height:50px;
    cursor:pointer;
	background-position:0px 0px;
	z-index:3;
}

.ug-theme-default .ug-default-button-fullscreen.ug-button-hover{
	background-position:0px -50px;	
}

.ug-theme-default .ug-default-button-fullscreen.ug-fullscreenmode{
	background-position:0px -100px;
}

.ug-theme-default .ug-default-button-fullscreen.ug-fullscreenmode.ug-button-hover{
	background-position:0px -150px;
}


/* --- fullscreen button single mode-- */

.ug-theme-default .ug-default-button-fullscreen-single{
	position:absolute;
	background-image:url('/bower_components/unitegallery/dist//themes/default/images/button_fullscreen_single.png');
    width:52px;
    height:50px;
    cursor:pointer;
	background-position:0px 0px;
	z-index:3;
}

.ug-theme-default .ug-default-button-fullscreen-single.ug-button-hover{
	background-position:0px -50px;	
}

.ug-theme-default .ug-default-button-fullscreen-single.ug-fullscreenmode{
	background-position:0px -100px;
}

.ug-theme-default .ug-default-button-fullscreen-single.ug-fullscreenmode.ug-button-hover{
	background-position:0px -150px;
}


/* --- play button -- */

.ug-theme-default .ug-default-button-play{
	position:absolute;
	background-image:url('/bower_components/unitegallery/dist//themes/default/images/button_playpause.png');
    width:51px;
    height:50px;
    cursor:pointer;
	background-position:0px 0px;
	z-index:4;
}

.ug-theme-default .ug-default-button-play.ug-button-hover{
	background-position:0px -50px;	
}

.ug-theme-default .ug-default-button-play.ug-stop-mode{
	background-position:0px -100px;
}

.ug-theme-default .ug-default-button-play.ug-stop-mode.ug-button-hover{
	background-position:0px -150px;
}

/* --- play button single mode-- */

.ug-theme-default .ug-default-button-play-single{
	position:absolute;
	background-image:url('/bower_components/unitegallery/dist//themes/default/images/button_playpause_single.png');
    width:50px;
    height:50px;
    cursor:pointer;
	background-position:0px 0px;
	z-index:4;
}

.ug-theme-default .ug-default-button-play-single.ug-button-hover{
	background-position:0px -50px;	
}

.ug-theme-default .ug-default-button-play-single.ug-stop-mode{
	background-position:0px -100px;
}

.ug-theme-default .ug-default-button-play-single.ug-stop-mode.ug-button-hover{
	background-position:0px -150px;
}


/* --- hide panel button -- */

.ug-default-button-hidepanel{
	position:absolute;
	width:23px;
	height:23px;
	z-index:5;
	cursor:pointer;	
}

.ug-default-button-hidepanel .ug-default-button-hidepanel-bg{
	background-color:#000000;
	width:23px;
	height:23px;
	position:absolute;
	top:0px;
	left:0px;
	opacity:0.4;
	filter: ~"alpha(opacity = 40)";
	z-index:1;
}

.ug-default-button-hidepanel .ug-default-button-hidepanel-tip{
	position:absolute;
	width:7px;
	height:7px;
	background-repeat:no-repeat;
	background-image:url('/bower_components/unitegallery/dist//themes/default/images/arrow_down_up.png');
	background-position: 0px 0px;
	z-index:2;
}

.ug-default-button-hidepanel.ug-button-hover .ug-default-button-hidepanel-tip{
	background-position: 0px -7px;
}

.ug-default-button-hidepanel.ug-button-hidden-mode .ug-default-button-hidepanel-tip{
	background-position: 0px -14px;
}

.ug-default-button-hidepanel.ug-button-hidden-mode.ug-button-hover .ug-default-button-hidepanel-tip{
	background-position: 0px -21px;
}

