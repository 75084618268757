.obrou-portal .fc .fc-event[data-before] {
  min-height: 28px;
}
.obrou-portal .fc .fc-event:before {
  float: left;
  font: normal normal normal 10px/1 FontAwesome;
  margin-right: 4px;
  margin-left: 1px;
  position: relative;
  top: 2px;
  word-wrap: break-word;
  width: 10px;
  line-height: 14px;
}
.obrou-portal .fc .race {
  border: 1px solid #3a87ad;
  background-color: #3a87ad;
}
.obrou-portal .fc .race:before {
  content: "\f091" attr(data-before);
}
.obrou-portal .fc .training {
  border: 1px solid #00A614;
  background-color: #00A65A;
}
.obrou-portal .fc .training:before {
  content: "\f21d" attr(data-before);
}
.obrou-portal .fc .user {
  border: 1px solid #3a87ad;
  background-color: #3a87ad;
}
.obrou-portal .fc .user:before {
  content: "\f007";
}
.obrou-portal .fc .event {
  border: 1px solid #3207ad;
  background-color: #3207ad;
}
.obrou-portal .fc .event:before {
  content: "\f073";
}
.obrou-portal .fc .trainingPlan {
  background-color: #6f5f62;
}
.obrou-portal .fc .trainingPlan.done {
  background-color: #1eb94a;
}
.obrou-portal .fc .trainingPlan.undone {
  background-color: orangered;
}
.obrou-portal .fc .trainingPlan .tranings_plan_checkbox {
  position: absolute;
  right: 2px;
  top: -2px;
}
.obrou-portal .fc .trainingPlan .trainings_plan_delete {
  position: absolute;
  right: 20px;
  top: 1px;
  display: none;
  color: white;
  z-index: 100;
}
.obrou-portal .fc .trainingPlan:hover .trainings_plan_delete {
  display: inline;
}
.obrou-portal .fc .trainingPlan .fc-content {
  width: calc(85%);
}
.obrou-portal .fc td .fc-past {
  background-color: #EEE;
}
.obrou-portal .fc td .fc-today {
  background-color: #FDD912;
}

.datagrid_container {
  margin-bottom: 25px;
}
.datagrid_container table.datagrid {
  width: 100%;
}
.datagrid_container table.datagrid thead {
  background: #d2e3ef;
}
.datagrid_container table.datagrid thead .sorting:after,
.datagrid_container table.datagrid thead .sorting_asc:after,
.datagrid_container table.datagrid thead .sorting_desc:after {
  top: 5px;
}
.datagrid_container table.datagrid td,
.datagrid_container table.datagrid th {
  padding: 6px;
  border: 1px solid #ebebeb;
}
.datagrid_container table.datagrid td:first-of-type,
.datagrid_container table.datagrid th:first-of-type {
  width: 40px;
}
.datagrid_container table.datagrid td:first-of-type input[type=checkbox],
.datagrid_container table.datagrid th:first-of-type input[type=checkbox] {
  width: 35px;
}
.datagrid_container table.datagrid td .btn,
.datagrid_container table.datagrid th .btn {
  padding: 6px 2px;
}
.datagrid_container table.datagrid td.actions,
.datagrid_container table.datagrid th.actions {
  width: 1%;
  text-align: right;
  white-space: nowrap;
}
.datagrid_container table.datagrid .btn {
  display: inline;
}
.datagrid_container table.datagrid tbody .loading {
  text-align: center;
}
.datagrid_container table.datagrid .daterange-filter-dropup li input {
  margin-left: 6px;
}
.datagrid_container table.datagrid .daterange-filter-dropup li .Zebra_DatePicker_Icon_Wrapper button {
  transform: translate(0px, 10px);
}
.datagrid_container table.datagrid .daterange-filter-dropup .filters .Zebra_DatePicker_Icon_Wrapper input {
  width: 110px;
}
.datagrid_container table.datagrid .numberrange-filter-dropup li input {
  margin-left: 6px;
}
.datagrid_container .dataTables_filter {
  display: none;
}
.datagrid_container div.dataTables_paginate a {
  margin-left: 10px;
}
.datagrid_container a {
  cursor: pointer;
}

.obrou-portal form.form-horizontal input[type=checkbox] {
  margin: 0;
}
.obrou-portal form .checkbox label > div {
  left: -10px;
}
.obrou-portal form input[type=checkbox] {
  height: 20px;
  width: 20px;
  /*margin: 0;*/
  vertical-align: middle;
}
.obrou-portal form input[required] {
  border-color: #555;
}
.obrou-portal form input::-webkit-calendar-picker-indicator {
  display: none;
}
.obrou-portal form input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important;
}
.obrou-portal form .fileUploader {
  padding-right: 15px;
  min-width: 250px;
}
.obrou-portal form .fileUploader .loading {
  width: 100%;
  text-align: center;
  color: #f5f5f5;
  position: absolute;
  padding-right: 32px;
}
.obrou-portal form .fileUploader .filesList {
  min-height: 20px;
}

.obrou-portal .discussionPreview table td:last-of-type {
  width: 170px;
}
.obrou-portal .discussionPreview table td:nth-of-type(2) {
  width: 100px;
}
.obrou-portal .discussionPreview .desc-wrapper i {
  margin-left: 10px;
}
.obrou-portal .discussionDetail .info {
  margin: 10px 5px 40px 20px;
}
.obrou-portal .discussionDetail .info table th {
  vertical-align: top;
}
.obrou-portal .discussionDetail .info table td,
.obrou-portal .discussionDetail .info table th {
  padding: 2px 4px;
}

.obrou-portal .photoAlbum {
  margin-right: 5px;
}
.obrou-portal .photoAlbum img {
  width: 32%;
}
.obrou-portal .ug-gallery-wrapper.ug-lightbox {
  z-index: 1500;
}

/* SECTIONS */
.icon-races:before {
  content: "\f091";
}
.icon-trainings:before {
  content: "\f21d";
}
.icon-actualities:before {
  content: "\f0a2";
}
.icon-statistics:before {
  content: "\f080";
}
.icon-gallery:before {
  content: "\f1c5";
}
.icon-money:before {
  content: "\f155";
}
.icon-forum:before {
  content: "\f0e6";
}
.icon-links:before {
  content: "\f064";
}
.icon-timeline:before {
  content: "\f143";
}
.icon-other:before {
  content: "\f097";
}
.icon-eshop:before {
  content: "\f07a";
}
.icon-settings:before {
  content: "\f085";
}
.icon-users:before {
  content: "\f007";
}
.icon-events:before {
  content: "\f073";
}
/* FILES */
.fileIcon.type-image:before {
  content: "\f1c5";
}
.fileIcon.type-pdf:before {
  content: "\f1c1";
}
.fileIcon.type-document:before {
  content: "\f1c2";
}
.fileIcon.type-archive:before {
  content: "\f1c6";
}
.fileIcon.type-text:before {
  content: "\f0f6";
}
.fileIcon.type-other:before {
  content: "\f016";
}
/*  OTHERS */
.toggle-group .toggle-on,
.toggle-group .toggle-on:active {
  color: black;
}
.tooltipHolder {
  font-size: 10px;
  background-color: #E5E5E5;
  color: #A7A7A7;
  width: 16px;
  position: relative;
  display: inline;
  top: -4px;
  left: 5px;
  padding-left: 3px;
  height: 16px;
  text-align: center;
  border: 1px solid #A7A7A7;
  border-radius: 200px 200px 200px 200px;
  -moz-border-radius: 200px 200px 200px 200px;
  -webkit-border-radius: 200px 200px 200px 200px;
}

body.obrou-portal .toast-top-right {
  top: 54px;
}
body.obrou-portal .content-wrapper {
  position: relative;
}
body.obrou-portal .content-wrapper .flashes {
  position: absolute;
  top: 2px;
  width: calc(100% - 30px);
  z-index: 1;
  opacity: 0.85;
}
body.obrou-portal .content-wrapper .flashes .box-body {
  padding: 0px;
}
body.obrou-portal .content-wrapper .flashes .box-body .alert {
  margin-bottom: 0px;
}
body.obrou-portal .content-wrapper .content-header {
  height: 28px;
}
body.obrou-portal .content-wrapper .content-header ol.breadcrumb {
  top: 5px;
}
body.obrou-portal .content-wrapper .content-header h1 {
  position: relative;
  top: -5px;
}
body.obrou-portal .main-header .logo {
  height: 40px;
  line-height: 40px;
}
body.obrou-portal .main-header .navbar {
  min-height: 40px;
}
body.obrou-portal .main-header .navbar .sidebar-toggle {
  padding: 10px;
}
body.obrou-portal .main-header .navbar .navbar-custom-menu .messages-menu li.unread {
  background-color: #DDD;
}
body.obrou-portal .main-header .navbar .navbar-custom-menu .badge {
  position: relative;
  top: -10px;
  left: -10px;
}
body.obrou-portal .main-header .navbar .navbar-custom-menu .dropdown:hover .dropdown-menu {
  display: block;
}
body.obrou-portal .main-header .navbar .navbar-custom-menu .nav > li > a {
  padding: 10px 15px;
}
body.obrou-portal table td,
body.obrou-portal table th {
  padding: 2px;
}
body.obrou-portal i.fa {
  margin-right: 4px;
}
body.obrou-portal .sidebar li.treeview:not([href]) {
  cursor: cell;
}
body.obrou-portal .sidebar li.treeview a {
  position: relative;
}
body.obrou-portal .sidebar li.treeview .fa-angle-up,
body.obrou-portal .sidebar li.treeview .fa-angle-down {
  position: absolute;
  right: 6px;
  top: 14px;
  cursor: alias;
}
body.obrou-portal .sidebar li.treeview .fa-angle-up {
  display: none;
}
body.obrou-portal .sidebar li.treeview .fa-angle-down {
  display: block;
}
body.obrou-portal .sidebar li.treeview.active .fa-angle-up {
  display: block;
}
body.obrou-portal .sidebar li.treeview.active .fa-angle-down {
  display: none;
}
body.obrou-portal .larger {
  font-size: larger;
}
body.obrou-portal #timeline-calendar-tabs {
  min-height: 500px;
}
body.obrou-portal .main-footer {
  padding: 5px 20px;
  font-size: 0.7em;
}
body.obrou-portal .back-to-top {
  position: fixed;
  bottom: 20px;
  right: 25px;
  width: 40px;
  height: 40px;
  color: #eeeeee;
  line-height: 40px;
  text-align: center;
  background-color: #222d32;
  cursor: pointer;
  border-radius: 5px;
  z-index: 99999;
  opacity: 0.7;
  display: none;
  text-decoration: none;
}

body.obrou-portal .products-list.links .product-description {
  white-space: normal;
}

body.obrou-portal .login-box img.logo {
  width: 100%;
  margin: 30px 0px 50px 0px;
}

body.obrou-portal .toast-top-right {
  top: 54px;
}
body.obrou-portal .content-wrapper {
  position: relative;
}
body.obrou-portal .content-wrapper .flashes {
  position: absolute;
  top: 2px;
  width: calc(100% - 30px);
  z-index: 1;
  opacity: 0.85;
}
body.obrou-portal .content-wrapper .flashes .box-body {
  padding: 0px;
}
body.obrou-portal .content-wrapper .flashes .box-body .alert {
  margin-bottom: 0px;
}
body.obrou-portal .content-wrapper .content-header {
  height: 28px;
}
body.obrou-portal .content-wrapper .content-header ol.breadcrumb {
  top: 5px;
}
body.obrou-portal .content-wrapper .content-header h1 {
  position: relative;
  top: -5px;
}
body.obrou-portal .main-header .logo {
  height: 40px;
  line-height: 40px;
}
body.obrou-portal .main-header .navbar {
  min-height: 40px;
}
body.obrou-portal .main-header .navbar .sidebar-toggle {
  padding: 10px;
}
body.obrou-portal .main-header .navbar .navbar-custom-menu .messages-menu li.unread {
  background-color: #DDD;
}
body.obrou-portal .main-header .navbar .navbar-custom-menu .badge {
  position: relative;
  top: -10px;
  left: -10px;
}
body.obrou-portal .main-header .navbar .navbar-custom-menu .dropdown:hover .dropdown-menu {
  display: block;
}
body.obrou-portal .main-header .navbar .navbar-custom-menu .nav > li > a {
  padding: 10px 15px;
}
body.obrou-portal table td,
body.obrou-portal table th {
  padding: 2px;
}
body.obrou-portal i.fa {
  margin-right: 4px;
}
body.obrou-portal .sidebar li.treeview:not([href]) {
  cursor: cell;
}
body.obrou-portal .sidebar li.treeview a {
  position: relative;
}
body.obrou-portal .sidebar li.treeview .fa-angle-up,
body.obrou-portal .sidebar li.treeview .fa-angle-down {
  position: absolute;
  right: 6px;
  top: 14px;
  cursor: alias;
}
body.obrou-portal .sidebar li.treeview .fa-angle-up {
  display: none;
}
body.obrou-portal .sidebar li.treeview .fa-angle-down {
  display: block;
}
body.obrou-portal .sidebar li.treeview.active .fa-angle-up {
  display: block;
}
body.obrou-portal .sidebar li.treeview.active .fa-angle-down {
  display: none;
}
body.obrou-portal .larger {
  font-size: larger;
}
body.obrou-portal #timeline-calendar-tabs {
  min-height: 500px;
}
body.obrou-portal .main-footer {
  padding: 5px 20px;
  font-size: 0.7em;
}
body.obrou-portal .back-to-top {
  position: fixed;
  bottom: 20px;
  right: 25px;
  width: 40px;
  height: 40px;
  color: #eeeeee;
  line-height: 40px;
  text-align: center;
  background-color: #222d32;
  cursor: pointer;
  border-radius: 5px;
  z-index: 99999;
  opacity: 0.7;
  display: none;
  text-decoration: none;
}
body.obrou-portal .login-box img.logo {
  width: 100%;
  margin: 30px 0px 50px 0px;
}
.datagrid_container {
  margin-bottom: 25px;
}
.datagrid_container table.datagrid {
  width: 100%;
}
.datagrid_container table.datagrid thead {
  background: #d2e3ef;
}
.datagrid_container table.datagrid thead .sorting:after,
.datagrid_container table.datagrid thead .sorting_asc:after,
.datagrid_container table.datagrid thead .sorting_desc:after {
  top: 5px;
}
.datagrid_container table.datagrid td,
.datagrid_container table.datagrid th {
  padding: 6px;
  border: 1px solid #ebebeb;
}
.datagrid_container table.datagrid td:first-of-type,
.datagrid_container table.datagrid th:first-of-type {
  width: 40px;
}
.datagrid_container table.datagrid td:first-of-type input[type=checkbox],
.datagrid_container table.datagrid th:first-of-type input[type=checkbox] {
  width: 35px;
}
.datagrid_container table.datagrid td .btn,
.datagrid_container table.datagrid th .btn {
  padding: 6px 2px;
}
.datagrid_container table.datagrid td.actions,
.datagrid_container table.datagrid th.actions {
  width: 1%;
  text-align: right;
  white-space: nowrap;
}
.datagrid_container table.datagrid .btn {
  display: inline;
}
.datagrid_container table.datagrid tbody .loading {
  text-align: center;
}
.datagrid_container table.datagrid .daterange-filter-dropup li input {
  margin-left: 6px;
}
.datagrid_container table.datagrid .daterange-filter-dropup li .Zebra_DatePicker_Icon_Wrapper button {
  transform: translate(0px, 10px);
}
.datagrid_container table.datagrid .daterange-filter-dropup .filters .Zebra_DatePicker_Icon_Wrapper input {
  width: 110px;
}
.datagrid_container table.datagrid .numberrange-filter-dropup li input {
  margin-left: 6px;
}
.datagrid_container .dataTables_filter {
  display: none;
}
.datagrid_container div.dataTables_paginate a {
  margin-left: 10px;
}
.datagrid_container a {
  cursor: pointer;
}
.obrou-portal form.form-horizontal input[type=checkbox] {
  margin: 0;
}
.obrou-portal form .checkbox label > div {
  left: -10px;
}
.obrou-portal form input[type=checkbox] {
  height: 20px;
  width: 20px;
  /*margin: 0;*/
  vertical-align: middle;
}
.obrou-portal form input[required] {
  border-color: #555;
}
.obrou-portal form input::-webkit-calendar-picker-indicator {
  display: none;
}
.obrou-portal form input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important;
}
.obrou-portal form .fileUploader {
  padding-right: 15px;
  min-width: 250px;
}
.obrou-portal form .fileUploader .loading {
  width: 100%;
  text-align: center;
  color: #f5f5f5;
  position: absolute;
  padding-right: 32px;
}
.obrou-portal form .fileUploader .filesList {
  min-height: 20px;
}
.timeline-container {
  max-height: 2000px;
  min-height: 250px;
  overflow: hidden;
}
.timeline-container .add-post {
  margin: 1px 26px 20px;
}
.timeline-container .add-post .add-post-form .form-group {
  margin-bottom: 5px;
}
.timeline-container .add-post .add-post-form .form-group label {
  margin-bottom: 0px;
}
.timeline-container .add-post .add-post-form input[type='text'] {
  width: 250px;
  display: inline-block;
  height: 34px;
}
.timeline-container .add-post .add-post-form textarea {
  height: 80px;
  width: 100%;
}
.timeline-container .add-post .add-post-form .dropdown {
  display: inline-block;
}
.timeline-container .add-post .add-post-form .dropdown .dropdown-menu[role=input] {
  padding: 10px 1px 5px 20px;
}
.timeline-container .add-post .add-post-form .dropdown .dropdown-menu[role=input] input {
  margin-right: 10px;
}
.timeline-container .add-post .add-post-form .dropdown button {
  height: 35px;
}
.timeline-container form.edit-post-form .checkbox {
  margin-left: 20px;
}
.timeline-container .timeline .timeline-item .timeline-item-text .post_gallery a {
  opacity: 1;
}
.timeline-container .timeline .timeline-item .timeline-item-text .post_gallery a img {
  opacity: 1;
}
.timeline-container .timeline div.new-comment-container {
  margin: 5px 0 15px 0;
}
.timeline-container .timeline div.new-comment-container img.img-sm {
  margin-top: 3px;
  width: 35px !important;
  height: 35px !important;
}
.timeline-container .timeline div.new-comment-container textarea {
  resize: none;
  height: 40px;
}
.timeline-container .timeline div.new-comment-container .btn {
  height: 40px;
}
.timeline-container .timeline div.new-comment-container .btn .valigner {
  height: 100%;
  vertical-align: middle;
  width: 0px;
  display: inline-block;
}
.timeline-container .timeline div.new-comment-container .btn i {
  vertical-align: middle;
}
.timeline-container .timeline div.timeline-item-text img {
  max-width: 100%;
}
.timeline-container .timeline div.timeline-item-text .last-discussion-comments li div {
  position: relative;
  max-height: 100px;
  overflow: hidden;
  mix-blend-mode: hard-light;
}
.timeline-container .timeline div.timeline-item-text .last-discussion-comments li div:after {
  content: '';
  position: absolute;
  top: 30px;
  height: 75px;
  width: 100%;
  background: linear-gradient(transparent, gray);
}
.timeline-container .timeline div.timeline-item-text .categories-results {
  width: 100%;
  max-width: 790px;
}
.timeline-container .timeline div.timeline-item-text .categories-results .members-in-category {
  width: 100%;
}
.timeline-container .timeline div.timeline-item-text .categories-results .members-in-category td:nth-child(1),
.timeline-container .timeline div.timeline-item-text .categories-results .members-in-category th:nth-child(1) {
  width: 45px;
  text-align: right;
}
.timeline-container .timeline div.timeline-item-text .categories-results .members-in-category td:nth-child(3),
.timeline-container .timeline div.timeline-item-text .categories-results .members-in-category th:nth-child(3) {
  width: 100px;
  text-align: right;
}
.timeline-container .timeline div.timeline-item-text .categories-results .members-in-category td:nth-child(4),
.timeline-container .timeline div.timeline-item-text .categories-results .members-in-category th:nth-child(4) {
  width: 100px;
  text-align: right;
}
.timeline-container .timeline div.timeline-item-text .categories-results > tbody > tr {
  border-top: 1px solid lightgrey;
}
.timeline-container .timeline div.timeline-item-text .categories-results td,
.timeline-container .timeline div.timeline-item-text .categories-results th {
  vertical-align: top;
  text-align: left;
}
.timeline-container .timeline .timeline-item > .public-post {
  color: #999;
  float: right;
  padding: 12px;
  font-size: 12px;
}
.timeline-container .timeline div.box-comments {
  margin-top: 20px;
  padding: 10px;
  max-height: 320px;
  border-top: 1px solid #eee;
  padding-top: 5px;
}
.timeline-container .timeline div.box-comments .slim-scroll-wrap {
  max-height: 255px;
}
.timeline-container .timeline div.box-comments .slim-scroll-wrap div.comment-header {
  height: 30px;
}
.timeline-container .timeline div.box-comments .slim-scroll-wrap div.comment-header span.username {
  padding: 4px;
  position: relative;
  left: 10px;
}
.timeline-container .timeline div.box-comments .slim-scroll-wrap div.comment-body {
  padding-bottom: 4px;
}
.timeline-container .timeline div.box-comments .slim-scroll-wrap div.comment-body .text-muted {
  margin-right: 10px;
}
.timeline-container .timeline div.box-comments .list-inline {
  position: relative;
  top: -18px;
}
.obrou-portal .messages-menu ul.dropdown-menu {
  width: 450px;
  min-height: 240px;
}
.obrou-portal .messages-menu ul.dropdown-menu .notifications {
  overflow-y: visible;
}
.obrou-portal .messages-menu ul.dropdown-menu .notifications li {
  position: relative;
}
.obrou-portal .messages-menu ul.dropdown-menu .notifications li.unread {
  background-color: #f4f4f4;
}
.obrou-portal .messages-menu ul.dropdown-menu .notifications .time {
  position: absolute;
  bottom: -1px;
  right: 4px;
  padding-right: 5px;
  color: #999;
}
.obrou-portal .messages .conversations .item.unread {
  background-color: #d2d6de;
}
.obrou-portal .messages .conversations .item.actual {
  border: 1px solid #3c8dbc;
}
.obrou-portal .messages .conversations.products-list > .item {
  padding: 10px 5px 10px 2px;
}
.obrou-portal .messages .memembersList {
  max-height: 40px;
  overflow: auto;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages {
  height: 100%;
  max-height: 1020px;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .subject h3 {
  margin-top: 6px;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .sended a.subject {
  color: #d2d6de;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .sended a.subject:hover {
  color: #72afd2;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .direct-chat-text .attachments {
  border-top: 1px solid white;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .direct-chat-msg.center {
  margin-bottom: 4px;
  font-size: smaller;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .direct-chat-msg.center .direct-chat-info {
  font-size: smaller;
  margin-bottom: 0px;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .direct-chat-msg.center .direct-chat-text {
  margin: 0;
  background: none;
  text-align: center;
  padding: 2px 10px;
  border-color: #ebecef;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .direct-chat-msg.center .direct-chat-text:before,
.obrou-portal .messages .box.direct-chat .direct-chat-messages .direct-chat-msg.center .direct-chat-text:after {
  content: none;
}
.obrou-portal .messages .messageForm {
  margin: 1px 26px 20px;
}
.obrou-portal .messages .messageForm form table {
  width: 100%;
}
.obrou-portal .messages .messageForm form table tr td:first-of-type {
  width: 30%;
}
.obrou-portal .messages .messageForm form table tr td:nth-of-type(2) {
  width: 60px;
}
.obrou-portal .messages .messageForm form table tr td:last-of-type {
  text-align: right;
}
.obrou-portal .messages .messageForm form .form-group {
  margin-bottom: 5px;
}
.obrou-portal .messages .messageForm form .form-group label {
  margin-bottom: 0px;
}
.obrou-portal .messages .messageForm form input[type='text'],
.obrou-portal .messages .messageForm form select {
  width: 100%;
  display: inline-block;
  height: 34px;
}
.obrou-portal .messages .messageForm form select {
  width: 100%;
}
.obrou-portal .messages .messageForm form textarea {
  height: 80px;
  width: 100%;
}
.obrou-portal .messages .messageForm form .dropdown {
  display: inline-block;
}
.obrou-portal .messages .messageForm form .dropdown .dropdown-menu[role=input] {
  padding: 10px 1px 5px 20px;
}
.obrou-portal .messages .messageForm form .dropdown .dropdown-menu[role=input] input {
  margin-right: 10px;
}
.obrou-portal .messages .messageForm form .dropdown button {
  height: 35px;
}
body.obrou-portal .products-list.links .product-description {
  white-space: normal;
}
/* SECTIONS */
.icon-races:before {
  content: "\f091";
}
.icon-trainings:before {
  content: "\f21d";
}
.icon-actualities:before {
  content: "\f0a2";
}
.icon-statistics:before {
  content: "\f080";
}
.icon-gallery:before {
  content: "\f1c5";
}
.icon-money:before {
  content: "\f155";
}
.icon-forum:before {
  content: "\f0e6";
}
.icon-links:before {
  content: "\f064";
}
.icon-timeline:before {
  content: "\f143";
}
.icon-other:before {
  content: "\f097";
}
.icon-eshop:before {
  content: "\f07a";
}
.icon-settings:before {
  content: "\f085";
}
.icon-users:before {
  content: "\f007";
}
.icon-events:before {
  content: "\f073";
}
/* FILES */
.fileIcon.type-image:before {
  content: "\f1c5";
}
.fileIcon.type-pdf:before {
  content: "\f1c1";
}
.fileIcon.type-document:before {
  content: "\f1c2";
}
.fileIcon.type-archive:before {
  content: "\f1c6";
}
.fileIcon.type-text:before {
  content: "\f0f6";
}
.fileIcon.type-other:before {
  content: "\f016";
}
/*  OTHERS */
.toggle-group .toggle-on,
.toggle-group .toggle-on:active {
  color: black;
}
.tooltipHolder {
  font-size: 10px;
  background-color: #E5E5E5;
  color: #A7A7A7;
  width: 16px;
  position: relative;
  display: inline;
  top: -4px;
  left: 5px;
  padding-left: 3px;
  height: 16px;
  text-align: center;
  border: 1px solid #A7A7A7;
  border-radius: 200px 200px 200px 200px;
  -moz-border-radius: 200px 200px 200px 200px;
  -webkit-border-radius: 200px 200px 200px 200px;
}
.obrou-portal .fc .fc-event[data-before] {
  min-height: 28px;
}
.obrou-portal .fc .fc-event:before {
  float: left;
  font: normal normal normal 10px/1 FontAwesome;
  margin-right: 4px;
  margin-left: 1px;
  position: relative;
  top: 2px;
  word-wrap: break-word;
  width: 10px;
  line-height: 14px;
}
.obrou-portal .fc .race {
  border: 1px solid #3a87ad;
  background-color: #3a87ad;
}
.obrou-portal .fc .race:before {
  content: "\f091" attr(data-before);
}
.obrou-portal .fc .training {
  border: 1px solid #00A614;
  background-color: #00A65A;
}
.obrou-portal .fc .training:before {
  content: "\f21d" attr(data-before);
}
.obrou-portal .fc .user {
  border: 1px solid #3a87ad;
  background-color: #3a87ad;
}
.obrou-portal .fc .user:before {
  content: "\f007";
}
.obrou-portal .fc .event {
  border: 1px solid #3207ad;
  background-color: #3207ad;
}
.obrou-portal .fc .event:before {
  content: "\f073";
}
.obrou-portal .fc .trainingPlan {
  background-color: #6f5f62;
}
.obrou-portal .fc .trainingPlan.done {
  background-color: #1eb94a;
}
.obrou-portal .fc .trainingPlan.undone {
  background-color: orangered;
}
.obrou-portal .fc .trainingPlan .tranings_plan_checkbox {
  position: absolute;
  right: 2px;
  top: -2px;
}
.obrou-portal .fc .trainingPlan .trainings_plan_delete {
  position: absolute;
  right: 20px;
  top: 1px;
  display: none;
  color: white;
  z-index: 100;
}
.obrou-portal .fc .trainingPlan:hover .trainings_plan_delete {
  display: inline;
}
.obrou-portal .fc .trainingPlan .fc-content {
  width: calc(85%);
}
.obrou-portal .fc td .fc-past {
  background-color: #EEE;
}
.obrou-portal .fc td .fc-today {
  background-color: #FDD912;
}
body.obrou-portal .modal#confirm-modal modal-body {
  text-align: center;
}
.obrou-portal .tooltip .tooltip-inner .tooltip-race,
.obrou-portal .tooltipster-content .tooltip-race,
.obrou-portal .tooltip .tooltip-inner .tooltip-training,
.obrou-portal .tooltipster-content .tooltip-training,
.obrou-portal .tooltip .tooltip-inner .tooltip-event,
.obrou-portal .tooltipster-content .tooltip-event {
  text-align: left;
}
.obrou-portal .tooltip .tooltip-inner .tooltip-race table td,
.obrou-portal .tooltipster-content .tooltip-race table td,
.obrou-portal .tooltip .tooltip-inner .tooltip-training table td,
.obrou-portal .tooltipster-content .tooltip-training table td,
.obrou-portal .tooltip .tooltip-inner .tooltip-event table td,
.obrou-portal .tooltipster-content .tooltip-event table td {
  vertical-align: top;
}
.obrou-portal .fc-day-grid-event .fc-content {
  white-space: normal;
}
.obrou-portal .tooltip .tooltip-inner {
  max-width: 400px;
}
.obrou-portal .user-panel > .image > img {
  width: 45px;
  height: 45px;
}
.obrou-portal .profile-user-img {
  height: 100px;
}
.obrou-portal .dataTables_paginate {
  text-align: right;
}
.racesGrid table.datagrid td:first-of-type,
.racesGrid table.datagrid td:first-of-type {
  width: 20%;
}
.racesGrid table.datagrid td:nth-of-type(3) {
  max-width: 20%;
}
.racesGrid table.datagrid .filters .dropdown-menu {
  bottom: auto;
  top: 100%;
}
.racesGrid table.datagrid .raceTypeIcon {
  width: 20px;
  height: 20px;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: 3px 5px;
  display: inline-block;
}
.racesGrid table.datagrid .raceTypeIcon.OB {
  background-image: url(../img/obicons/ob.png);
}
.racesGrid table.datagrid .raceTypeIcon.LOB {
  background-image: url(../img/obicons/lob.png);
}
.racesGrid table.datagrid .raceTypeIcon.MTBO {
  background-image: url(../img/obicons/mtbo.png);
}
.racesGrid table.datagrid .raceTypeIcon.OST {
  background-image: url(../img/obicons/ost.png);
}
.racesGrid table.datagrid .raceTypeIcon.TRAIL {
  background-image: url(../img/obicons/trail.png);
}
.raceDetail table.details {
  width: 100%;
}
.raceDetail table.details th {
  vertical-align: top;
  padding: 2px 8px 2px 4px;
}
.raceDetail table.details table.membersOnRace {
  width: 100%;
  margin-top: -3px;
}
.applicationForm input:not([type=checkbox]):not([type=radio]) {
  min-width: 60px;
}
.application .applicationTable {
  width: 100%;
}
body.obrou-portal table.notificationsSetting tr td,
body.obrou-portal table.notificationsSetting tr th {
  padding: 5px 10px;
}
body.obrou-portal table.notificationsSetting tr th {
  text-align: center;
}
body.obrou-portal .notifications-menu ul.dropdown-menu {
  width: 450px;
  min-height: 240px;
}
body.obrou-portal .notifications-menu ul.dropdown-menu .notifications {
  overflow-y: visible;
}
body.obrou-portal .notifications-menu ul.dropdown-menu .notifications li {
  position: relative;
}
body.obrou-portal .notifications-menu ul.dropdown-menu .notifications li.unread {
  background-color: #f4f4f4;
}
body.obrou-portal .notifications-menu ul.dropdown-menu .notifications .time {
  position: absolute;
  bottom: -1px;
  right: 4px;
  padding-right: 5px;
  color: #999;
}
body.obrou-portal .navbar-nav > .notifications-menu > .dropdown-menu > li .menu.notifications > li > a {
  padding-bottom: 16px;
  white-space: normal;
}
.obrou-portal .photoAlbum {
  margin-right: 5px;
}
.obrou-portal .photoAlbum img {
  width: 32%;
}
.obrou-portal .ug-gallery-wrapper.ug-lightbox {
  z-index: 1500;
}
.obrou-portal .discussionPreview table td:last-of-type {
  width: 170px;
}
.obrou-portal .discussionPreview table td:nth-of-type(2) {
  width: 100px;
}
.obrou-portal .discussionPreview .desc-wrapper i {
  margin-left: 10px;
}
.obrou-portal .discussionDetail .info {
  margin: 10px 5px 40px 20px;
}
.obrou-portal .discussionDetail .info table th {
  vertical-align: top;
}
.obrou-portal .discussionDetail .info table td,
.obrou-portal .discussionDetail .info table th {
  padding: 2px 4px;
}
body.obrou-portal .searchResults .widget-user-2 .widget-user-header {
  min-height: 102px;
}
body.obrou-portal .searchResults .widget-user-2 .widget-user-header .widget-user-image img {
  height: 65px;
}
body.obrou-portal .searchResults .widget-race .nav-stacked > li > .widget-row {
  border-radius: 0;
  border-top: 0;
  border-left: 3px solid transparent;
  color: #444;
  padding: 10px 15px;
  position: relative;
  display: block;
}
body.obrou-portal #searchForm {
  overflow: visible;
  z-index: 811;
}
body.obrou-portal #searchForm .easy-autocomplete-container ul {
  top: 36px;
}
body.obrou-portal #searchForm .easy-autocomplete-container ul .eac-category {
  text-align: center;
  border-top: 1px solid black;
}
body.obrou-portal #searchForm .easy-autocomplete-container ul .eac-category:first-of-type {
  border-top: none;
}
body.obrou-portal #searchForm .easy-autocomplete-container ul li {
  border-top: 1px solid lightgrey;
  font-size: small;
}
body.obrou-portal .statistics #rankingTabs .graph {
  width: 100%;
}
/*

    Zebra_DatePicker: a lightweight jQuery date picker plugin

    Default theme

    copyright (c) 2011 - 2014 Stefan Gabos
    http://stefangabos.ro/jquery/zebra-datepicker/

*/
.Zebra_DatePicker *,
.Zebra_DatePicker *:after,
.Zebra_DatePicker *:before {
  -moz-box-sizing: content-box !important;
  -webkit-box-sizing: content-box !important;
  box-sizing: content-box !important;
}
.Zebra_DatePicker {
  position: absolute;
  background: #666;
  border: 3px solid #666;
  z-index: 1200;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  font-size: 13px;
  top: 0;
}
.Zebra_DatePicker * {
  margin: 0;
  padding: 0;
  color: #000;
  background: transparent;
  border: none;
}
/* = GLOBALS
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker table {
  border-collapse: collapse;
  border-spacing: 0;
  width: auto;
  table-layout: auto;
}
.Zebra_DatePicker td,
.Zebra_DatePicker th {
  text-align: center;
  padding: 5px 0;
}
.Zebra_DatePicker td {
  cursor: pointer;
}
.Zebra_DatePicker .dp_daypicker,
.Zebra_DatePicker .dp_monthpicker,
.Zebra_DatePicker .dp_yearpicker {
  margin-top: 3px;
}
.Zebra_DatePicker .dp_daypicker td,
.Zebra_DatePicker .dp_daypicker th,
.Zebra_DatePicker .dp_monthpicker td,
.Zebra_DatePicker .dp_yearpicker td {
  background: #E8E8E8;
  width: 30px;
  border: 1px solid #7BACD2;
}
.Zebra_DatePicker,
.Zebra_DatePicker .dp_header .dp_hover,
.Zebra_DatePicker .dp_footer .dp_hover {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
/* = VISIBLE/HIDDEN STATES (USE TRANSITIONS FOR EFFECTS)
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker.dp_visible {
  visibility: visible;
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
.Zebra_DatePicker.dp_hidden {
  visibility: hidden;
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}
/* = HEADER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_header td {
  color: #FFF;
}
.Zebra_DatePicker .dp_header .dp_previous,
.Zebra_DatePicker .dp_header .dp_next {
  width: 30px;
}
.Zebra_DatePicker .dp_header .dp_caption {
  font-weight: bold;
}
.Zebra_DatePicker .dp_header .dp_hover {
  background: #222;
  color: #FFF;
}
/* = DATEPICKER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_daypicker th {
  background: #FFCC33;
}
.Zebra_DatePicker td.dp_not_in_month {
  background: #F3F3F3;
  color: #CDCDCD;
  cursor: default;
}
.Zebra_DatePicker td.dp_not_in_month_selectable {
  background: #F3F3F3;
  color: #CDCDCD;
  cursor: pointer;
}
.Zebra_DatePicker td.dp_weekend {
  background: #D8D8D8;
}
.Zebra_DatePicker td.dp_weekend_disabled {
  color: #CCC;
  cursor: default;
}
.Zebra_DatePicker td.dp_selected {
  background: #5A4B4B;
  color: #FFF !important;
}
.Zebra_DatePicker td.dp_week_number {
  background: #FFCC33;
  color: #555;
  cursor: text;
  font-style: italic;
}
/* = MONTHPICKER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_monthpicker td {
  width: 33%;
}
/* = YEARPICKER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_yearpicker td {
  width: 33%;
}
/* = FOOTER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_footer {
  margin-top: 3px;
}
.Zebra_DatePicker .dp_footer .dp_hover {
  background: #222;
  color: #FFF;
}
/* = SELECT CURRENT DAY
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_today {
  color: #FFF;
  padding: 3px;
}
/* = CLEAR DATE
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_clear {
  color: #FFF;
  padding: 3px;
}
/* = SOME MORE GLOBALS (MUST BE LAST IN ORDER TO OVERWRITE PREVIOUS PROPERTIES)
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker td.dp_current {
  color: #C40000;
}
.Zebra_DatePicker td.dp_disabled_current {
  color: #E38585;
}
.Zebra_DatePicker td.dp_disabled {
  background: #F3F3F3;
  color: #CDCDCD;
  cursor: default;
}
.Zebra_DatePicker td.dp_hover {
  background: #482424;
  color: #FFF;
}
/* = ICON
----------------------------------------------------------------------------------------------------------------------*/
button.Zebra_DatePicker_Icon {
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  background: url('../img/calendar.png') no-repeat left top;
  text-indent: -9000px;
  border: none;
  cursor: pointer;
  padding: 0;
  line-height: 0;
  vertical-align: top;
}
button.Zebra_DatePicker_Icon_Disabled {
  background-image: url('../images/calendar-disabled.png');
}
/* don't set vertical margins! */
button.Zebra_DatePicker_Icon {
  margin: 0 0 0 3px;
}
button.Zebra_DatePicker_Icon_Inside_Right {
  margin: 0 3px 0 0;
}
button.Zebra_DatePicker_Icon_Inside_Left {
  margin: 0 0 0 3px;
}
/* @activeColor: #c0392b; //red */
.bodineChecks {
  /* $activeColor: #c0392b; //red */
  /* $background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/13460/dark_wall.png'); */
  /* .slideOne */
  /* end .slideOne */
  /* .slideTwo */
  /* end .slideTwo */
  /* .slideThree */
  /* end .slideThree */
  /* .roundedOne */
  /* end .roundedOne */
  /* .roundedTwo */
  /* end .roundedTwo */
  /* .squaredOne */
  /* end .squaredOne */
  /* .squaredTwo */
  /* end .squaredTwo */
  /* .squaredThree */
  /* end .squaredThree */
  /* .squaredFour */
  /* end .squaredFour */
}
.bodineChecks .slideOne {
  width: 50px;
  height: 10px;
  background: #333;
  margin: 20px auto;
  position: relative;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
}
.bodineChecks .slideOne label {
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: -3px;
  left: -3px;
  cursor: pointer;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border-radius: 50px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.bodineChecks .slideOne input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .slideOne input[type=checkbox]:checked + label {
  left: 37px;
}
.bodineChecks .slideTwo {
  width: 80px;
  height: 30px;
  background: #333;
  margin: 20px auto;
  position: relative;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
}
.bodineChecks .slideTwo:after {
  content: '';
  position: absolute;
  top: 14px;
  left: 14px;
  height: 2px;
  width: 52px;
  background: #111;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
}
.bodineChecks .slideTwo label {
  display: block;
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  top: 4px;
  z-index: 1;
  left: 4px;
  background: #fcfff4;
  border-radius: 50px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
}
.bodineChecks .slideTwo label:after {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 6px;
  left: 6px;
  background: #333;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px black, 0px 1px 0px rgba(255, 255, 255, 0.9);
}
.bodineChecks .slideTwo input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .slideTwo input[type=checkbox]:checked + label {
  left: 54px;
}
.bodineChecks .slideTwo input[type=checkbox]:checked + label:after {
  background: #27ae60;
  /*activeColor*/
}
.bodineChecks .slideThree {
  width: 80px;
  height: 26px;
  background: #333;
  margin: 20px auto;
  position: relative;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
}
.bodineChecks .slideThree:after {
  content: 'OFF';
  color: #000;
  position: absolute;
  right: 10px;
  z-index: 0;
  font: 12px/26px Arial, sans-serif;
  font-weight: bold;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.15);
}
.bodineChecks .slideThree:before {
  content: 'ON';
  color: #27ae60;
  position: absolute;
  left: 10px;
  z-index: 0;
  font: 12px/26px Arial, sans-serif;
  font-weight: bold;
}
.bodineChecks .slideThree label {
  display: block;
  width: 34px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border-radius: 50px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
}
.bodineChecks .slideThree input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .slideThree input[type=checkbox]:checked + label {
  left: 43px;
}
.bodineChecks .roundedOne {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 20px auto;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.bodineChecks .roundedOne label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  left: 4px;
  top: 4px;
  background: -webkit-linear-gradient(top, #222222 0%, #45484d 100%);
  background: linear-gradient(to bottom, #222222 0%, #45484d 100%);
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
}
.bodineChecks .roundedOne label:after {
  content: '';
  width: 16px;
  height: 16px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: #27ae60;
  background: -webkit-linear-gradient(top, #27ae60 0%, #145b32 100%);
  background: linear-gradient(to bottom, #27ae60 0%, #145b32 100%);
  opacity: 0;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.bodineChecks .roundedOne label:hover::after {
  opacity: 0.3;
}
.bodineChecks .roundedOne input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .roundedOne input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.bodineChecks .roundedTwo {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 20px auto;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.bodineChecks .roundedTwo label {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  left: 4px;
  cursor: pointer;
  background: -webkit-linear-gradient(top, #222222 0%, #3c8dbc 100%);
  background: linear-gradient(to bottom, #222222 0%, #3c8dbc 100%);
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
}
.bodineChecks .roundedTwo label:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  top: 6px;
  left: 5px;
  border: 3px solid #fcfff4;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.bodineChecks .roundedTwo label:hover::after {
  opacity: 0.3;
}
.bodineChecks .roundedTwo input[type=checkbox],
.bodineChecks .roundedTwo input[type=radio] {
  visibility: hidden;
}
.bodineChecks .roundedTwo input[type=checkbox]:checked + label:after,
.bodineChecks .roundedTwo input[type=radio]:checked + label:after {
  opacity: 1;
}
.bodineChecks .squaredOne {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 20px auto;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.bodineChecks .squaredOne label {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  left: 4px;
  cursor: pointer;
  background: -webkit-linear-gradient(top, #222222 0%, #45484d 100%);
  background: linear-gradient(to bottom, #222222 0%, #45484d 100%);
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
}
.bodineChecks .squaredOne label:after {
  content: '';
  width: 16px;
  height: 16px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: #27ae60;
  background: -webkit-linear-gradient(top, #27ae60 0%, #145b32 100%);
  background: linear-gradient(to bottom, #27ae60 0%, #145b32 100%);
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
  opacity: 0;
}
.bodineChecks .squaredOne label:hover::after {
  opacity: 0.3;
}
.bodineChecks .squaredOne input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .squaredOne input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.bodineChecks .squaredTwo {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 20px auto;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.bodineChecks .squaredTwo label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  left: 4px;
  top: 4px;
  background: -webkit-linear-gradient(top, #222222 0%, #45484d 100%);
  background: linear-gradient(to bottom, #222222 0%, #45484d 100%);
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
}
.bodineChecks .squaredTwo label:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 3px solid #fcfff4;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.bodineChecks .squaredTwo label:hover::after {
  opacity: 0.3;
}
.bodineChecks .squaredTwo input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .squaredTwo input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.bodineChecks .squaredThree {
  width: 20px;
  position: relative;
  margin: 20px auto;
}
.bodineChecks .squaredThree label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background: -webkit-linear-gradient(top, #222222 0%, #45484d 100%);
  background: linear-gradient(to bottom, #222222 0%, #45484d 100%);
  border-radius: 4px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.4);
}
.bodineChecks .squaredThree label:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 3px solid #fcfff4;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.bodineChecks .squaredThree label:hover::after {
  opacity: 0.3;
}
.bodineChecks .squaredThree input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .squaredThree input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.bodineChecks .squaredFour {
  width: 20px;
  position: relative;
  margin: 20px auto;
}
.bodineChecks .squaredFour label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border-radius: 4px;
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.bodineChecks .squaredFour label:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 3px solid #333;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.bodineChecks .squaredFour label:hover::after {
  opacity: 0.5;
}
.bodineChecks .squaredFour input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .squaredFour input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.bodineChecks * {
  box-sizing: border-box;
}
.bodineChecks body {
  background: #3498db;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}
.bodineChecks body h1,
.bodineChecks body h2,
.bodineChecks body em {
  color: #eee;
  font-size: 30px;
  text-align: center;
  margin: 20px 0 0 0;
  -webkit-font-smoothing: antialiased;
  text-shadow: 0px 1px #000;
}
.bodineChecks body em {
  font-size: 14px;
  text-align: center;
  display: block;
  margin-bottom: 50px;
}
.bodineChecks body .ondisplay {
  text-align: center;
  padding: 20px 0;
}
.bodineChecks body .ondisplay section {
  width: 100px;
  height: 100px;
  background: #555;
  display: inline-block;
  position: relative;
  text-align: center;
  margin-top: 5px;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.bodineChecks body .ondisplay section:after {
  /*         visibility: hidden; */
  content: attr(title);
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-shadow: 0px 1px #000;
}
.inlineCheckboxes .inlineCheckbox {
  display: inline-block;
  position: relative;
  text-align: center;
  min-width: 60px;
}
.inlineCheckboxes .inlineCheckbox section {
  display: block;
}
.inlineCheckboxes .inlineCheckbox span.label {
  position: relative;
  color: black;
  top: -10px;
}
/*!
 * @copyright &copy; Kartik Visweswaran, Krajee.com, 2013 - 2016
 * @version 4.0.1
 *
 * A simple yet powerful JQuery star rating plugin that allows rendering fractional star ratings and supports
 * Right to Left (RTL) input.
 *
 * For more JQuery/Bootstrap plugins and demos visit http://plugins.krajee.com
 * For more Yii related demos visit http://demos.krajee.com
 */
.rating-loading {
  width: 25px;
  height: 25px;
  font-size: 0;
  color: #fff;
  background: transparent url('../img/loading.gif') top left no-repeat;
  border: none;
}
/*
 * Stars
 */
.rating-container .rating {
  cursor: default;
  position: relative;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
.rating-disabled .rating {
  cursor: not-allowed;
}
.rating-container .star {
  display: inline-block;
  margin: 0 3px;
  text-align: center;
}
.rating-container .empty-stars {
  color: #aaa;
}
.rating-container .filled-stars {
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  color: #fde16d;
  white-space: nowrap;
  overflow: hidden;
  -webkit-text-stroke: 1px #777;
  text-shadow: 1px 1px #999;
}
.rating-rtl {
  float: right;
}
.rating-animate .filled-stars {
  transition: width 0.25s ease;
  -o-transition: width 0.25s ease;
  -moz-transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
}
.rating-rtl .filled-stars {
  left: auto;
  right: 0;
  -moz-transform: matrix(-1, 0, 0, 1, 0, 0) translate3d(0, 0, 0);
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0) translate3d(0, 0, 0);
  -o-transform: matrix(-1, 0, 0, 1, 0, 0) translate3d(0, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0) translate3d(0, 0, 0);
}
.rating-rtl.is-star .filled-stars {
  right: 0.06em;
}
.rating-rtl.is-heart .empty-stars {
  margin-right: 0.07em;
}
/**
 * Sizes
 */
.rating-xl {
  font-size: 4.89em;
}
.rating-lg {
  font-size: 3.91em;
}
.rating-md {
  font-size: 3.13em;
}
.rating-sm {
  font-size: 2.5em;
}
.rating-xs {
  font-size: 2em;
}
.rating-xxs {
  font-size: 1.2em;
}
.rating-xl {
  font-size: 4.89em;
}
/**
 * Clear
 */
.rating-container .clear-rating {
  color: #aaa;
  cursor: not-allowed;
  display: inline-block;
  vertical-align: middle;
  font-size: 60%;
}
.clear-rating-active {
  cursor: pointer !important;
}
.clear-rating-active:hover {
  color: #843534;
}
.rating-container .clear-rating {
  padding-right: 5px;
}
/**
 * Caption
 */
.rating-container .caption {
  color: #999;
  display: inline-block;
  vertical-align: middle;
  font-size: 60%;
  margin-top: -0.6em;
}
.rating-container .caption {
  margin-left: 5px;
  margin-right: 0;
}
.rating-rtl .caption {
  margin-right: 5px;
  margin-left: 0;
}
/**
 * Print
 */
@media print {
  .rating-container .clear-rating {
    display: none;
  }
}
.ug-gallery-wrapper,
.ug-gallery-wrapper * {
  transition: all !important;
  -o-transition: all !important;
  -webkit-transition: all;
  -moz-transition: all !important;
  -ms-transition: all !important;
  max-width: none;
}
.ug-gallery-wrapper {
  z-index: 0;
}
.ug-item-wrapper img {
  width: auto;
  max-width: none;
  margin: 0px;
  padding: 0px;
}
.ug-videoplayer-wrapper video {
  height: 100%;
  width: 100%;
  visibility: visible !important;
}
.ug-rtl {
  direction: rtl;
}
.ug-gallery-wrapper {
  overflow: hidden;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
}
.ug-error-message {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 20px;
  color: #F30D0D;
}
.ug-error-message-wrapper {
  width: 100%;
  height: 100%;
  border: 1px solid black;
}
.ug-gallery-wrapper .ug-canvas-pie {
  position: absolute;
  z-index: 4;
}
.ug-gallery-wrapper .ug-progress-bar {
  position: absolute;
  overflow: hidden;
  z-index: 4;
}
.ug-gallery-wrapper .ug-progress-bar-inner {
  xposition: absolute;
}
.ug-gallery-wrapper.ug-fullscreen {
  height: 100% !important;
  width: 100% !important;
  max-width: none !important;
  max-height: none !important;
}
.ug-gallery-wrapper.ug-fake-fullscreen {
  position: fixed !important;
  height: auto !important;
  width: auto !important;
  max-width: none !important;
  max-height: none !important;
  margin: 0px !important;
  padding: 0px !important;
  top: 0px !important;
  left: 0px !important;
  bottom: 0px !important;
  right: 0px !important;
  z-index: 999999 !important;
}
.ug-body-fullscreen {
  overflow: hidden !important;
  height: 100% !important;
  width: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
}
.ug-gallery-wrapper .ug-preloader-trans {
  display: block;
  height: 35px;
  width: 32px;
  height: 32px;
  background-image: url('/bower_components/unitegallery/dist//images/loader_skype_trans.gif');
  background-repeat: no-repeat;
}
.ug-gallery-wrapper .ug-slider-wrapper {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  background-color: #000000;
}
.ug-slider-wrapper .ug-slide-wrapper {
  position: absolute;
}
.ug-slide-wrapper.ug-slide-clickable {
  cursor: pointer;
}
.ug-slider-wrapper .ug-slider-inner {
  position: absolute;
  z-index: 0;
}
.ug-slider-wrapper .ug-item-wrapper {
  position: absolute;
  overflow: hidden;
}
.ug-slider-wrapper,
.ug-item-wrapper img {
  -webkit-user-select: none;
  /* make unselectable */
  -moz-user-select: none;
  -ms-user-select: none;
  max-width: none !important;
  box-sizing: border-box;
}
.ug-slider-wrapper .ug-slider-preloader {
  position: absolute;
  z-index: 1;
  border: 2px solid #ffffff;
  border-radius: 6px;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
}
.ug-slider-preloader.ug-loader1 {
  width: 30px;
  height: 30px;
  background-image: url('/bower_components/unitegallery/dist//images/loader-white1.gif');
}
.ug-slider-preloader.ug-loader1.ug-loader-black {
  background-image: url('/bower_components/unitegallery/dist//images/loader-black1.gif');
}
.ug-slider-preloader.ug-loader2 {
  width: 32px;
  height: 32px;
  background-image: url('/bower_components/unitegallery/dist//images/loader-white2.gif');
}
.ug-slider-preloader.ug-loader2.ug-loader-black {
  background-image: url('/bower_components/unitegallery/dist//images/loader-black2.gif');
}
.ug-slider-preloader.ug-loader3 {
  width: 38px;
  height: 38px;
  background-image: url('/bower_components/unitegallery/dist//images/loader-white3.gif');
}
.ug-slider-preloader.ug-loader3.ug-loader-black {
  background-image: url('/bower_components/unitegallery/dist//images/loader-black3.gif');
}
.ug-slider-preloader.ug-loader4 {
  width: 32px;
  height: 32px;
  background-image: url('/bower_components/unitegallery/dist//images/loader-white4.gif');
  background-color: white;
}
.ug-slider-preloader.ug-loader4.ug-loader-black {
  background-image: url('/bower_components/unitegallery/dist//images/loader-black4.gif');
}
.ug-slider-preloader.ug-loader5 {
  width: 60px;
  height: 8px;
  background-image: url('/bower_components/unitegallery/dist//images/loader-white5.gif');
  background-color: white;
  border: none;
  border-radius: 0px;
}
.ug-slider-preloader.ug-loader5.ug-loader-black {
  background-image: url('/bower_components/unitegallery/dist//images/loader-black5.gif');
  border: 2px solid #000000;
}
.ug-slider-preloader.ug-loader6 {
  width: 32px;
  height: 32px;
  background-image: url('/bower_components/unitegallery/dist//images/loader-white6.gif');
}
.ug-slider-preloader.ug-loader6.ug-loader-black {
  background-image: url('/bower_components/unitegallery/dist//images/loader-black6.gif');
}
.ug-slider-preloader.ug-loader7 {
  width: 32px;
  height: 10px;
  background-image: url('/bower_components/unitegallery/dist//images/loader-white7.gif');
  border-width: 3px;
  border-radius: 3px;
}
.ug-slider-preloader.ug-loader7.ug-loader-black {
  background-image: url('/bower_components/unitegallery/dist//images/loader-black7.gif');
}
.ug-slider-preloader.ug-loader-black {
  border-color: #000000;
  background-color: #000000;
}
.ug-slider-preloader.ug-loader-nobg {
  background-color: transparent;
}
.ug-slider-wrapper .ug-button-videoplay {
  position: absolute;
  z-index: 2;
  cursor: pointer;
}
.ug-slider-wrapper .ug-button-videoplay.ug-type-square {
  width: 86px;
  height: 66px;
  background-image: url('/bower_components/unitegallery/dist//images/play-button-square.png');
  background-position: 0px -66px;
}
.ug-slider-wrapper .ug-button-videoplay.ug-type-square.ug-button-hover {
  background-position: 0px 0px;
}
.ug-slider-wrapper .ug-button-videoplay.ug-type-round {
  width: 76px;
  height: 76px;
  background-image: url('/bower_components/unitegallery/dist//images/play-button-round.png');
  opacity: 0.9;
  filter: alpha(opacity = 90);
  transition: all 0.3s ease 0s !important;
}
.ug-slider-wrapper .ug-button-videoplay.ug-type-round.ug-button-hover {
  opacity: 1;
  filter: alpha(opacity = 100);
  transition: all 0.3s ease 0s !important;
}
.ug-gallery-wrapper .ug-videoplayer {
  position: absolute;
  z-index: 100;
  background-color: #000000;
  background-image: url('/bower_components/unitegallery/dist//images/loader-black1.gif');
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.ug-videoplayer .ug-videoplayer-wrapper {
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-image: url('/bower_components/unitegallery/dist//images/loader-black1.gif');
  background-repeat: no-repeat;
  background-position: center center;
}
.ug-videoplayer .ug-videoplayer-button-close {
  position: absolute;
  height: 64px;
  width: 64px;
  background-image: url('/bower_components/unitegallery/dist//images/button-close.png');
  cursor: pointer;
  z-index: 1000;
}
.ug-videoplayer .ug-videoplayer-button-close:hover {
  background-position: 0px -64px;
}
.ug-gallery-wrapper .ug-thumbs-strip,
.ug-gallery-wrapper .ug-thumbs-grid {
  position: absolute;
  overflow: hidden;
  -webkit-user-select: none;
  /* make unselectable */
  -moz-user-select: none;
  -ms-user-select: none;
  xbackground-color: green;
  z-index: 10;
}
.ug-thumbs-strip.ug-dragging {
  xcursor: -webkit-grab;
  xcursor: -moz-grab;
}
.ug-gallery-wrapper .ug-thumbs-strip-inner,
.ug-gallery-wrapper .ug-thumbs-grid-inner {
  position: absolute;
}
.ug-thumb-wrapper.ug-thumb-generated {
  position: absolute;
  overflow: hidden;
  -webkit-user-select: none;
  /* make unselectable */
  -moz-user-select: none;
  -ms-user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-loader,
.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-error {
  position: absolute;
  top: 0px;
  left: 0px;
  background-repeat: no-repeat;
  background-position: center center;
}
.ug-thumb-wrapper .ug-thumb-border-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 4;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-style: solid;
}
.ug-thumb-wrapper .ug-thumb-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
}
.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-loader-dark {
  background-image: url('/bower_components/unitegallery/dist//images/loader.gif');
}
.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-loader-bright {
  background-image: url('/bower_components/unitegallery/dist//images/loader_bright.gif');
}
.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-error {
  background-image: url('/bower_components/unitegallery/dist//images/not_loaded.png');
}
.ug-thumb-wrapper.ug-thumb-generated img {
  position: absolute;
  z-index: 1;
  max-width: none !important;
}
.ug-thumb-wrapper.ug-thumb-generated img.ug-thumb-image-overlay {
  position: absolute;
  z-index: 2;
  max-width: none !important;
}
/* bw desaturate effect lightweight */
img.ug-bw-effect {
  filter: grayscale(100%);
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android */
  -moz-filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
/* blur effect desaturate effect lightweight */
img.ug-blur-effect {
  filter: blur(0.8px);
  -webkit-filter: blur(0.8px);
  -moz-filter: blur(0.8px);
  -o-filter: blur(0.8px);
  -ms-filter: blur(0.8px);
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id='blur'><feGaussianBlur stdDeviation=\'0.8\' /></filter></svg>#blur");
  /* Firefox 10+, Firefox on Android */
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0.8');
}
img.ug-sepia-effect {
  -webkit-filter: sepia(1);
  -webkit-filter: sepia(100%);
  -moz-filter: sepia(100%);
  -ms-filter: sepia(100%);
  -o-filter: sepia(100%);
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'sepia\'><feColorMatrix values=\'0.14 0.45 0.05 0 0 0.12 0.39 0.04 0 0 0.08 0.28 0.03 0 0 0 0 0 1 0\' /></filter></svg>#sepia");
  /* Firefox 10+, Firefox on Android */
  filter: sepia(100%);
  background-color: #5E2612;
  filter: alpha(opacity = 50);
  zoom: 1;
}
.ug-gallery-wrapper .ug-bullets {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
}
.ug-gallery-wrapper .ug-bullets .ug-bullet {
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
  float: left;
  z-index: 2;
}
.ug-gallery-wrapper .ug-bullets .ug-bullet:first-child {
  margin-left: 0px;
}
.ug-gallery-wrapper .ug-textpanel {
  position: absolute;
  overflow: hidden;
}
.ug-slider-wrapper .ug-textpanel {
  z-index: 2;
}
.ug-gallery-wrapper .ug-textpanel-bg,
.ug-gallery-wrapper .ug-textpanel-title,
.ug-gallery-wrapper .ug-textpanel-description {
  display: block;
  position: absolute;
  margin: 0px;
  padding: 0px;
}
.ug-gallery-wrapper .ug-textpanel-bg {
  background-color: #000000;
  opacity: 0.4;
  filter: alpha(opacity = 40);
  z-index: 1;
}
.ug-textpanel-textwrapper {
  overflow: hidden;
}
.ug-gallery-wrapper .ug-textpanel-title {
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: left;
  z-index: 2;
}
.ug-gallery-wrapper .ug-textpanel-description {
  font-size: 14px;
  color: white;
  text-align: left;
  z-index: 3;
}
.ug-slider-wrapper .ug-zoompanel {
  position: absolute;
  top: 200px;
  left: 100px;
}
.ug-zoompanel .ug-zoompanel-button {
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
}
.ug-gallery-wrapper .ug-arrow-left {
  position: absolute;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
}
.ug-gallery-wrapper .ug-arrow-right {
  position: absolute;
  background-repeat: no-repeat;
  cursor: pointer;
}
.ug-gallery-wrapper .ug-grid-panel {
  position: absolute;
  background-color: #000000;
  xbackground-color: blue;
  z-index: 2;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow {
  position: absolute;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 11;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow.ug-button-disabled {
  cursor: default;
}
/* handle tip */
.ug-gallery-wrapper .ug-panel-handle-tip {
  position: absolute;
  z-index: 13;
  cursor: pointer;
}
.ug-gallery-wrapper .ug-overlay-disabled {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.3;
  filter: alpha(opacity=30);
  background-image: url('/bower_components/unitegallery/dist//images/cover-grid.png');
}
.ug-gallery-wrapper .ug-strip-panel {
  position: absolute;
  background-color: #000000;
  z-index: 2;
}
.ug-strip-panel .ug-strip-arrow {
  position: absolute;
  cursor: pointer;
}
.ug-strip-panel .ug-strip-arrow.ug-button-disabled {
  cursor: default;
}
.ug-strip-panel .ug-strip-arrow-tip {
  position: absolute;
}
.ug-thumb-wrapper.ug-tile {
  display: block;
  position: absolute;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}
.ug-tile .ug-tile-cloneswrapper {
  position: absolute;
  width: 0px;
  height: 0px;
  top: 0px;
  left: 0px;
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity = 90);
}
/* transit something */
/* transit fixed size items */
/* transit image overlay */
.ug-tiles-transit.ug-tiles-transition-active .ug-thumb-wrapper.ug-tile,
.ug-tiles-transit.ug-tiles-transition-active .ug-thumb-wrapper.ug-tile .ug-trans-enabled {
  transition: left 0.7s ease 0s, top 0.7s ease 0s, width 0.7s ease 0s, height 0.7s ease 0s !important;
}
.ug-thumb-wrapper.ug-tile.ug-tile-clickable {
  cursor: pointer;
}
.ug-thumb-wrapper.ug-tile .ug-image-container {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  top: 0px;
  left: 0px;
}
.ug-thumb-wrapper.ug-tile img.ug-thumb-image {
  -webkit-user-select: none;
  /* make unselectable */
  -moz-user-select: none;
  -ms-user-select: none;
  max-width: none !important;
  max-height: none !important;
  display: block;
  border: none;
  padding: 0px !important;
  margin: 0px !important;
  width: auto;
  height: auto;
  position: absolute;
}
.ug-thumb-wrapper.ug-tile .ug-tile-image-overlay {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  top: 0px;
  left: 0px;
}
.ug-thumb-wrapper.ug-tile .ug-tile-image-overlay img {
  width: 100%;
  max-width: none !important;
}
.ug-tiletype-justified .ug-thumb-wrapper.ug-tile .ug-tile-image-overlay img {
  height: 100%;
  max-width: none !important;
}
.ug-thumb-wrapper.ug-tile .ug-tile-icon {
  position: absolute;
  width: 38px;
  height: 38px;
  background-repeat: no-repeat;
  background-position: 0 0;
  xbackground-color: green;
  z-index: 6;
  top: 1px;
  left: 1px;
  cursor: pointer;
}
.ug-thumb-wrapper.ug-tile .ug-tile-icon.ug-icon-link {
  background-image: url('/bower_components/unitegallery/dist//images/icon-link32.png');
}
.ug-thumb-wrapper.ug-tile .ug-tile-icon.ug-icon-zoom {
  background-image: url('/bower_components/unitegallery/dist//images/icon-zoom32.png');
}
.ug-thumb-wrapper.ug-tile .ug-tile-icon.ug-icon-play {
  background-image: url('/bower_components/unitegallery/dist//images/icon-play32.png');
}
.ug-thumb-wrapper.ug-tile .ug-tile-icon:hover {
  background-position: 0 -38px;
}
.ug-thumbs-grid .ug-tile-image-overlay img {
  width: auto;
  height: auto;
  position: absolute;
  max-width: none !important;
}
/* text panel on tiles*/
.ug-tile .ug-textpanel {
  z-index: 5;
  cursor: default;
}
.ug-tile.ug-tile-clickable .ug-textpanel {
  cursor: pointer;
}
.ug-tile .ug-textpanel-title {
  font-size: 14px;
  font-weight: bold;
}
.ug-gallery-wrapper .ug-carousel-wrapper {
  position: absolute;
  overflow: hidden;
}
.ug-gallery-wrapper .ug-carousel-inner {
  position: absolute;
}
.ug-gallery-wrapper.ug-lightbox {
  display: none;
  position: fixed;
  border: none;
  padding: 0px;
  margin: 0px;
  outline: invert none medium;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: none !important;
  max-height: none !important;
  z-index: 20;
}
.ug-lightbox .ug-lightbox-top-panel {
  position: absolute;
  z-index: 2;
}
.ug-lightbox .ug-lightbox-top-panel-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000000;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.ug-lightbox .ug-lightbox-overlay {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 1;
}
.ug-lightbox .ug-slider-wrapper {
  z-index: 1;
  xbackground-color: green;
  background-color: transparent !important;
}
.ug-lightbox .ug-textpanel {
  z-index: 2;
}
.ug-lightbox .ug-lightbox-arrow-left,
.ug-lightbox .ug-lightbox-arrow-right {
  position: absolute;
  width: 50px;
  height: 55px;
  background-repeat: no-repeat;
  background-image: url('/bower_components/unitegallery/dist//images/lightbox-arrow-left.png');
  background-position: 0px 0px;
  z-index: 3;
  cursor: pointer;
}
.ug-lightbox .ug-lightbox-arrow-right {
  background-image: url('/bower_components/unitegallery/dist//images/lightbox-arrow-right.png');
}
.ug-lightbox .ug-lightbox-button-close {
  position: absolute;
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-image: url('/bower_components/unitegallery/dist//images/lightbox-icon-close.png');
  background-position: 0px 0px;
  z-index: 4;
  cursor: pointer;
}
.ug-lightbox-compact .ug-lightbox-button-close {
  width: 45px;
  height: 41px;
  background-image: url('/bower_components/unitegallery/dist//images/lightbox-icon-close-compact2.png');
}
.ug-lightbox .ug-lightbox-arrow-left.ug-arrow-hover,
.ug-lightbox .ug-lightbox-arrow-right.ug-arrow-hover {
  background-position: 0px -55px;
}
.ug-lightbox .ug-lightbox-button-close.ug-button-hover {
  background-position: 0px -35px;
}
.ug-lightbox-compact .ug-lightbox-button-close.ug-button-hover {
  background-position: 0px -40px;
}
.ug-lightbox .ug-lightbox-numbers {
  position: absolute;
  color: #e5e5e5;
  font-size: 12px;
  top: 14px;
  left: 8px;
  z-index: 3;
}
.ug-lightbox-compact .ug-lightbox-numbers {
  font-size: 14px;
  padding-right: 5px;
  padding-top: 7px;
}
.ug-lightbox .ug-textpanel-title,
.ug-lightbox .ug-textpanel-description {
  font-size: 14px;
  font-weight: normal;
  color: #e5e5e5;
}
.ug-lightbox-compact .ug-textpanel-title,
.ug-lightbox-compact .ug-textpanel-description {
  color: #F3F3F3;
  font-size: 16px;
}
.ug-tabs-wrapper {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
}
.ug-tabs-wrapper a.ug-tab {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 5px;
  background-color: #333333;
  border-radius: 3px;
  font-size: 12px;
  font-weight: normal;
  border: 1px solid #969696;
}
.ug-tabs-wrapper a.ug-tab:hover {
  background-color: #707070 !important;
}
.ug-tabs-wrapper a.ug-tab.ug-tab-selected,
.ug-tabs-wrapper a.ug-tab.ug-tab-selected:hover {
  background-color: #2077A2 !important;
  cursor: default !important;
}
.ug-tabs-wrapper a.ug-tab:first-child {
  margin-left: 0px !important;
}
/* --- arrows -- */
.ug-arrow-left.ug-skin-default,
.ug-arrow-right.ug-skin-default {
  width: 15px;
  height: 30px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/slider_arrow_left.png');
  background-position: 0px 0px;
}
.ug-arrow-right.ug-skin-default {
  background-image: url('/bower_components/unitegallery/dist/skins/default/slider_arrow_right.png');
}
.ug-arrow-left.ug-skin-default.ug-arrow-hover,
.ug-arrow-right.ug-skin-default.ug-arrow-hover {
  background-position: 0px -30px;
}
.ug-arrow-left.ug-skin-default.ug-arrow-disabled,
.ug-arrow-right.ug-skin-default.ug-arrow-disabled {
  background-position: 0px -60px;
}
/* --- bullets -- */
.ug-bullets.ug-skin-default .ug-bullet {
  width: 15px;
  height: 15px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/slider_bullets.png');
  background-position: top left;
  margin-left: 5px;
}
.ug-bullets.ug-skin-default .ug-bullet.ug-bullet-active {
  background-position: bottom left;
}
.ug-bullets.ug-skin-default.ug-bullets-gray .ug-bullet {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_bullets_gray.png');
}
.ug-bullets.ug-skin-default.ug-bullets-blue .ug-bullet {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_bullets_blue.png');
}
.ug-bullets.ug-skin-default.ug-bullets-brown .ug-bullet {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_bullets_brown.png');
}
.ug-bullets.ug-skin-default.ug-bullets-green .ug-bullet {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_bullets_green.png');
}
.ug-bullets.ug-skin-default.ug-bullets-red .ug-bullet {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_bullets_red.png');
}
.ug-button-tile-navigation {
  width: 36px;
  height: 36px;
  background-position: top left;
  cursor: pointer;
  float: left;
}
.ug-button-tile-navigation.ug-button-tile-left {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_button_left.png');
}
.ug-button-tile-navigation.ug-button-tile-right {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_button_right.png');
}
.ug-button-tile-navigation:hover {
  background-position: bottom left;
}
.ug-button-tile-navigation.ug-button-disabled {
  opacity: 0.6;
}
.ug-button-tile-navigation.ug-button-disabled:hover {
  background-position: top left;
}
.ug-button-tile-navigation.ug-button-tile-play {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_button_play_pause.png');
  width: 35px;
}
.ug-button-tile-navigation.ug-button-tile-play.ug-pause-icon {
  background-position: bottom left;
}
.ug-button-tile-navigation.ug-button-tile-play:hover {
  background-position: top left;
}
.ug-button-tile-navigation.ug-button-tile-play.ug-pause-icon:hover {
  background-position: bottom left;
}
/* --- play button -- */
.ug-button-play.ug-skin-default {
  position: absolute;
  background-image: url('/bower_components/unitegallery/dist/skins/default/button_playpause.png');
  width: 14px;
  height: 17px;
  cursor: pointer;
  background-position: 0px 0px;
}
.ug-button-play.ug-skin-default.ug-button-hover {
  background-position: 0px -17px;
}
.ug-button-play.ug-skin-default.ug-stop-mode {
  background-position: 0px -34px;
}
.ug-button-play.ug-skin-default.ug-stop-mode.ug-button-hover {
  background-position: 0px -51px;
}
/* --- fullscreen button -- */
.ug-button-fullscreen.ug-skin-default {
  position: absolute;
  background-image: url('/bower_components/unitegallery/dist/skins/default/button_fullscreen.png');
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-position: 0px 0px;
}
.ug-button-fullscreen.ug-skin-default.ug-button-hover {
  background-position: 0px -16px;
}
.ug-button-fullscreen.ug-skin-default.ug-fullscreenmode {
  background-position: 0px -32px;
}
.ug-button-fullscreen.ug-skin-default.ug-fullscreenmode.ug-button-hover {
  background-position: 0px -48px;
}
/* --- zoom panel --- */
.ug-zoompanel.ug-skin-default .ug-zoompanel-button {
  background-repeat: no-repeat;
  cursor: pointer;
  height: 23px;
  width: 23px;
  margin-top: 5px;
  background-position: 0px 0px;
}
.ug-zoompanel.ug-skin-default .ug-zoompanel-plus {
  margin-top: 0px;
}
.ug-zoompanel.ug-skin-default .ug-zoompanel-plus {
  background-image: url('/bower_components/unitegallery/dist/skins/default/icon_zoom_plus.png');
}
.ug-zoompanel.ug-skin-default .ug-zoompanel-minus {
  background-image: url('/bower_components/unitegallery/dist/skins/default/icon_zoom_minus.png');
}
.ug-zoompanel.ug-skin-default .ug-zoompanel-return {
  background-image: url('/bower_components/unitegallery/dist/skins/default/icon_zoom_back.png');
}
.ug-zoompanel.ug-skin-default .ug-zoompanel-button.ug-button-hover {
  background-position: 0px -23px;
}
.ug-zoompanel.ug-skin-default .ug-zoompanel-button-disabled,
.ug-zoompanel.ug-skin-default .ug-zoompanel-button.ug-button-hover.ug-zoompanel-button-disabled {
  background-position: 0px -46px;
  cursor: default;
}
/* --- Grid Panel Arrows -- */
/*--- down arrow ---*/
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-down.ug-skin-default {
  width: 30px;
  height: 15px;
  background-position: 0px 0px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/arrow_grid_down.png');
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-down.ug-skin-default.ug-button-hover {
  background-position: 0px -15px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-down.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-down.ug-skin-default.ug-button-disabled {
  background-position: 0px -30px;
}
/*--- up arrow ---*/
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-up.ug-skin-default {
  width: 30px;
  height: 15px;
  background-position: 0px 0px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/arrow_grid_up.png');
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-up.ug-skin-default.ug-button-hover {
  background-position: 0px -15px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-up.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-up.ug-skin-default.ug-button-disabled {
  background-position: 0px -30px;
}
/*--- left arrow ---*/
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left.ug-skin-default {
  width: 40px;
  height: 40px;
  background-position: 0px -40px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/grid_arrow_left.png');
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left.ug-skin-default.ug-button-hover {
  background-position: 0px 0px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left.ug-skin-default.ug-button-disabled {
  background-position: 0px -80px;
}
/*--- right arrow ---*/
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right.ug-skin-default {
  width: 40px;
  height: 40px;
  background-position: 0px -40px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/grid_arrow_right.png');
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right.ug-skin-default.ug-button-hover {
  background-position: 0px 0px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right.ug-skin-default.ug-button-disabled {
  background-position: 0px -80px;
}
/*--- left arrow - horizontal panel type ---*/
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left-hortype.ug-skin-default {
  width: 15px;
  height: 30px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/slider_arrow_left.png');
  background-position: 0px 0px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left-hortype.ug-skin-default.ug-button-hover {
  background-position: 0px -30px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left-hortype.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left-hortype.ug-skin-default.ug-button-disabled {
  background-position: 0px -60px;
}
/*--- right arrow - horizontal panel type ---*/
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right-hortype.ug-skin-default {
  width: 15px;
  height: 30px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/slider_arrow_right.png');
  background-position: 0px 0px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right-hortype.ug-skin-default.ug-button-hover {
  background-position: 0px -30px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right-hortype.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right-hortype.ug-skin-default.ug-button-disabled {
  background-position: 0px -60px;
}
/* --- Strip Panel Arrows -- */
/* left and right arrows */
.ug-strip-panel .ug-strip-arrow-left.ug-skin-default,
.ug-strip-panel .ug-strip-arrow-right.ug-skin-default {
  width: 25px;
  height: 50px;
}
.ug-strip-panel .ug-strip-arrow-left.ug-skin-default .ug-strip-arrow-tip,
.ug-strip-panel .ug-strip-arrow-right.ug-skin-default .ug-strip-arrow-tip {
  background-position: 0px 0px;
  background-repeat: no-repeat;
  width: 4px;
  height: 7px;
}
.ug-strip-panel .ug-strip-arrow-left.ug-skin-default .ug-strip-arrow-tip {
  background-image: url('/bower_components/unitegallery/dist/skins/default/arrows_strip_left.png');
}
.ug-strip-panel .ug-strip-arrow-right.ug-skin-default .ug-strip-arrow-tip {
  background-image: url('/bower_components/unitegallery/dist/skins/default/arrows_strip_right.png');
}
.ug-strip-panel .ug-strip-arrow-left.ug-skin-default.ug-button-hover .ug-strip-arrow-tip,
.ug-strip-panel .ug-strip-arrow-right.ug-skin-default.ug-button-hover .ug-strip-arrow-tip {
  background-position: 0px -7px;
}
/* up and down arrows */
.ug-strip-panel .ug-strip-arrow-up.ug-skin-default,
.ug-strip-panel .ug-strip-arrow-down.ug-skin-default {
  height: 25px;
  width: 50px;
}
.ug-strip-panel .ug-strip-arrow-up.ug-skin-default .ug-strip-arrow-tip,
.ug-strip-panel .ug-strip-arrow-down.ug-skin-default .ug-strip-arrow-tip {
  background-position: 0px 0px;
  background-repeat: no-repeat;
  width: 7px;
  height: 4px;
}
.ug-strip-panel .ug-strip-arrow-up.ug-skin-default .ug-strip-arrow-tip {
  background-image: url('/bower_components/unitegallery/dist/skins/default/arrows_strip_up.png');
}
.ug-strip-panel .ug-strip-arrow-down.ug-skin-default .ug-strip-arrow-tip {
  background-image: url('/bower_components/unitegallery/dist/skins/default/arrows_strip_down.png');
}
.ug-strip-panel .ug-strip-arrow-up.ug-skin-default.ug-button-hover .ug-strip-arrow-tip,
.ug-strip-panel .ug-strip-arrow-down.ug-skin-default.ug-button-hover .ug-strip-arrow-tip {
  background-position: -7px 0px;
}
.ug-strip-panel .ug-strip-arrow.ug-skin-default.ug-button-disabled .ug-strip-arrow-tip {
  background-position: 0px 0px;
  opacity: 0.5;
  filter: alpha(opacity = 50);
}
/* --- Panel Handle Tip -- */
/* left and right handle */
.ug-gallery-wrapper .ug-handle-tip-left.ug-skin-default,
.ug-gallery-wrapper .ug-handle-tip-right.ug-skin-default {
  width: 22px;
  height: 36px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/grid_handle_black_left.png');
  background-repeat: no-repeat;
  background-position: 0px -36px;
}
.ug-gallery-wrapper .ug-handle-tip-right.ug-skin-default {
  background-image: url('/bower_components/unitegallery/dist/skins/default/grid_handle_black_right.png');
  background-position: 0px -36px;
}
.ug-gallery-wrapper .ug-handle-tip-left.ug-button-hover.ug-skin-default,
.ug-gallery-wrapper .ug-handle-tip-right.ug-button-hover.ug-skin-default {
  background-position: 0px 0px;
}
.ug-gallery-wrapper .ug-handle-tip-left.ug-skin-default.ug-button-closed,
.ug-gallery-wrapper .ug-handle-tip-right.ug-skin-default.ug-button-closed {
  background-position: 0px -108px;
}
.ug-gallery-wrapper .ug-handle-tip-left.ug-skin-default.ug-button-closed.ug-button-hover,
.ug-gallery-wrapper .ug-handle-tip-right.ug-skin-default.ug-button-closed.ug-button-hover {
  background-position: 0px -72px;
}
/* top and bottom handle */
.ug-gallery-wrapper .ug-handle-tip-top.ug-skin-default,
.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default {
  width: 36px;
  height: 22px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/grid_handle_black_top.png');
  background-repeat: no-repeat;
  background-position: -36px 0px;
}
.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default {
  background-image: url('/bower_components/unitegallery/dist/skins/default/grid_handle_black_bottom.png');
  background-position: -36px 0px;
}
.ug-gallery-wrapper .ug-handle-tip-top.ug-skin-default.ug-button-hover,
.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default.ug-button-hover {
  background-position: 0px 0px;
}
.ug-gallery-wrapper .ug-handle-tip-top.ug-skin-default.ug-button-closed,
.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default.ug-button-closed {
  background-position: -108px 0px;
}
.ug-gallery-wrapper .ug-handle-tip-top.ug-skin-default.ug-button-closed.ug-button-hover,
.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default.ug-button-closed.ug-button-hover {
  background-position: -72px 0px;
}
.ug-gallery-wrapper .ug-theme-panel {
  position: absolute;
  xbackground-color: red;
  z-index: 11;
}
.ug-theme-panel .ug-strip-panel {
  z-index: 6;
}
/* --- fullscreen button -- */
.ug-theme-default .ug-default-button-fullscreen {
  position: absolute;
  background-image: url('/bower_components/unitegallery/dist//themes/default/images/button_fullscreen.png');
  width: 53px;
  height: 50px;
  cursor: pointer;
  background-position: 0px 0px;
  z-index: 3;
}
.ug-theme-default .ug-default-button-fullscreen.ug-button-hover {
  background-position: 0px -50px;
}
.ug-theme-default .ug-default-button-fullscreen.ug-fullscreenmode {
  background-position: 0px -100px;
}
.ug-theme-default .ug-default-button-fullscreen.ug-fullscreenmode.ug-button-hover {
  background-position: 0px -150px;
}
/* --- fullscreen button single mode-- */
.ug-theme-default .ug-default-button-fullscreen-single {
  position: absolute;
  background-image: url('/bower_components/unitegallery/dist//themes/default/images/button_fullscreen_single.png');
  width: 52px;
  height: 50px;
  cursor: pointer;
  background-position: 0px 0px;
  z-index: 3;
}
.ug-theme-default .ug-default-button-fullscreen-single.ug-button-hover {
  background-position: 0px -50px;
}
.ug-theme-default .ug-default-button-fullscreen-single.ug-fullscreenmode {
  background-position: 0px -100px;
}
.ug-theme-default .ug-default-button-fullscreen-single.ug-fullscreenmode.ug-button-hover {
  background-position: 0px -150px;
}
/* --- play button -- */
.ug-theme-default .ug-default-button-play {
  position: absolute;
  background-image: url('/bower_components/unitegallery/dist//themes/default/images/button_playpause.png');
  width: 51px;
  height: 50px;
  cursor: pointer;
  background-position: 0px 0px;
  z-index: 4;
}
.ug-theme-default .ug-default-button-play.ug-button-hover {
  background-position: 0px -50px;
}
.ug-theme-default .ug-default-button-play.ug-stop-mode {
  background-position: 0px -100px;
}
.ug-theme-default .ug-default-button-play.ug-stop-mode.ug-button-hover {
  background-position: 0px -150px;
}
/* --- play button single mode-- */
.ug-theme-default .ug-default-button-play-single {
  position: absolute;
  background-image: url('/bower_components/unitegallery/dist//themes/default/images/button_playpause_single.png');
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-position: 0px 0px;
  z-index: 4;
}
.ug-theme-default .ug-default-button-play-single.ug-button-hover {
  background-position: 0px -50px;
}
.ug-theme-default .ug-default-button-play-single.ug-stop-mode {
  background-position: 0px -100px;
}
.ug-theme-default .ug-default-button-play-single.ug-stop-mode.ug-button-hover {
  background-position: 0px -150px;
}
/* --- hide panel button -- */
.ug-default-button-hidepanel {
  position: absolute;
  width: 23px;
  height: 23px;
  z-index: 5;
  cursor: pointer;
}
.ug-default-button-hidepanel .ug-default-button-hidepanel-bg {
  background-color: #000000;
  width: 23px;
  height: 23px;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.4;
  filter: alpha(opacity = 40);
  z-index: 1;
}
.ug-default-button-hidepanel .ug-default-button-hidepanel-tip {
  position: absolute;
  width: 7px;
  height: 7px;
  background-repeat: no-repeat;
  background-image: url('/bower_components/unitegallery/dist//themes/default/images/arrow_down_up.png');
  background-position: 0px 0px;
  z-index: 2;
}
.ug-default-button-hidepanel.ug-button-hover .ug-default-button-hidepanel-tip {
  background-position: 0px -7px;
}
.ug-default-button-hidepanel.ug-button-hidden-mode .ug-default-button-hidepanel-tip {
  background-position: 0px -14px;
}
.ug-default-button-hidepanel.ug-button-hidden-mode.ug-button-hover .ug-default-button-hidepanel-tip {
  background-position: 0px -21px;
}
/*!
 * Datetimepicker for Bootstrap 3
 * version : 4.17.42
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.bootstrap-datetimepicker-widget {
  list-style: none;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  margin: 2px 0;
  padding: 4px;
  width: 19em;
}
@media (min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 992px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 1200px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
.bootstrap-datetimepicker-widget.dropdown-menu:before,
.bootstrap-datetimepicker-widget.dropdown-menu:after {
  content: '';
  display: inline-block;
  position: absolute;
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  top: -7px;
  left: 7px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  top: -6px;
  left: 8px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  bottom: -7px;
  left: 6px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  bottom: -6px;
  left: 7px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px;
}
.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0;
}
.bootstrap-datetimepicker-widget a[data-action] {
  padding: 6px 0;
}
.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none;
}
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 54px;
  font-weight: bold;
  font-size: 1.2em;
  margin: 0;
}
.bootstrap-datetimepicker-widget button[data-action] {
  padding: 6px;
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle AM/PM";
}
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Clear the picker";
}
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Set the date to today";
}
.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
}
.bootstrap-datetimepicker-widget .picker-switch::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle Date and Time Screens";
}
.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit;
}
.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
}
.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0;
}
.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table th {
  text-align: center;
  border-radius: 4px;
}
.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px;
}
.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px;
}
.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table th.prev::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Previous Month";
}
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Next Month";
}
.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background: #eeeeee;
}
.bootstrap-datetimepicker-widget table td {
  height: 54px;
  line-height: 54px;
  width: 54px;
}
.bootstrap-datetimepicker-widget table td.cw {
  font-size: .8em;
  height: 20px;
  line-height: 20px;
  color: #777777;
}
.bootstrap-datetimepicker-widget table td.day {
  height: 20px;
  line-height: 20px;
  width: 20px;
}
.bootstrap-datetimepicker-widget table td.day:hover,
.bootstrap-datetimepicker-widget table td.hour:hover,
.bootstrap-datetimepicker-widget table td.minute:hover,
.bootstrap-datetimepicker-widget table td.second:hover {
  background: #eeeeee;
  cursor: pointer;
}
.bootstrap-datetimepicker-widget table td.old,
.bootstrap-datetimepicker-widget table td.new {
  color: #777777;
}
.bootstrap-datetimepicker-widget table td.today {
  position: relative;
}
.bootstrap-datetimepicker-widget table td.today:before {
  content: '';
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #337ab7;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #337ab7;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.bootstrap-datetimepicker-widget table td.active.today:before {
  border-bottom-color: #fff;
}
.bootstrap-datetimepicker-widget table td.disabled,
.bootstrap-datetimepicker-widget table td.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 54px;
  height: 54px;
  line-height: 54px;
  margin: 2px 1.5px;
  cursor: pointer;
  border-radius: 4px;
}
.bootstrap-datetimepicker-widget table td span:hover {
  background: #eeeeee;
}
.bootstrap-datetimepicker-widget table td span.active {
  background-color: #337ab7;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.bootstrap-datetimepicker-widget table td span.old {
  color: #777777;
}
.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px;
}
.bootstrap-datetimepicker-widget.wider {
  width: 21em;
}
.bootstrap-datetimepicker-widget .datepicker-decades .decade {
  line-height: 1.8em !important;
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.bg.c012 {
  background-color: #f8f8f8;
}
.uploader-wrap {
  margin-top: 10px;
}
.uploader-wrap .form-error-message:hover {
  cursor: pointer;
}
.uploader-wrap .delete-icon-style {
  margin: 0px 0 -4px 4px;
}
.uploader-wrap .drop-zone {
  width: 310px;
  height: 80px;
  border: 2px solid #e2e5e8;
  margin: 10px 0 10px 0px;
  line-height: 80px;
  color: #c5c8cb;
  text-align: center;
}
.uploader-wrap .drop-zone .drop-zone-icon {
  margin: 0 10px -10px 0;
}
.uploader-wrap .delete,
.uploader-wrap .delete-default {
  cursor: pointer;
}
.uploader-wrap table.files {
  width: 100%;
}
.uploader-wrap table.files .name {
  font-weight: bold;
  font-size: 12px;
}
.uploader-wrap table.files td {
  border: none;
}
.uploader-wrap table.files tr:first-child td {
  padding-top: 12px;
}
.uploader-wrap table.files tr:last-child td {
  padding-bottom: 8px;
}
.uploader-wrap .button-holder.extra-margin {
  margin-bottom: 8px;
}
.uploader-wrap .fileinput-button {
  position: relative;
  overflow: hidden;
}
.uploader-wrap .fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transform: translate(-300px, 0) scale(4);
  font-size: 23px;
  direction: ltr;
  cursor: pointer;
}
.uploader-wrap .fileupload-buttonbar .btn,
.uploader-wrap .fileupload-buttonbar .toggle {
  margin-bottom: 5px;
}
.uploader-wrap .fileupload-processing .fileupload-loading {
  display: block;
}
.following-races hr {
  margin: 6px 0 8px -30px;
}
.following-races li {
  position: relative;
  margin-top: 10px;
}
.following-races li h6 {
  width: 100%;
  position: absolute;
  top: -12px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.following-races li h6 a {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 46px);
  display: inline-block;
}
.following-races li.OB {
  list-style-image: url(../img/obicons/ob.png);
}
.following-races li.LOB {
  list-style-image: url(../img/obicons/lob.png);
}
.following-races li.MTBO {
  list-style-image: url(../img/obicons/mtbo.png);
}
.following-races li.OST {
  list-style-image: url(../img/obicons/ost.png);
}
.following-races li.TRAIL {
  list-style-image: url(../img/obicons/trail.png);
}
.following-races li.training_1 {
  list-style-image: url(../img/obicons/map.png);
}
.following-races li.training_2 {
  list-style-image: url(../img/obicons/ob.png);
}
.following-races li.training_3 {
  list-style-image: url(../img/obicons/psy.png);
}
.following-races li.training_4 {
  list-style-image: url(../img/obicons/ost.png);
}
.following-races li.date {
  list-style-image: url(../img/obicons/event.png);
}
.following-races li.check {
  list-style-image: url(../img/obicons/check.png);
  line-height: 1.2;
}
.following-races li .green {
  color: #00e400;
}
.following-races .red {
  color: red;
}
.following-races .larger {
  font-size: larger;
}
.following-events li {
  position: relative;
  margin-top: 10px;
}
.following-events li h6 {
  width: 100%;
  position: absolute;
  top: -12px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.following-events li h6 a {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 6px);
  display: inline-block;
}
.following-events li.date {
  list-style-image: url(../img/obicons/event.png);
}
.following-trainings li {
  position: relative;
  margin-top: 10px;
}
.following-trainings li h6 {
  width: 100%;
  position: absolute;
  top: -12px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.following-trainings li h6 a {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 46px);
  display: inline-block;
}
.following-trainings li.training_1 {
  list-style-image: url(../img/obicons/map.png);
}
.following-trainings li.training_2 {
  list-style-image: url(../img/obicons/ob.png);
}
.following-trainings li.training_3 {
  list-style-image: url(../img/obicons/psy.png);
}
.following-trainings li.training_4 {
  list-style-image: url(../img/obicons/ost.png);
}
.following-trainings li .green {
  color: #00e400;
}
.survey .surveyContainerMe {
  position: relative;
  top: -41.5px;
}
.survey .surveyContainerMe .filled-stars {
  color: #dd4b39;
}
.survey .surveyContainerMe .star {
  margin: 0 12px;
  position: relative;
}
.survey .surveyContainerMe .star:first-of-type {
  left: -1px;
}
.survey .surveyContainerMe .star:nth-of-type(2) {
  left: -0.5px;
}
.survey .surveyContainerMe .star:nth-of-type(3) {
  left: -1px;
}
.survey .surveyContainerMe .star:nth-of-type(4) {
  left: -1px;
}
.survey .surveyContainerMe .star:nth-of-type(5) {
  left: 0px;
}
.survey .surveyContainerMe div.caption {
  position: relative;
  top: 5px;
}
.survey .surveyContainerMe div.caption span.label {
  color: gray!important;
  background: none!important;
}
.rssfeed .item {
  margin-bottom: 14px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 5px;
  position: relative;
}
.rssfeed .item:last-of-type {
  border-bottom: none;
  padding-bottom: 0px;
}
.rssfeed .item a:hover {
  text-decoration: none;
}
.rssfeed .item h4 {
  font-size: 16px;
  color: #1B548D;
}
.rssfeed .item p {
  font-weight: normal;
}
.rssfeed .item .source {
  font-weight: bold;
  font-size: 12px;
}
.rssfeed .item .date {
  font-weight: bold;
  position: absolute;
  right: 10px;
  font-size: 12px;
}
.lastraces hr {
  margin: 6px 0 8px -30px;
}
.lastraces li {
  position: relative;
  margin-top: 10px;
}
.lastraces li h6 {
  width: 100%;
  position: absolute;
  top: -12px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.lastraces li h6 a {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 46px);
  display: inline-block;
}
.lastraces li.OB {
  list-style-image: url(../img/obicons/ob.png);
}
.lastraces li.LOB {
  list-style-image: url(../img/obicons/lob.png);
}
.lastraces li.MTBO {
  list-style-image: url(../img/obicons/mtbo.png);
}
.lastraces li.OST {
  list-style-image: url(../img/obicons/ost.png);
}
.lastraces li.TRAIL {
  list-style-image: url(../img/obicons/trail.png);
}
.lastraces li.training_1 {
  list-style-image: url(../img/obicons/map.png);
}
.lastraces li.training_2 {
  list-style-image: url(../img/obicons/ob.png);
}
.lastraces li.training_3 {
  list-style-image: url(../img/obicons/psy.png);
}
.lastraces li.training_4 {
  list-style-image: url(../img/obicons/ost.png);
}
.lastraces li.date {
  list-style-image: url(../img/obicons/event.png);
}
.lastraces li.check {
  list-style-image: url(../img/obicons/check.png);
  line-height: 1.2;
}
.lastraces li .green {
  color: #00e400;
}
.lastraces li .inforow {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 46px);
  white-space: nowrap;
  display: inline-block;
  margin: 12px 0 0;
}
.lastraces li table.members {
  width: 100%;
}
.lastraces li table.members .col-time {
  text-align: right;
}
.lastraces li table.members .col-delay {
  text-align: left;
}
.lastraces .red {
  color: red;
}
.lastraces .larger {
  font-size: larger;
}
.lastdiscussions .discussionItem li div {
  position: relative;
  max-height: 60px;
  overflow: hidden;
  mix-blend-mode: hard-light;
}
.lastdiscussions .discussionItem li div:after {
  content: '';
  position: absolute;
  top: 5px;
  height: 60px;
  width: 100%;
  background: linear-gradient(transparent, gray);
}
.lastdiscussions .discussionItem h4 {
  margin-top: -5px;
  margin-bottom: 0px;
}
.lastdiscussions .discussionItem i.commentsCount {
  top: -10px;
  position: relative;
}

.obrou-portal .messages-menu ul.dropdown-menu {
  width: 450px;
  min-height: 240px;
}
.obrou-portal .messages-menu ul.dropdown-menu .notifications {
  overflow-y: visible;
}
.obrou-portal .messages-menu ul.dropdown-menu .notifications li {
  position: relative;
}
.obrou-portal .messages-menu ul.dropdown-menu .notifications li.unread {
  background-color: #f4f4f4;
}
.obrou-portal .messages-menu ul.dropdown-menu .notifications .time {
  position: absolute;
  bottom: -1px;
  right: 4px;
  padding-right: 5px;
  color: #999;
}
.obrou-portal .messages .conversations .item.unread {
  background-color: #d2d6de;
}
.obrou-portal .messages .conversations .item.actual {
  border: 1px solid #3c8dbc;
}
.obrou-portal .messages .conversations.products-list > .item {
  padding: 10px 5px 10px 2px;
}
.obrou-portal .messages .memembersList {
  max-height: 40px;
  overflow: auto;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages {
  height: 100%;
  max-height: 1020px;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .subject h3 {
  margin-top: 6px;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .sended a.subject {
  color: #d2d6de;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .sended a.subject:hover {
  color: #72afd2;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .direct-chat-text .attachments {
  border-top: 1px solid white;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .direct-chat-msg.center {
  margin-bottom: 4px;
  font-size: smaller;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .direct-chat-msg.center .direct-chat-info {
  font-size: smaller;
  margin-bottom: 0px;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .direct-chat-msg.center .direct-chat-text {
  margin: 0;
  background: none;
  text-align: center;
  padding: 2px 10px;
  border-color: #ebecef;
}
.obrou-portal .messages .box.direct-chat .direct-chat-messages .direct-chat-msg.center .direct-chat-text:before,
.obrou-portal .messages .box.direct-chat .direct-chat-messages .direct-chat-msg.center .direct-chat-text:after {
  content: none;
}
.obrou-portal .messages .messageForm {
  margin: 1px 26px 20px;
}
.obrou-portal .messages .messageForm form table {
  width: 100%;
}
.obrou-portal .messages .messageForm form table tr td:first-of-type {
  width: 30%;
}
.obrou-portal .messages .messageForm form table tr td:nth-of-type(2) {
  width: 60px;
}
.obrou-portal .messages .messageForm form table tr td:last-of-type {
  text-align: right;
}
.obrou-portal .messages .messageForm form .form-group {
  margin-bottom: 5px;
}
.obrou-portal .messages .messageForm form .form-group label {
  margin-bottom: 0px;
}
.obrou-portal .messages .messageForm form input[type='text'],
.obrou-portal .messages .messageForm form select {
  width: 100%;
  display: inline-block;
  height: 34px;
}
.obrou-portal .messages .messageForm form select {
  width: 100%;
}
.obrou-portal .messages .messageForm form textarea {
  height: 80px;
  width: 100%;
}
.obrou-portal .messages .messageForm form .dropdown {
  display: inline-block;
}
.obrou-portal .messages .messageForm form .dropdown .dropdown-menu[role=input] {
  padding: 10px 1px 5px 20px;
}
.obrou-portal .messages .messageForm form .dropdown .dropdown-menu[role=input] input {
  margin-right: 10px;
}
.obrou-portal .messages .messageForm form .dropdown button {
  height: 35px;
}

body.obrou-portal .modal#confirm-modal modal-body {
  text-align: center;
}

body.obrou-portal table.notificationsSetting tr td,
body.obrou-portal table.notificationsSetting tr th {
  padding: 5px 10px;
}
body.obrou-portal table.notificationsSetting tr th {
  text-align: center;
}
body.obrou-portal .notifications-menu ul.dropdown-menu {
  width: 450px;
  min-height: 240px;
}
body.obrou-portal .notifications-menu ul.dropdown-menu .notifications {
  overflow-y: visible;
}
body.obrou-portal .notifications-menu ul.dropdown-menu .notifications li {
  position: relative;
}
body.obrou-portal .notifications-menu ul.dropdown-menu .notifications li.unread {
  background-color: #f4f4f4;
}
body.obrou-portal .notifications-menu ul.dropdown-menu .notifications .time {
  position: absolute;
  bottom: -1px;
  right: 4px;
  padding-right: 5px;
  color: #999;
}
body.obrou-portal .navbar-nav > .notifications-menu > .dropdown-menu > li .menu.notifications > li > a {
  padding-bottom: 16px;
  white-space: normal;
}

.obrou-portal .fc-day-grid-event .fc-content {
  white-space: normal;
}
.obrou-portal .tooltip .tooltip-inner {
  max-width: 400px;
}
.obrou-portal .user-panel > .image > img {
  width: 45px;
  height: 45px;
}
.obrou-portal .profile-user-img {
  height: 100px;
}
.obrou-portal .dataTables_paginate {
  text-align: right;
}

.racesGrid table.datagrid td:first-of-type,
.racesGrid table.datagrid td:first-of-type {
  width: 20%;
}
.racesGrid table.datagrid td:nth-of-type(3) {
  max-width: 20%;
}
.racesGrid table.datagrid .filters .dropdown-menu {
  bottom: auto;
  top: 100%;
}
.racesGrid table.datagrid .raceTypeIcon {
  width: 20px;
  height: 20px;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: 3px 5px;
  display: inline-block;
}
.racesGrid table.datagrid .raceTypeIcon.OB {
  background-image: url(../img/obicons/ob.png);
}
.racesGrid table.datagrid .raceTypeIcon.LOB {
  background-image: url(../img/obicons/lob.png);
}
.racesGrid table.datagrid .raceTypeIcon.MTBO {
  background-image: url(../img/obicons/mtbo.png);
}
.racesGrid table.datagrid .raceTypeIcon.OST {
  background-image: url(../img/obicons/ost.png);
}
.racesGrid table.datagrid .raceTypeIcon.TRAIL {
  background-image: url(../img/obicons/trail.png);
}
.raceDetail table.details {
  width: 100%;
}
.raceDetail table.details th {
  vertical-align: top;
  padding: 2px 8px 2px 4px;
}
.raceDetail table.details table.membersOnRace {
  width: 100%;
  margin-top: -3px;
}
.applicationForm input:not([type=checkbox]):not([type=radio]) {
  min-width: 60px;
}
.application .applicationTable {
  width: 100%;
}

body.obrou-portal .searchResults .widget-user-2 .widget-user-header {
  min-height: 102px;
}
body.obrou-portal .searchResults .widget-user-2 .widget-user-header .widget-user-image img {
  height: 65px;
}
body.obrou-portal .searchResults .widget-race .nav-stacked > li > .widget-row {
  border-radius: 0;
  border-top: 0;
  border-left: 3px solid transparent;
  color: #444;
  padding: 10px 15px;
  position: relative;
  display: block;
}
body.obrou-portal #searchForm {
  overflow: visible;
  z-index: 811;
}
body.obrou-portal #searchForm .easy-autocomplete-container ul {
  top: 36px;
}
body.obrou-portal #searchForm .easy-autocomplete-container ul .eac-category {
  text-align: center;
  border-top: 1px solid black;
}
body.obrou-portal #searchForm .easy-autocomplete-container ul .eac-category:first-of-type {
  border-top: none;
}
body.obrou-portal #searchForm .easy-autocomplete-container ul li {
  border-top: 1px solid lightgrey;
  font-size: small;
}

#ajax-spinner {
  margin: 15px 0 0 15px;
  padding: 13px;
  background: transparent url('../images/spinner.gif') no-repeat 50% 50%;
  font-size: 0;
  z-index: 123456;
}

body.obrou-portal .statistics #rankingTabs .graph {
  width: 100%;
}

.timeline-container {
  max-height: 2000px;
  min-height: 250px;
  overflow: hidden;
}
.timeline-container .add-post {
  margin: 1px 26px 20px;
}
.timeline-container .add-post .add-post-form .form-group {
  margin-bottom: 5px;
}
.timeline-container .add-post .add-post-form .form-group label {
  margin-bottom: 0px;
}
.timeline-container .add-post .add-post-form input[type='text'] {
  width: 250px;
  display: inline-block;
  height: 34px;
}
.timeline-container .add-post .add-post-form textarea {
  height: 80px;
  width: 100%;
}
.timeline-container .add-post .add-post-form .dropdown {
  display: inline-block;
}
.timeline-container .add-post .add-post-form .dropdown .dropdown-menu[role=input] {
  padding: 10px 1px 5px 20px;
}
.timeline-container .add-post .add-post-form .dropdown .dropdown-menu[role=input] input {
  margin-right: 10px;
}
.timeline-container .add-post .add-post-form .dropdown button {
  height: 35px;
}
.timeline-container form.edit-post-form .checkbox {
  margin-left: 20px;
}
.timeline-container .timeline .timeline-item .timeline-item-text .post_gallery a {
  opacity: 1;
}
.timeline-container .timeline .timeline-item .timeline-item-text .post_gallery a img {
  opacity: 1;
}
.timeline-container .timeline div.new-comment-container {
  margin: 5px 0 15px 0;
}
.timeline-container .timeline div.new-comment-container img.img-sm {
  margin-top: 3px;
  width: 35px !important;
  height: 35px !important;
}
.timeline-container .timeline div.new-comment-container textarea {
  resize: none;
  height: 40px;
}
.timeline-container .timeline div.new-comment-container .btn {
  height: 40px;
}
.timeline-container .timeline div.new-comment-container .btn .valigner {
  height: 100%;
  vertical-align: middle;
  width: 0px;
  display: inline-block;
}
.timeline-container .timeline div.new-comment-container .btn i {
  vertical-align: middle;
}
.timeline-container .timeline div.timeline-item-text img {
  max-width: 100%;
}
.timeline-container .timeline div.timeline-item-text .last-discussion-comments li div {
  position: relative;
  max-height: 100px;
  overflow: hidden;
  mix-blend-mode: hard-light;
}
.timeline-container .timeline div.timeline-item-text .last-discussion-comments li div:after {
  content: '';
  position: absolute;
  top: 30px;
  height: 75px;
  width: 100%;
  background: linear-gradient(transparent, gray);
}
.timeline-container .timeline div.timeline-item-text .categories-results {
  width: 100%;
  max-width: 790px;
}
.timeline-container .timeline div.timeline-item-text .categories-results .members-in-category {
  width: 100%;
}
.timeline-container .timeline div.timeline-item-text .categories-results .members-in-category td:nth-child(1),
.timeline-container .timeline div.timeline-item-text .categories-results .members-in-category th:nth-child(1) {
  width: 45px;
  text-align: right;
}
.timeline-container .timeline div.timeline-item-text .categories-results .members-in-category td:nth-child(3),
.timeline-container .timeline div.timeline-item-text .categories-results .members-in-category th:nth-child(3) {
  width: 100px;
  text-align: right;
}
.timeline-container .timeline div.timeline-item-text .categories-results .members-in-category td:nth-child(4),
.timeline-container .timeline div.timeline-item-text .categories-results .members-in-category th:nth-child(4) {
  width: 100px;
  text-align: right;
}
.timeline-container .timeline div.timeline-item-text .categories-results > tbody > tr {
  border-top: 1px solid lightgrey;
}
.timeline-container .timeline div.timeline-item-text .categories-results td,
.timeline-container .timeline div.timeline-item-text .categories-results th {
  vertical-align: top;
  text-align: left;
}
.timeline-container .timeline .timeline-item > .public-post {
  color: #999;
  float: right;
  padding: 12px;
  font-size: 12px;
}
.timeline-container .timeline div.box-comments {
  margin-top: 20px;
  padding: 10px;
  max-height: 320px;
  border-top: 1px solid #eee;
  padding-top: 5px;
}
.timeline-container .timeline div.box-comments .slim-scroll-wrap {
  max-height: 255px;
}
.timeline-container .timeline div.box-comments .slim-scroll-wrap div.comment-header {
  height: 30px;
}
.timeline-container .timeline div.box-comments .slim-scroll-wrap div.comment-header span.username {
  padding: 4px;
  position: relative;
  left: 10px;
}
.timeline-container .timeline div.box-comments .slim-scroll-wrap div.comment-body {
  padding-bottom: 4px;
}
.timeline-container .timeline div.box-comments .slim-scroll-wrap div.comment-body .text-muted {
  margin-right: 10px;
}
.timeline-container .timeline div.box-comments .list-inline {
  position: relative;
  top: -18px;
}

.obrou-portal .tooltip .tooltip-inner .tooltip-race,
.obrou-portal .tooltipster-content .tooltip-race,
.obrou-portal .tooltip .tooltip-inner .tooltip-training,
.obrou-portal .tooltipster-content .tooltip-training,
.obrou-portal .tooltip .tooltip-inner .tooltip-event,
.obrou-portal .tooltipster-content .tooltip-event {
  text-align: left;
}
.obrou-portal .tooltip .tooltip-inner .tooltip-race table td,
.obrou-portal .tooltipster-content .tooltip-race table td,
.obrou-portal .tooltip .tooltip-inner .tooltip-training table td,
.obrou-portal .tooltipster-content .tooltip-training table td,
.obrou-portal .tooltip .tooltip-inner .tooltip-event table td,
.obrou-portal .tooltipster-content .tooltip-event table td {
  vertical-align: top;
}

.trainingsGrid table.datagrid td:first-of-type,
.trainingsGrid table.datagrid td:first-of-type {
  width: 20%;
}
.trainingsGrid table.datagrid td:nth-of-type(3) {
  max-width: 20%;
}
.trainingsGrid table.datagrid .filters .dropdown-menu {
  bottom: auto;
  top: 100%;
}
.trainingsGrid table.datagrid .raceTypeIcon {
  width: 20px;
  height: 20px;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: 3px 5px;
  display: inline-block;
}
.trainingsGrid table.datagrid .raceTypeIcon.OB {
  background-image: url(../img/obicons/ob.png);
}
.trainingsGrid table.datagrid .raceTypeIcon.LOB {
  background-image: url(../img/obicons/lob.png);
}
.trainingsGrid table.datagrid .raceTypeIcon.MTBO {
  background-image: url(../img/obicons/mtbo.png);
}
.trainingsGrid table.datagrid .raceTypeIcon.OST {
  background-image: url(../img/obicons/ost.png);
}
.trainingsGrid table.datagrid .raceTypeIcon.TRAIL {
  background-image: url(../img/obicons/trail.png);
}
.trainingDetail table.details {
  width: 100%;
}
.trainingDetail table.details th {
  vertical-align: top;
  padding: 2px 8px 2px 4px;
}
.trainingDetail table.details table.membersOnRace {
  width: 100%;
  margin-top: -3px;
}
.trainingDetail table.details table.membersOnRace td:first-of-type {
  white-space: nowrap;
  width: 1%;
}
.trainingDetail table.details table.membersOnRace td:nth-of-type(2) {
  white-space: nowrap;
  width: 1%;
}
.application .applicationTable {
  width: 100%;
}

/* @activeColor: #c0392b; //red */
.bodineChecks {
  /* $activeColor: #c0392b; //red */
  /* $background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/13460/dark_wall.png'); */
  /* .slideOne */
  /* end .slideOne */
  /* .slideTwo */
  /* end .slideTwo */
  /* .slideThree */
  /* end .slideThree */
  /* .roundedOne */
  /* end .roundedOne */
  /* .roundedTwo */
  /* end .roundedTwo */
  /* .squaredOne */
  /* end .squaredOne */
  /* .squaredTwo */
  /* end .squaredTwo */
  /* .squaredThree */
  /* end .squaredThree */
  /* .squaredFour */
  /* end .squaredFour */
}
.bodineChecks .slideOne {
  width: 50px;
  height: 10px;
  background: #333;
  margin: 20px auto;
  position: relative;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
}
.bodineChecks .slideOne label {
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: -3px;
  left: -3px;
  cursor: pointer;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border-radius: 50px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.bodineChecks .slideOne input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .slideOne input[type=checkbox]:checked + label {
  left: 37px;
}
.bodineChecks .slideTwo {
  width: 80px;
  height: 30px;
  background: #333;
  margin: 20px auto;
  position: relative;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
}
.bodineChecks .slideTwo:after {
  content: '';
  position: absolute;
  top: 14px;
  left: 14px;
  height: 2px;
  width: 52px;
  background: #111;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
}
.bodineChecks .slideTwo label {
  display: block;
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  top: 4px;
  z-index: 1;
  left: 4px;
  background: #fcfff4;
  border-radius: 50px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
}
.bodineChecks .slideTwo label:after {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 6px;
  left: 6px;
  background: #333;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px black, 0px 1px 0px rgba(255, 255, 255, 0.9);
}
.bodineChecks .slideTwo input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .slideTwo input[type=checkbox]:checked + label {
  left: 54px;
}
.bodineChecks .slideTwo input[type=checkbox]:checked + label:after {
  background: #27ae60;
  /*activeColor*/
}
.bodineChecks .slideThree {
  width: 80px;
  height: 26px;
  background: #333;
  margin: 20px auto;
  position: relative;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
}
.bodineChecks .slideThree:after {
  content: 'OFF';
  color: #000;
  position: absolute;
  right: 10px;
  z-index: 0;
  font: 12px/26px Arial, sans-serif;
  font-weight: bold;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.15);
}
.bodineChecks .slideThree:before {
  content: 'ON';
  color: #27ae60;
  position: absolute;
  left: 10px;
  z-index: 0;
  font: 12px/26px Arial, sans-serif;
  font-weight: bold;
}
.bodineChecks .slideThree label {
  display: block;
  width: 34px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border-radius: 50px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
}
.bodineChecks .slideThree input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .slideThree input[type=checkbox]:checked + label {
  left: 43px;
}
.bodineChecks .roundedOne {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 20px auto;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.bodineChecks .roundedOne label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  left: 4px;
  top: 4px;
  background: -webkit-linear-gradient(top, #222222 0%, #45484d 100%);
  background: linear-gradient(to bottom, #222222 0%, #45484d 100%);
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
}
.bodineChecks .roundedOne label:after {
  content: '';
  width: 16px;
  height: 16px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: #27ae60;
  background: -webkit-linear-gradient(top, #27ae60 0%, #145b32 100%);
  background: linear-gradient(to bottom, #27ae60 0%, #145b32 100%);
  opacity: 0;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.bodineChecks .roundedOne label:hover::after {
  opacity: 0.3;
}
.bodineChecks .roundedOne input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .roundedOne input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.bodineChecks .roundedTwo {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 20px auto;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.bodineChecks .roundedTwo label {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  left: 4px;
  cursor: pointer;
  background: -webkit-linear-gradient(top, #222222 0%, #3c8dbc 100%);
  background: linear-gradient(to bottom, #222222 0%, #3c8dbc 100%);
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
}
.bodineChecks .roundedTwo label:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  top: 6px;
  left: 5px;
  border: 3px solid #fcfff4;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.bodineChecks .roundedTwo label:hover::after {
  opacity: 0.3;
}
.bodineChecks .roundedTwo input[type=checkbox],
.bodineChecks .roundedTwo input[type=radio] {
  visibility: hidden;
}
.bodineChecks .roundedTwo input[type=checkbox]:checked + label:after,
.bodineChecks .roundedTwo input[type=radio]:checked + label:after {
  opacity: 1;
}
.bodineChecks .squaredOne {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 20px auto;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.bodineChecks .squaredOne label {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  left: 4px;
  cursor: pointer;
  background: -webkit-linear-gradient(top, #222222 0%, #45484d 100%);
  background: linear-gradient(to bottom, #222222 0%, #45484d 100%);
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
}
.bodineChecks .squaredOne label:after {
  content: '';
  width: 16px;
  height: 16px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: #27ae60;
  background: -webkit-linear-gradient(top, #27ae60 0%, #145b32 100%);
  background: linear-gradient(to bottom, #27ae60 0%, #145b32 100%);
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
  opacity: 0;
}
.bodineChecks .squaredOne label:hover::after {
  opacity: 0.3;
}
.bodineChecks .squaredOne input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .squaredOne input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.bodineChecks .squaredTwo {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 20px auto;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.bodineChecks .squaredTwo label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  left: 4px;
  top: 4px;
  background: -webkit-linear-gradient(top, #222222 0%, #45484d 100%);
  background: linear-gradient(to bottom, #222222 0%, #45484d 100%);
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
}
.bodineChecks .squaredTwo label:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 3px solid #fcfff4;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.bodineChecks .squaredTwo label:hover::after {
  opacity: 0.3;
}
.bodineChecks .squaredTwo input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .squaredTwo input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.bodineChecks .squaredThree {
  width: 20px;
  position: relative;
  margin: 20px auto;
}
.bodineChecks .squaredThree label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background: -webkit-linear-gradient(top, #222222 0%, #45484d 100%);
  background: linear-gradient(to bottom, #222222 0%, #45484d 100%);
  border-radius: 4px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.4);
}
.bodineChecks .squaredThree label:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 3px solid #fcfff4;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.bodineChecks .squaredThree label:hover::after {
  opacity: 0.3;
}
.bodineChecks .squaredThree input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .squaredThree input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.bodineChecks .squaredFour {
  width: 20px;
  position: relative;
  margin: 20px auto;
}
.bodineChecks .squaredFour label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background: #fcfff4;
  background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border-radius: 4px;
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.bodineChecks .squaredFour label:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 3px solid #333;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.bodineChecks .squaredFour label:hover::after {
  opacity: 0.5;
}
.bodineChecks .squaredFour input[type=checkbox] {
  visibility: hidden;
}
.bodineChecks .squaredFour input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.bodineChecks * {
  box-sizing: border-box;
}
.bodineChecks body {
  background: #3498db;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}
.bodineChecks body h1,
.bodineChecks body h2,
.bodineChecks body em {
  color: #eee;
  font-size: 30px;
  text-align: center;
  margin: 20px 0 0 0;
  -webkit-font-smoothing: antialiased;
  text-shadow: 0px 1px #000;
}
.bodineChecks body em {
  font-size: 14px;
  text-align: center;
  display: block;
  margin-bottom: 50px;
}
.bodineChecks body .ondisplay {
  text-align: center;
  padding: 20px 0;
}
.bodineChecks body .ondisplay section {
  width: 100px;
  height: 100px;
  background: #555;
  display: inline-block;
  position: relative;
  text-align: center;
  margin-top: 5px;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.bodineChecks body .ondisplay section:after {
  /*         visibility: hidden; */
  content: attr(title);
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-shadow: 0px 1px #000;
}
.inlineCheckboxes .inlineCheckbox {
  display: inline-block;
  position: relative;
  text-align: center;
  min-width: 60px;
}
.inlineCheckboxes .inlineCheckbox section {
  display: block;
}
.inlineCheckboxes .inlineCheckbox span.label {
  position: relative;
  color: black;
  top: -10px;
}

/*!
 * Datetimepicker for Bootstrap 3
 * version : 4.17.42
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.bootstrap-datetimepicker-widget {
  list-style: none;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  margin: 2px 0;
  padding: 4px;
  width: 19em;
}
@media (min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 992px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 1200px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
.bootstrap-datetimepicker-widget.dropdown-menu:before,
.bootstrap-datetimepicker-widget.dropdown-menu:after {
  content: '';
  display: inline-block;
  position: absolute;
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  top: -7px;
  left: 7px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  top: -6px;
  left: 8px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  bottom: -7px;
  left: 6px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  bottom: -6px;
  left: 7px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px;
}
.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0;
}
.bootstrap-datetimepicker-widget a[data-action] {
  padding: 6px 0;
}
.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none;
}
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 54px;
  font-weight: bold;
  font-size: 1.2em;
  margin: 0;
}
.bootstrap-datetimepicker-widget button[data-action] {
  padding: 6px;
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle AM/PM";
}
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Clear the picker";
}
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Set the date to today";
}
.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
}
.bootstrap-datetimepicker-widget .picker-switch::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle Date and Time Screens";
}
.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit;
}
.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
}
.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0;
}
.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table th {
  text-align: center;
  border-radius: 4px;
}
.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px;
}
.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px;
}
.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table th.prev::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Previous Month";
}
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Next Month";
}
.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background: #eeeeee;
}
.bootstrap-datetimepicker-widget table td {
  height: 54px;
  line-height: 54px;
  width: 54px;
}
.bootstrap-datetimepicker-widget table td.cw {
  font-size: .8em;
  height: 20px;
  line-height: 20px;
  color: #777777;
}
.bootstrap-datetimepicker-widget table td.day {
  height: 20px;
  line-height: 20px;
  width: 20px;
}
.bootstrap-datetimepicker-widget table td.day:hover,
.bootstrap-datetimepicker-widget table td.hour:hover,
.bootstrap-datetimepicker-widget table td.minute:hover,
.bootstrap-datetimepicker-widget table td.second:hover {
  background: #eeeeee;
  cursor: pointer;
}
.bootstrap-datetimepicker-widget table td.old,
.bootstrap-datetimepicker-widget table td.new {
  color: #777777;
}
.bootstrap-datetimepicker-widget table td.today {
  position: relative;
}
.bootstrap-datetimepicker-widget table td.today:before {
  content: '';
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #337ab7;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #337ab7;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.bootstrap-datetimepicker-widget table td.active.today:before {
  border-bottom-color: #fff;
}
.bootstrap-datetimepicker-widget table td.disabled,
.bootstrap-datetimepicker-widget table td.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 54px;
  height: 54px;
  line-height: 54px;
  margin: 2px 1.5px;
  cursor: pointer;
  border-radius: 4px;
}
.bootstrap-datetimepicker-widget table td span:hover {
  background: #eeeeee;
}
.bootstrap-datetimepicker-widget table td span.active {
  background-color: #337ab7;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.bootstrap-datetimepicker-widget table td span.old {
  color: #777777;
}
.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px;
}
.bootstrap-datetimepicker-widget.wider {
  width: 21em;
}
.bootstrap-datetimepicker-widget .datepicker-decades .decade {
  line-height: 1.8em !important;
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}



/*!
 * @copyright &copy; Kartik Visweswaran, Krajee.com, 2013 - 2016
 * @version 4.0.1
 *
 * A simple yet powerful JQuery star rating plugin that allows rendering fractional star ratings and supports
 * Right to Left (RTL) input.
 *
 * For more JQuery/Bootstrap plugins and demos visit http://plugins.krajee.com
 * For more Yii related demos visit http://demos.krajee.com
 */
.rating-loading {
  width: 25px;
  height: 25px;
  font-size: 0;
  color: #fff;
  background: transparent url('../img/loading.gif') top left no-repeat;
  border: none;
}
/*
 * Stars
 */
.rating-container .rating {
  cursor: default;
  position: relative;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
.rating-disabled .rating {
  cursor: not-allowed;
}
.rating-container .star {
  display: inline-block;
  margin: 0 3px;
  text-align: center;
}
.rating-container .empty-stars {
  color: #aaa;
}
.rating-container .filled-stars {
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  color: #fde16d;
  white-space: nowrap;
  overflow: hidden;
  -webkit-text-stroke: 1px #777;
  text-shadow: 1px 1px #999;
}
.rating-rtl {
  float: right;
}
.rating-animate .filled-stars {
  transition: width 0.25s ease;
  -o-transition: width 0.25s ease;
  -moz-transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
}
.rating-rtl .filled-stars {
  left: auto;
  right: 0;
  -moz-transform: matrix(-1, 0, 0, 1, 0, 0) translate3d(0, 0, 0);
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0) translate3d(0, 0, 0);
  -o-transform: matrix(-1, 0, 0, 1, 0, 0) translate3d(0, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0) translate3d(0, 0, 0);
}
.rating-rtl.is-star .filled-stars {
  right: 0.06em;
}
.rating-rtl.is-heart .empty-stars {
  margin-right: 0.07em;
}
/**
 * Sizes
 */
.rating-xl {
  font-size: 4.89em;
}
.rating-lg {
  font-size: 3.91em;
}
.rating-md {
  font-size: 3.13em;
}
.rating-sm {
  font-size: 2.5em;
}
.rating-xs {
  font-size: 2em;
}
.rating-xxs {
  font-size: 1.2em;
}
.rating-xl {
  font-size: 4.89em;
}
/**
 * Clear
 */
.rating-container .clear-rating {
  color: #aaa;
  cursor: not-allowed;
  display: inline-block;
  vertical-align: middle;
  font-size: 60%;
}
.clear-rating-active {
  cursor: pointer !important;
}
.clear-rating-active:hover {
  color: #843534;
}
.rating-container .clear-rating {
  padding-right: 5px;
}
/**
 * Caption
 */
.rating-container .caption {
  color: #999;
  display: inline-block;
  vertical-align: middle;
  font-size: 60%;
  margin-top: -0.6em;
}
.rating-container .caption {
  margin-left: 5px;
  margin-right: 0;
}
.rating-rtl .caption {
  margin-right: 5px;
  margin-left: 0;
}
/**
 * Print
 */
@media print {
  .rating-container .clear-rating {
    display: none;
  }
}

/*

    Zebra_DatePicker: a lightweight jQuery date picker plugin

    Default theme

    copyright (c) 2011 - 2014 Stefan Gabos
    http://stefangabos.ro/jquery/zebra-datepicker/

*/
.Zebra_DatePicker *,
.Zebra_DatePicker *:after,
.Zebra_DatePicker *:before {
  -moz-box-sizing: content-box !important;
  -webkit-box-sizing: content-box !important;
  box-sizing: content-box !important;
}
.Zebra_DatePicker {
  position: absolute;
  background: #666;
  border: 3px solid #666;
  z-index: 1200;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  font-size: 13px;
  top: 0;
}
.Zebra_DatePicker * {
  margin: 0;
  padding: 0;
  color: #000;
  background: transparent;
  border: none;
}
/* = GLOBALS
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker table {
  border-collapse: collapse;
  border-spacing: 0;
  width: auto;
  table-layout: auto;
}
.Zebra_DatePicker td,
.Zebra_DatePicker th {
  text-align: center;
  padding: 5px 0;
}
.Zebra_DatePicker td {
  cursor: pointer;
}
.Zebra_DatePicker .dp_daypicker,
.Zebra_DatePicker .dp_monthpicker,
.Zebra_DatePicker .dp_yearpicker {
  margin-top: 3px;
}
.Zebra_DatePicker .dp_daypicker td,
.Zebra_DatePicker .dp_daypicker th,
.Zebra_DatePicker .dp_monthpicker td,
.Zebra_DatePicker .dp_yearpicker td {
  background: #E8E8E8;
  width: 30px;
  border: 1px solid #7BACD2;
}
.Zebra_DatePicker,
.Zebra_DatePicker .dp_header .dp_hover,
.Zebra_DatePicker .dp_footer .dp_hover {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
/* = VISIBLE/HIDDEN STATES (USE TRANSITIONS FOR EFFECTS)
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker.dp_visible {
  visibility: visible;
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
.Zebra_DatePicker.dp_hidden {
  visibility: hidden;
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}
/* = HEADER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_header td {
  color: #FFF;
}
.Zebra_DatePicker .dp_header .dp_previous,
.Zebra_DatePicker .dp_header .dp_next {
  width: 30px;
}
.Zebra_DatePicker .dp_header .dp_caption {
  font-weight: bold;
}
.Zebra_DatePicker .dp_header .dp_hover {
  background: #222;
  color: #FFF;
}
/* = DATEPICKER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_daypicker th {
  background: #FFCC33;
}
.Zebra_DatePicker td.dp_not_in_month {
  background: #F3F3F3;
  color: #CDCDCD;
  cursor: default;
}
.Zebra_DatePicker td.dp_not_in_month_selectable {
  background: #F3F3F3;
  color: #CDCDCD;
  cursor: pointer;
}
.Zebra_DatePicker td.dp_weekend {
  background: #D8D8D8;
}
.Zebra_DatePicker td.dp_weekend_disabled {
  color: #CCC;
  cursor: default;
}
.Zebra_DatePicker td.dp_selected {
  background: #5A4B4B;
  color: #FFF !important;
}
.Zebra_DatePicker td.dp_week_number {
  background: #FFCC33;
  color: #555;
  cursor: text;
  font-style: italic;
}
/* = MONTHPICKER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_monthpicker td {
  width: 33%;
}
/* = YEARPICKER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_yearpicker td {
  width: 33%;
}
/* = FOOTER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_footer {
  margin-top: 3px;
}
.Zebra_DatePicker .dp_footer .dp_hover {
  background: #222;
  color: #FFF;
}
/* = SELECT CURRENT DAY
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_today {
  color: #FFF;
  padding: 3px;
}
/* = CLEAR DATE
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_clear {
  color: #FFF;
  padding: 3px;
}
/* = SOME MORE GLOBALS (MUST BE LAST IN ORDER TO OVERWRITE PREVIOUS PROPERTIES)
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker td.dp_current {
  color: #C40000;
}
.Zebra_DatePicker td.dp_disabled_current {
  color: #E38585;
}
.Zebra_DatePicker td.dp_disabled {
  background: #F3F3F3;
  color: #CDCDCD;
  cursor: default;
}
.Zebra_DatePicker td.dp_hover {
  background: #482424;
  color: #FFF;
}
/* = ICON
----------------------------------------------------------------------------------------------------------------------*/
button.Zebra_DatePicker_Icon {
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  background: url('../img/calendar.png') no-repeat left top;
  text-indent: -9000px;
  border: none;
  cursor: pointer;
  padding: 0;
  line-height: 0;
  vertical-align: top;
}
button.Zebra_DatePicker_Icon_Disabled {
  background-image: url('../images/calendar-disabled.png');
}
/* don't set vertical margins! */
button.Zebra_DatePicker_Icon {
  margin: 0 0 0 3px;
}
button.Zebra_DatePicker_Icon_Inside_Right {
  margin: 0 3px 0 0;
}
button.Zebra_DatePicker_Icon_Inside_Left {
  margin: 0 0 0 3px;
}

.following-events li {
  position: relative;
  margin-top: 10px;
}
.following-events li h6 {
  width: 100%;
  position: absolute;
  top: -12px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.following-events li h6 a {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 6px);
  display: inline-block;
}
.following-events li.date {
  list-style-image: url(../img/obicons/event.png);
}

.following-races hr {
  margin: 6px 0 8px -30px;
}
.following-races li {
  position: relative;
  margin-top: 10px;
}
.following-races li h6 {
  width: 100%;
  position: absolute;
  top: -12px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.following-races li h6 a {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 46px);
  display: inline-block;
}
.following-races li.OB {
  list-style-image: url(../img/obicons/ob.png);
}
.following-races li.LOB {
  list-style-image: url(../img/obicons/lob.png);
}
.following-races li.MTBO {
  list-style-image: url(../img/obicons/mtbo.png);
}
.following-races li.OST {
  list-style-image: url(../img/obicons/ost.png);
}
.following-races li.TRAIL {
  list-style-image: url(../img/obicons/trail.png);
}
.following-races li.training_1 {
  list-style-image: url(../img/obicons/map.png);
}
.following-races li.training_2 {
  list-style-image: url(../img/obicons/ob.png);
}
.following-races li.training_3 {
  list-style-image: url(../img/obicons/psy.png);
}
.following-races li.training_4 {
  list-style-image: url(../img/obicons/ost.png);
}
.following-races li.date {
  list-style-image: url(../img/obicons/event.png);
}
.following-races li.check {
  list-style-image: url(../img/obicons/check.png);
  line-height: 1.2;
}
.following-races li .green {
  color: #00e400;
}
.following-races .red {
  color: red;
}
.following-races .larger {
  font-size: larger;
}

.following-trainings li {
  position: relative;
  margin-top: 10px;
}
.following-trainings li h6 {
  width: 100%;
  position: absolute;
  top: -12px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.following-trainings li h6 a {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 46px);
  display: inline-block;
}
.following-trainings li.training_1 {
  list-style-image: url(../img/obicons/map.png);
}
.following-trainings li.training_2 {
  list-style-image: url(../img/obicons/ob.png);
}
.following-trainings li.training_3 {
  list-style-image: url(../img/obicons/psy.png);
}
.following-trainings li.training_4 {
  list-style-image: url(../img/obicons/ost.png);
}
.following-trainings li .green {
  color: #00e400;
}

.lastdiscussions .discussionItem li div {
  position: relative;
  max-height: 60px;
  overflow: hidden;
  mix-blend-mode: hard-light;
}
.lastdiscussions .discussionItem li div:after {
  content: '';
  position: absolute;
  top: 5px;
  height: 60px;
  width: 100%;
  background: linear-gradient(transparent, gray);
}
.lastdiscussions .discussionItem h4 {
  margin-top: -5px;
  margin-bottom: 0px;
}
.lastdiscussions .discussionItem i.commentsCount {
  top: -10px;
  position: relative;
}

.lastraces hr {
  margin: 6px 0 8px -30px;
}
.lastraces li {
  position: relative;
  margin-top: 10px;
}
.lastraces li h6 {
  width: 100%;
  position: absolute;
  top: -12px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.lastraces li h6 a {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 46px);
  display: inline-block;
}
.lastraces li.OB {
  list-style-image: url(../img/obicons/ob.png);
}
.lastraces li.LOB {
  list-style-image: url(../img/obicons/lob.png);
}
.lastraces li.MTBO {
  list-style-image: url(../img/obicons/mtbo.png);
}
.lastraces li.OST {
  list-style-image: url(../img/obicons/ost.png);
}
.lastraces li.TRAIL {
  list-style-image: url(../img/obicons/trail.png);
}
.lastraces li.training_1 {
  list-style-image: url(../img/obicons/map.png);
}
.lastraces li.training_2 {
  list-style-image: url(../img/obicons/ob.png);
}
.lastraces li.training_3 {
  list-style-image: url(../img/obicons/psy.png);
}
.lastraces li.training_4 {
  list-style-image: url(../img/obicons/ost.png);
}
.lastraces li.date {
  list-style-image: url(../img/obicons/event.png);
}
.lastraces li.check {
  list-style-image: url(../img/obicons/check.png);
  line-height: 1.2;
}
.lastraces li .green {
  color: #00e400;
}
.lastraces li .inforow {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 46px);
  white-space: nowrap;
  display: inline-block;
  margin: 12px 0 0;
}
.lastraces li table.members {
  width: 100%;
}
.lastraces li table.members .col-time {
  text-align: right;
}
.lastraces li table.members .col-delay {
  text-align: left;
}
.lastraces .red {
  color: red;
}
.lastraces .larger {
  font-size: larger;
}

.rssfeed .item {
  margin-bottom: 14px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 5px;
  position: relative;
}
.rssfeed .item:last-of-type {
  border-bottom: none;
  padding-bottom: 0px;
}
.rssfeed .item a:hover {
  text-decoration: none;
}
.rssfeed .item h4 {
  font-size: 16px;
  color: #1B548D;
}
.rssfeed .item p {
  font-weight: normal;
}
.rssfeed .item .source {
  font-weight: bold;
  font-size: 12px;
}
.rssfeed .item .date {
  font-weight: bold;
  position: absolute;
  right: 10px;
  font-size: 12px;
}

.survey .surveyContainerMe {
  position: relative;
  top: -41.5px;
}
.survey .surveyContainerMe .filled-stars {
  color: #dd4b39;
}
.survey .surveyContainerMe .star {
  margin: 0 12px;
  position: relative;
}
.survey .surveyContainerMe .star:first-of-type {
  left: -1px;
}
.survey .surveyContainerMe .star:nth-of-type(2) {
  left: -0.5px;
}
.survey .surveyContainerMe .star:nth-of-type(3) {
  left: -1px;
}
.survey .surveyContainerMe .star:nth-of-type(4) {
  left: -1px;
}
.survey .surveyContainerMe .star:nth-of-type(5) {
  left: 0px;
}
.survey .surveyContainerMe div.caption {
  position: relative;
  top: 5px;
}
.survey .surveyContainerMe div.caption span.label {
  color: gray!important;
  background: none!important;
}

.ug-gallery-wrapper .ug-theme-panel {
  position: absolute;
  xbackground-color: red;
  z-index: 11;
}
.ug-theme-panel .ug-strip-panel {
  z-index: 6;
}
/* --- fullscreen button -- */
.ug-theme-default .ug-default-button-fullscreen {
  position: absolute;
  background-image: url('/bower_components/unitegallery/dist//themes/default/images/button_fullscreen.png');
  width: 53px;
  height: 50px;
  cursor: pointer;
  background-position: 0px 0px;
  z-index: 3;
}
.ug-theme-default .ug-default-button-fullscreen.ug-button-hover {
  background-position: 0px -50px;
}
.ug-theme-default .ug-default-button-fullscreen.ug-fullscreenmode {
  background-position: 0px -100px;
}
.ug-theme-default .ug-default-button-fullscreen.ug-fullscreenmode.ug-button-hover {
  background-position: 0px -150px;
}
/* --- fullscreen button single mode-- */
.ug-theme-default .ug-default-button-fullscreen-single {
  position: absolute;
  background-image: url('/bower_components/unitegallery/dist//themes/default/images/button_fullscreen_single.png');
  width: 52px;
  height: 50px;
  cursor: pointer;
  background-position: 0px 0px;
  z-index: 3;
}
.ug-theme-default .ug-default-button-fullscreen-single.ug-button-hover {
  background-position: 0px -50px;
}
.ug-theme-default .ug-default-button-fullscreen-single.ug-fullscreenmode {
  background-position: 0px -100px;
}
.ug-theme-default .ug-default-button-fullscreen-single.ug-fullscreenmode.ug-button-hover {
  background-position: 0px -150px;
}
/* --- play button -- */
.ug-theme-default .ug-default-button-play {
  position: absolute;
  background-image: url('/bower_components/unitegallery/dist//themes/default/images/button_playpause.png');
  width: 51px;
  height: 50px;
  cursor: pointer;
  background-position: 0px 0px;
  z-index: 4;
}
.ug-theme-default .ug-default-button-play.ug-button-hover {
  background-position: 0px -50px;
}
.ug-theme-default .ug-default-button-play.ug-stop-mode {
  background-position: 0px -100px;
}
.ug-theme-default .ug-default-button-play.ug-stop-mode.ug-button-hover {
  background-position: 0px -150px;
}
/* --- play button single mode-- */
.ug-theme-default .ug-default-button-play-single {
  position: absolute;
  background-image: url('/bower_components/unitegallery/dist//themes/default/images/button_playpause_single.png');
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-position: 0px 0px;
  z-index: 4;
}
.ug-theme-default .ug-default-button-play-single.ug-button-hover {
  background-position: 0px -50px;
}
.ug-theme-default .ug-default-button-play-single.ug-stop-mode {
  background-position: 0px -100px;
}
.ug-theme-default .ug-default-button-play-single.ug-stop-mode.ug-button-hover {
  background-position: 0px -150px;
}
/* --- hide panel button -- */
.ug-default-button-hidepanel {
  position: absolute;
  width: 23px;
  height: 23px;
  z-index: 5;
  cursor: pointer;
}
.ug-default-button-hidepanel .ug-default-button-hidepanel-bg {
  background-color: #000000;
  width: 23px;
  height: 23px;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.4;
  filter: alpha(opacity = 40);
  z-index: 1;
}
.ug-default-button-hidepanel .ug-default-button-hidepanel-tip {
  position: absolute;
  width: 7px;
  height: 7px;
  background-repeat: no-repeat;
  background-image: url('/bower_components/unitegallery/dist//themes/default/images/arrow_down_up.png');
  background-position: 0px 0px;
  z-index: 2;
}
.ug-default-button-hidepanel.ug-button-hover .ug-default-button-hidepanel-tip {
  background-position: 0px -7px;
}
.ug-default-button-hidepanel.ug-button-hidden-mode .ug-default-button-hidepanel-tip {
  background-position: 0px -14px;
}
.ug-default-button-hidepanel.ug-button-hidden-mode.ug-button-hover .ug-default-button-hidepanel-tip {
  background-position: 0px -21px;
}

.ug-gallery-wrapper,
.ug-gallery-wrapper * {
  transition: all !important;
  -o-transition: all !important;
  -webkit-transition: all;
  -moz-transition: all !important;
  -ms-transition: all !important;
  max-width: none;
}
.ug-gallery-wrapper {
  z-index: 0;
}
.ug-item-wrapper img {
  width: auto;
  max-width: none;
  margin: 0px;
  padding: 0px;
}
.ug-videoplayer-wrapper video {
  height: 100%;
  width: 100%;
  visibility: visible !important;
}
.ug-rtl {
  direction: rtl;
}
.ug-gallery-wrapper {
  overflow: hidden;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
}
.ug-error-message {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 20px;
  color: #F30D0D;
}
.ug-error-message-wrapper {
  width: 100%;
  height: 100%;
  border: 1px solid black;
}
.ug-gallery-wrapper .ug-canvas-pie {
  position: absolute;
  z-index: 4;
}
.ug-gallery-wrapper .ug-progress-bar {
  position: absolute;
  overflow: hidden;
  z-index: 4;
}
.ug-gallery-wrapper .ug-progress-bar-inner {
  xposition: absolute;
}
.ug-gallery-wrapper.ug-fullscreen {
  height: 100% !important;
  width: 100% !important;
  max-width: none !important;
  max-height: none !important;
}
.ug-gallery-wrapper.ug-fake-fullscreen {
  position: fixed !important;
  height: auto !important;
  width: auto !important;
  max-width: none !important;
  max-height: none !important;
  margin: 0px !important;
  padding: 0px !important;
  top: 0px !important;
  left: 0px !important;
  bottom: 0px !important;
  right: 0px !important;
  z-index: 999999 !important;
}
.ug-body-fullscreen {
  overflow: hidden !important;
  height: 100% !important;
  width: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
}
.ug-gallery-wrapper .ug-preloader-trans {
  display: block;
  height: 35px;
  width: 32px;
  height: 32px;
  background-image: url('/bower_components/unitegallery/dist//images/loader_skype_trans.gif');
  background-repeat: no-repeat;
}
.ug-gallery-wrapper .ug-slider-wrapper {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  background-color: #000000;
}
.ug-slider-wrapper .ug-slide-wrapper {
  position: absolute;
}
.ug-slide-wrapper.ug-slide-clickable {
  cursor: pointer;
}
.ug-slider-wrapper .ug-slider-inner {
  position: absolute;
  z-index: 0;
}
.ug-slider-wrapper .ug-item-wrapper {
  position: absolute;
  overflow: hidden;
}
.ug-slider-wrapper,
.ug-item-wrapper img {
  -webkit-user-select: none;
  /* make unselectable */
  -moz-user-select: none;
  -ms-user-select: none;
  max-width: none !important;
  box-sizing: border-box;
}
.ug-slider-wrapper .ug-slider-preloader {
  position: absolute;
  z-index: 1;
  border: 2px solid #ffffff;
  border-radius: 6px;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
}
.ug-slider-preloader.ug-loader1 {
  width: 30px;
  height: 30px;
  background-image: url('/bower_components/unitegallery/dist//images/loader-white1.gif');
}
.ug-slider-preloader.ug-loader1.ug-loader-black {
  background-image: url('/bower_components/unitegallery/dist//images/loader-black1.gif');
}
.ug-slider-preloader.ug-loader2 {
  width: 32px;
  height: 32px;
  background-image: url('/bower_components/unitegallery/dist//images/loader-white2.gif');
}
.ug-slider-preloader.ug-loader2.ug-loader-black {
  background-image: url('/bower_components/unitegallery/dist//images/loader-black2.gif');
}
.ug-slider-preloader.ug-loader3 {
  width: 38px;
  height: 38px;
  background-image: url('/bower_components/unitegallery/dist//images/loader-white3.gif');
}
.ug-slider-preloader.ug-loader3.ug-loader-black {
  background-image: url('/bower_components/unitegallery/dist//images/loader-black3.gif');
}
.ug-slider-preloader.ug-loader4 {
  width: 32px;
  height: 32px;
  background-image: url('/bower_components/unitegallery/dist//images/loader-white4.gif');
  background-color: white;
}
.ug-slider-preloader.ug-loader4.ug-loader-black {
  background-image: url('/bower_components/unitegallery/dist//images/loader-black4.gif');
}
.ug-slider-preloader.ug-loader5 {
  width: 60px;
  height: 8px;
  background-image: url('/bower_components/unitegallery/dist//images/loader-white5.gif');
  background-color: white;
  border: none;
  border-radius: 0px;
}
.ug-slider-preloader.ug-loader5.ug-loader-black {
  background-image: url('/bower_components/unitegallery/dist//images/loader-black5.gif');
  border: 2px solid #000000;
}
.ug-slider-preloader.ug-loader6 {
  width: 32px;
  height: 32px;
  background-image: url('/bower_components/unitegallery/dist//images/loader-white6.gif');
}
.ug-slider-preloader.ug-loader6.ug-loader-black {
  background-image: url('/bower_components/unitegallery/dist//images/loader-black6.gif');
}
.ug-slider-preloader.ug-loader7 {
  width: 32px;
  height: 10px;
  background-image: url('/bower_components/unitegallery/dist//images/loader-white7.gif');
  border-width: 3px;
  border-radius: 3px;
}
.ug-slider-preloader.ug-loader7.ug-loader-black {
  background-image: url('/bower_components/unitegallery/dist//images/loader-black7.gif');
}
.ug-slider-preloader.ug-loader-black {
  border-color: #000000;
  background-color: #000000;
}
.ug-slider-preloader.ug-loader-nobg {
  background-color: transparent;
}
.ug-slider-wrapper .ug-button-videoplay {
  position: absolute;
  z-index: 2;
  cursor: pointer;
}
.ug-slider-wrapper .ug-button-videoplay.ug-type-square {
  width: 86px;
  height: 66px;
  background-image: url('/bower_components/unitegallery/dist//images/play-button-square.png');
  background-position: 0px -66px;
}
.ug-slider-wrapper .ug-button-videoplay.ug-type-square.ug-button-hover {
  background-position: 0px 0px;
}
.ug-slider-wrapper .ug-button-videoplay.ug-type-round {
  width: 76px;
  height: 76px;
  background-image: url('/bower_components/unitegallery/dist//images/play-button-round.png');
  opacity: 0.9;
  filter: alpha(opacity = 90);
  transition: all 0.3s ease 0s !important;
}
.ug-slider-wrapper .ug-button-videoplay.ug-type-round.ug-button-hover {
  opacity: 1;
  filter: alpha(opacity = 100);
  transition: all 0.3s ease 0s !important;
}
.ug-gallery-wrapper .ug-videoplayer {
  position: absolute;
  z-index: 100;
  background-color: #000000;
  background-image: url('/bower_components/unitegallery/dist//images/loader-black1.gif');
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.ug-videoplayer .ug-videoplayer-wrapper {
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-image: url('/bower_components/unitegallery/dist//images/loader-black1.gif');
  background-repeat: no-repeat;
  background-position: center center;
}
.ug-videoplayer .ug-videoplayer-button-close {
  position: absolute;
  height: 64px;
  width: 64px;
  background-image: url('/bower_components/unitegallery/dist//images/button-close.png');
  cursor: pointer;
  z-index: 1000;
}
.ug-videoplayer .ug-videoplayer-button-close:hover {
  background-position: 0px -64px;
}
.ug-gallery-wrapper .ug-thumbs-strip,
.ug-gallery-wrapper .ug-thumbs-grid {
  position: absolute;
  overflow: hidden;
  -webkit-user-select: none;
  /* make unselectable */
  -moz-user-select: none;
  -ms-user-select: none;
  xbackground-color: green;
  z-index: 10;
}
.ug-thumbs-strip.ug-dragging {
  xcursor: -webkit-grab;
  xcursor: -moz-grab;
}
.ug-gallery-wrapper .ug-thumbs-strip-inner,
.ug-gallery-wrapper .ug-thumbs-grid-inner {
  position: absolute;
}
.ug-thumb-wrapper.ug-thumb-generated {
  position: absolute;
  overflow: hidden;
  -webkit-user-select: none;
  /* make unselectable */
  -moz-user-select: none;
  -ms-user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-loader,
.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-error {
  position: absolute;
  top: 0px;
  left: 0px;
  background-repeat: no-repeat;
  background-position: center center;
}
.ug-thumb-wrapper .ug-thumb-border-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 4;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-style: solid;
}
.ug-thumb-wrapper .ug-thumb-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
}
.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-loader-dark {
  background-image: url('/bower_components/unitegallery/dist//images/loader.gif');
}
.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-loader-bright {
  background-image: url('/bower_components/unitegallery/dist//images/loader_bright.gif');
}
.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-error {
  background-image: url('/bower_components/unitegallery/dist//images/not_loaded.png');
}
.ug-thumb-wrapper.ug-thumb-generated img {
  position: absolute;
  z-index: 1;
  max-width: none !important;
}
.ug-thumb-wrapper.ug-thumb-generated img.ug-thumb-image-overlay {
  position: absolute;
  z-index: 2;
  max-width: none !important;
}
/* bw desaturate effect lightweight */
img.ug-bw-effect {
  filter: grayscale(100%);
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android */
  -moz-filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
/* blur effect desaturate effect lightweight */
img.ug-blur-effect {
  filter: blur(0.8px);
  -webkit-filter: blur(0.8px);
  -moz-filter: blur(0.8px);
  -o-filter: blur(0.8px);
  -ms-filter: blur(0.8px);
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id='blur'><feGaussianBlur stdDeviation=\'0.8\' /></filter></svg>#blur");
  /* Firefox 10+, Firefox on Android */
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0.8');
}
img.ug-sepia-effect {
  -webkit-filter: sepia(1);
  -webkit-filter: sepia(100%);
  -moz-filter: sepia(100%);
  -ms-filter: sepia(100%);
  -o-filter: sepia(100%);
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'sepia\'><feColorMatrix values=\'0.14 0.45 0.05 0 0 0.12 0.39 0.04 0 0 0.08 0.28 0.03 0 0 0 0 0 1 0\' /></filter></svg>#sepia");
  /* Firefox 10+, Firefox on Android */
  filter: sepia(100%);
  background-color: #5E2612;
  filter: alpha(opacity = 50);
  zoom: 1;
}
.ug-gallery-wrapper .ug-bullets {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
}
.ug-gallery-wrapper .ug-bullets .ug-bullet {
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
  float: left;
  z-index: 2;
}
.ug-gallery-wrapper .ug-bullets .ug-bullet:first-child {
  margin-left: 0px;
}
.ug-gallery-wrapper .ug-textpanel {
  position: absolute;
  overflow: hidden;
}
.ug-slider-wrapper .ug-textpanel {
  z-index: 2;
}
.ug-gallery-wrapper .ug-textpanel-bg,
.ug-gallery-wrapper .ug-textpanel-title,
.ug-gallery-wrapper .ug-textpanel-description {
  display: block;
  position: absolute;
  margin: 0px;
  padding: 0px;
}
.ug-gallery-wrapper .ug-textpanel-bg {
  background-color: #000000;
  opacity: 0.4;
  filter: alpha(opacity = 40);
  z-index: 1;
}
.ug-textpanel-textwrapper {
  overflow: hidden;
}
.ug-gallery-wrapper .ug-textpanel-title {
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: left;
  z-index: 2;
}
.ug-gallery-wrapper .ug-textpanel-description {
  font-size: 14px;
  color: white;
  text-align: left;
  z-index: 3;
}
.ug-slider-wrapper .ug-zoompanel {
  position: absolute;
  top: 200px;
  left: 100px;
}
.ug-zoompanel .ug-zoompanel-button {
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
}
.ug-gallery-wrapper .ug-arrow-left {
  position: absolute;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
}
.ug-gallery-wrapper .ug-arrow-right {
  position: absolute;
  background-repeat: no-repeat;
  cursor: pointer;
}
.ug-gallery-wrapper .ug-grid-panel {
  position: absolute;
  background-color: #000000;
  xbackground-color: blue;
  z-index: 2;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow {
  position: absolute;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 11;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow.ug-button-disabled {
  cursor: default;
}
/* handle tip */
.ug-gallery-wrapper .ug-panel-handle-tip {
  position: absolute;
  z-index: 13;
  cursor: pointer;
}
.ug-gallery-wrapper .ug-overlay-disabled {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.3;
  filter: alpha(opacity=30);
  background-image: url('/bower_components/unitegallery/dist//images/cover-grid.png');
}
.ug-gallery-wrapper .ug-strip-panel {
  position: absolute;
  background-color: #000000;
  z-index: 2;
}
.ug-strip-panel .ug-strip-arrow {
  position: absolute;
  cursor: pointer;
}
.ug-strip-panel .ug-strip-arrow.ug-button-disabled {
  cursor: default;
}
.ug-strip-panel .ug-strip-arrow-tip {
  position: absolute;
}
.ug-thumb-wrapper.ug-tile {
  display: block;
  position: absolute;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}
.ug-tile .ug-tile-cloneswrapper {
  position: absolute;
  width: 0px;
  height: 0px;
  top: 0px;
  left: 0px;
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity = 90);
}
/* transit something */
/* transit fixed size items */
/* transit image overlay */
.ug-tiles-transit.ug-tiles-transition-active .ug-thumb-wrapper.ug-tile,
.ug-tiles-transit.ug-tiles-transition-active .ug-thumb-wrapper.ug-tile .ug-trans-enabled {
  transition: left 0.7s ease 0s, top 0.7s ease 0s, width 0.7s ease 0s, height 0.7s ease 0s !important;
}
.ug-thumb-wrapper.ug-tile.ug-tile-clickable {
  cursor: pointer;
}
.ug-thumb-wrapper.ug-tile .ug-image-container {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  top: 0px;
  left: 0px;
}
.ug-thumb-wrapper.ug-tile img.ug-thumb-image {
  -webkit-user-select: none;
  /* make unselectable */
  -moz-user-select: none;
  -ms-user-select: none;
  max-width: none !important;
  max-height: none !important;
  display: block;
  border: none;
  padding: 0px !important;
  margin: 0px !important;
  width: auto;
  height: auto;
  position: absolute;
}
.ug-thumb-wrapper.ug-tile .ug-tile-image-overlay {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  top: 0px;
  left: 0px;
}
.ug-thumb-wrapper.ug-tile .ug-tile-image-overlay img {
  width: 100%;
  max-width: none !important;
}
.ug-tiletype-justified .ug-thumb-wrapper.ug-tile .ug-tile-image-overlay img {
  height: 100%;
  max-width: none !important;
}
.ug-thumb-wrapper.ug-tile .ug-tile-icon {
  position: absolute;
  width: 38px;
  height: 38px;
  background-repeat: no-repeat;
  background-position: 0 0;
  xbackground-color: green;
  z-index: 6;
  top: 1px;
  left: 1px;
  cursor: pointer;
}
.ug-thumb-wrapper.ug-tile .ug-tile-icon.ug-icon-link {
  background-image: url('/bower_components/unitegallery/dist//images/icon-link32.png');
}
.ug-thumb-wrapper.ug-tile .ug-tile-icon.ug-icon-zoom {
  background-image: url('/bower_components/unitegallery/dist//images/icon-zoom32.png');
}
.ug-thumb-wrapper.ug-tile .ug-tile-icon.ug-icon-play {
  background-image: url('/bower_components/unitegallery/dist//images/icon-play32.png');
}
.ug-thumb-wrapper.ug-tile .ug-tile-icon:hover {
  background-position: 0 -38px;
}
.ug-thumbs-grid .ug-tile-image-overlay img {
  width: auto;
  height: auto;
  position: absolute;
  max-width: none !important;
}
/* text panel on tiles*/
.ug-tile .ug-textpanel {
  z-index: 5;
  cursor: default;
}
.ug-tile.ug-tile-clickable .ug-textpanel {
  cursor: pointer;
}
.ug-tile .ug-textpanel-title {
  font-size: 14px;
  font-weight: bold;
}
.ug-gallery-wrapper .ug-carousel-wrapper {
  position: absolute;
  overflow: hidden;
}
.ug-gallery-wrapper .ug-carousel-inner {
  position: absolute;
}
.ug-gallery-wrapper.ug-lightbox {
  display: none;
  position: fixed;
  border: none;
  padding: 0px;
  margin: 0px;
  outline: invert none medium;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: none !important;
  max-height: none !important;
  z-index: 20;
}
.ug-lightbox .ug-lightbox-top-panel {
  position: absolute;
  z-index: 2;
}
.ug-lightbox .ug-lightbox-top-panel-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000000;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.ug-lightbox .ug-lightbox-overlay {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 1;
}
.ug-lightbox .ug-slider-wrapper {
  z-index: 1;
  xbackground-color: green;
  background-color: transparent !important;
}
.ug-lightbox .ug-textpanel {
  z-index: 2;
}
.ug-lightbox .ug-lightbox-arrow-left,
.ug-lightbox .ug-lightbox-arrow-right {
  position: absolute;
  width: 50px;
  height: 55px;
  background-repeat: no-repeat;
  background-image: url('/bower_components/unitegallery/dist//images/lightbox-arrow-left.png');
  background-position: 0px 0px;
  z-index: 3;
  cursor: pointer;
}
.ug-lightbox .ug-lightbox-arrow-right {
  background-image: url('/bower_components/unitegallery/dist//images/lightbox-arrow-right.png');
}
.ug-lightbox .ug-lightbox-button-close {
  position: absolute;
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-image: url('/bower_components/unitegallery/dist//images/lightbox-icon-close.png');
  background-position: 0px 0px;
  z-index: 4;
  cursor: pointer;
}
.ug-lightbox-compact .ug-lightbox-button-close {
  width: 45px;
  height: 41px;
  background-image: url('/bower_components/unitegallery/dist//images/lightbox-icon-close-compact2.png');
}
.ug-lightbox .ug-lightbox-arrow-left.ug-arrow-hover,
.ug-lightbox .ug-lightbox-arrow-right.ug-arrow-hover {
  background-position: 0px -55px;
}
.ug-lightbox .ug-lightbox-button-close.ug-button-hover {
  background-position: 0px -35px;
}
.ug-lightbox-compact .ug-lightbox-button-close.ug-button-hover {
  background-position: 0px -40px;
}
.ug-lightbox .ug-lightbox-numbers {
  position: absolute;
  color: #e5e5e5;
  font-size: 12px;
  top: 14px;
  left: 8px;
  z-index: 3;
}
.ug-lightbox-compact .ug-lightbox-numbers {
  font-size: 14px;
  padding-right: 5px;
  padding-top: 7px;
}
.ug-lightbox .ug-textpanel-title,
.ug-lightbox .ug-textpanel-description {
  font-size: 14px;
  font-weight: normal;
  color: #e5e5e5;
}
.ug-lightbox-compact .ug-textpanel-title,
.ug-lightbox-compact .ug-textpanel-description {
  color: #F3F3F3;
  font-size: 16px;
}
.ug-tabs-wrapper {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
}
.ug-tabs-wrapper a.ug-tab {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 5px;
  background-color: #333333;
  border-radius: 3px;
  font-size: 12px;
  font-weight: normal;
  border: 1px solid #969696;
}
.ug-tabs-wrapper a.ug-tab:hover {
  background-color: #707070 !important;
}
.ug-tabs-wrapper a.ug-tab.ug-tab-selected,
.ug-tabs-wrapper a.ug-tab.ug-tab-selected:hover {
  background-color: #2077A2 !important;
  cursor: default !important;
}
.ug-tabs-wrapper a.ug-tab:first-child {
  margin-left: 0px !important;
}
/* --- arrows -- */
.ug-arrow-left.ug-skin-default,
.ug-arrow-right.ug-skin-default {
  width: 15px;
  height: 30px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/slider_arrow_left.png');
  background-position: 0px 0px;
}
.ug-arrow-right.ug-skin-default {
  background-image: url('/bower_components/unitegallery/dist/skins/default/slider_arrow_right.png');
}
.ug-arrow-left.ug-skin-default.ug-arrow-hover,
.ug-arrow-right.ug-skin-default.ug-arrow-hover {
  background-position: 0px -30px;
}
.ug-arrow-left.ug-skin-default.ug-arrow-disabled,
.ug-arrow-right.ug-skin-default.ug-arrow-disabled {
  background-position: 0px -60px;
}
/* --- bullets -- */
.ug-bullets.ug-skin-default .ug-bullet {
  width: 15px;
  height: 15px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/slider_bullets.png');
  background-position: top left;
  margin-left: 5px;
}
.ug-bullets.ug-skin-default .ug-bullet.ug-bullet-active {
  background-position: bottom left;
}
.ug-bullets.ug-skin-default.ug-bullets-gray .ug-bullet {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_bullets_gray.png');
}
.ug-bullets.ug-skin-default.ug-bullets-blue .ug-bullet {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_bullets_blue.png');
}
.ug-bullets.ug-skin-default.ug-bullets-brown .ug-bullet {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_bullets_brown.png');
}
.ug-bullets.ug-skin-default.ug-bullets-green .ug-bullet {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_bullets_green.png');
}
.ug-bullets.ug-skin-default.ug-bullets-red .ug-bullet {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_bullets_red.png');
}
.ug-button-tile-navigation {
  width: 36px;
  height: 36px;
  background-position: top left;
  cursor: pointer;
  float: left;
}
.ug-button-tile-navigation.ug-button-tile-left {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_button_left.png');
}
.ug-button-tile-navigation.ug-button-tile-right {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_button_right.png');
}
.ug-button-tile-navigation:hover {
  background-position: bottom left;
}
.ug-button-tile-navigation.ug-button-disabled {
  opacity: 0.6;
}
.ug-button-tile-navigation.ug-button-disabled:hover {
  background-position: top left;
}
.ug-button-tile-navigation.ug-button-tile-play {
  background-image: url('/bower_components/unitegallery/dist/skins/default/tile_button_play_pause.png');
  width: 35px;
}
.ug-button-tile-navigation.ug-button-tile-play.ug-pause-icon {
  background-position: bottom left;
}
.ug-button-tile-navigation.ug-button-tile-play:hover {
  background-position: top left;
}
.ug-button-tile-navigation.ug-button-tile-play.ug-pause-icon:hover {
  background-position: bottom left;
}
/* --- play button -- */
.ug-button-play.ug-skin-default {
  position: absolute;
  background-image: url('/bower_components/unitegallery/dist/skins/default/button_playpause.png');
  width: 14px;
  height: 17px;
  cursor: pointer;
  background-position: 0px 0px;
}
.ug-button-play.ug-skin-default.ug-button-hover {
  background-position: 0px -17px;
}
.ug-button-play.ug-skin-default.ug-stop-mode {
  background-position: 0px -34px;
}
.ug-button-play.ug-skin-default.ug-stop-mode.ug-button-hover {
  background-position: 0px -51px;
}
/* --- fullscreen button -- */
.ug-button-fullscreen.ug-skin-default {
  position: absolute;
  background-image: url('/bower_components/unitegallery/dist/skins/default/button_fullscreen.png');
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-position: 0px 0px;
}
.ug-button-fullscreen.ug-skin-default.ug-button-hover {
  background-position: 0px -16px;
}
.ug-button-fullscreen.ug-skin-default.ug-fullscreenmode {
  background-position: 0px -32px;
}
.ug-button-fullscreen.ug-skin-default.ug-fullscreenmode.ug-button-hover {
  background-position: 0px -48px;
}
/* --- zoom panel --- */
.ug-zoompanel.ug-skin-default .ug-zoompanel-button {
  background-repeat: no-repeat;
  cursor: pointer;
  height: 23px;
  width: 23px;
  margin-top: 5px;
  background-position: 0px 0px;
}
.ug-zoompanel.ug-skin-default .ug-zoompanel-plus {
  margin-top: 0px;
}
.ug-zoompanel.ug-skin-default .ug-zoompanel-plus {
  background-image: url('/bower_components/unitegallery/dist/skins/default/icon_zoom_plus.png');
}
.ug-zoompanel.ug-skin-default .ug-zoompanel-minus {
  background-image: url('/bower_components/unitegallery/dist/skins/default/icon_zoom_minus.png');
}
.ug-zoompanel.ug-skin-default .ug-zoompanel-return {
  background-image: url('/bower_components/unitegallery/dist/skins/default/icon_zoom_back.png');
}
.ug-zoompanel.ug-skin-default .ug-zoompanel-button.ug-button-hover {
  background-position: 0px -23px;
}
.ug-zoompanel.ug-skin-default .ug-zoompanel-button-disabled,
.ug-zoompanel.ug-skin-default .ug-zoompanel-button.ug-button-hover.ug-zoompanel-button-disabled {
  background-position: 0px -46px;
  cursor: default;
}
/* --- Grid Panel Arrows -- */
/*--- down arrow ---*/
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-down.ug-skin-default {
  width: 30px;
  height: 15px;
  background-position: 0px 0px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/arrow_grid_down.png');
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-down.ug-skin-default.ug-button-hover {
  background-position: 0px -15px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-down.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-down.ug-skin-default.ug-button-disabled {
  background-position: 0px -30px;
}
/*--- up arrow ---*/
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-up.ug-skin-default {
  width: 30px;
  height: 15px;
  background-position: 0px 0px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/arrow_grid_up.png');
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-up.ug-skin-default.ug-button-hover {
  background-position: 0px -15px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-up.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-up.ug-skin-default.ug-button-disabled {
  background-position: 0px -30px;
}
/*--- left arrow ---*/
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left.ug-skin-default {
  width: 40px;
  height: 40px;
  background-position: 0px -40px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/grid_arrow_left.png');
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left.ug-skin-default.ug-button-hover {
  background-position: 0px 0px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left.ug-skin-default.ug-button-disabled {
  background-position: 0px -80px;
}
/*--- right arrow ---*/
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right.ug-skin-default {
  width: 40px;
  height: 40px;
  background-position: 0px -40px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/grid_arrow_right.png');
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right.ug-skin-default.ug-button-hover {
  background-position: 0px 0px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right.ug-skin-default.ug-button-disabled {
  background-position: 0px -80px;
}
/*--- left arrow - horizontal panel type ---*/
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left-hortype.ug-skin-default {
  width: 15px;
  height: 30px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/slider_arrow_left.png');
  background-position: 0px 0px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left-hortype.ug-skin-default.ug-button-hover {
  background-position: 0px -30px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left-hortype.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left-hortype.ug-skin-default.ug-button-disabled {
  background-position: 0px -60px;
}
/*--- right arrow - horizontal panel type ---*/
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right-hortype.ug-skin-default {
  width: 15px;
  height: 30px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/slider_arrow_right.png');
  background-position: 0px 0px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right-hortype.ug-skin-default.ug-button-hover {
  background-position: 0px -30px;
}
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right-hortype.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right-hortype.ug-skin-default.ug-button-disabled {
  background-position: 0px -60px;
}
/* --- Strip Panel Arrows -- */
/* left and right arrows */
.ug-strip-panel .ug-strip-arrow-left.ug-skin-default,
.ug-strip-panel .ug-strip-arrow-right.ug-skin-default {
  width: 25px;
  height: 50px;
}
.ug-strip-panel .ug-strip-arrow-left.ug-skin-default .ug-strip-arrow-tip,
.ug-strip-panel .ug-strip-arrow-right.ug-skin-default .ug-strip-arrow-tip {
  background-position: 0px 0px;
  background-repeat: no-repeat;
  width: 4px;
  height: 7px;
}
.ug-strip-panel .ug-strip-arrow-left.ug-skin-default .ug-strip-arrow-tip {
  background-image: url('/bower_components/unitegallery/dist/skins/default/arrows_strip_left.png');
}
.ug-strip-panel .ug-strip-arrow-right.ug-skin-default .ug-strip-arrow-tip {
  background-image: url('/bower_components/unitegallery/dist/skins/default/arrows_strip_right.png');
}
.ug-strip-panel .ug-strip-arrow-left.ug-skin-default.ug-button-hover .ug-strip-arrow-tip,
.ug-strip-panel .ug-strip-arrow-right.ug-skin-default.ug-button-hover .ug-strip-arrow-tip {
  background-position: 0px -7px;
}
/* up and down arrows */
.ug-strip-panel .ug-strip-arrow-up.ug-skin-default,
.ug-strip-panel .ug-strip-arrow-down.ug-skin-default {
  height: 25px;
  width: 50px;
}
.ug-strip-panel .ug-strip-arrow-up.ug-skin-default .ug-strip-arrow-tip,
.ug-strip-panel .ug-strip-arrow-down.ug-skin-default .ug-strip-arrow-tip {
  background-position: 0px 0px;
  background-repeat: no-repeat;
  width: 7px;
  height: 4px;
}
.ug-strip-panel .ug-strip-arrow-up.ug-skin-default .ug-strip-arrow-tip {
  background-image: url('/bower_components/unitegallery/dist/skins/default/arrows_strip_up.png');
}
.ug-strip-panel .ug-strip-arrow-down.ug-skin-default .ug-strip-arrow-tip {
  background-image: url('/bower_components/unitegallery/dist/skins/default/arrows_strip_down.png');
}
.ug-strip-panel .ug-strip-arrow-up.ug-skin-default.ug-button-hover .ug-strip-arrow-tip,
.ug-strip-panel .ug-strip-arrow-down.ug-skin-default.ug-button-hover .ug-strip-arrow-tip {
  background-position: -7px 0px;
}
.ug-strip-panel .ug-strip-arrow.ug-skin-default.ug-button-disabled .ug-strip-arrow-tip {
  background-position: 0px 0px;
  opacity: 0.5;
  filter: alpha(opacity = 50);
}
/* --- Panel Handle Tip -- */
/* left and right handle */
.ug-gallery-wrapper .ug-handle-tip-left.ug-skin-default,
.ug-gallery-wrapper .ug-handle-tip-right.ug-skin-default {
  width: 22px;
  height: 36px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/grid_handle_black_left.png');
  background-repeat: no-repeat;
  background-position: 0px -36px;
}
.ug-gallery-wrapper .ug-handle-tip-right.ug-skin-default {
  background-image: url('/bower_components/unitegallery/dist/skins/default/grid_handle_black_right.png');
  background-position: 0px -36px;
}
.ug-gallery-wrapper .ug-handle-tip-left.ug-button-hover.ug-skin-default,
.ug-gallery-wrapper .ug-handle-tip-right.ug-button-hover.ug-skin-default {
  background-position: 0px 0px;
}
.ug-gallery-wrapper .ug-handle-tip-left.ug-skin-default.ug-button-closed,
.ug-gallery-wrapper .ug-handle-tip-right.ug-skin-default.ug-button-closed {
  background-position: 0px -108px;
}
.ug-gallery-wrapper .ug-handle-tip-left.ug-skin-default.ug-button-closed.ug-button-hover,
.ug-gallery-wrapper .ug-handle-tip-right.ug-skin-default.ug-button-closed.ug-button-hover {
  background-position: 0px -72px;
}
/* top and bottom handle */
.ug-gallery-wrapper .ug-handle-tip-top.ug-skin-default,
.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default {
  width: 36px;
  height: 22px;
  background-image: url('/bower_components/unitegallery/dist/skins/default/grid_handle_black_top.png');
  background-repeat: no-repeat;
  background-position: -36px 0px;
}
.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default {
  background-image: url('/bower_components/unitegallery/dist/skins/default/grid_handle_black_bottom.png');
  background-position: -36px 0px;
}
.ug-gallery-wrapper .ug-handle-tip-top.ug-skin-default.ug-button-hover,
.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default.ug-button-hover {
  background-position: 0px 0px;
}
.ug-gallery-wrapper .ug-handle-tip-top.ug-skin-default.ug-button-closed,
.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default.ug-button-closed {
  background-position: -108px 0px;
}
.ug-gallery-wrapper .ug-handle-tip-top.ug-skin-default.ug-button-closed.ug-button-hover,
.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default.ug-button-closed.ug-button-hover {
  background-position: -72px 0px;
}
