.timeline-container {
    max-height: 2000px;
    min-height: 250px;
    overflow: hidden;
    .add-post {
        margin: 1px 26px 20px;
        .add-post-form {
            .form-group {
                margin-bottom: 5px;
                label {
                    margin-bottom: 0px;
                }
            }
            input[type='text'] {
                width: 250px;
                display: inline-block;
                height: 34px;
            }
            textarea {
                height: 80px;
                width: 100%;
            }
            .dropdown {
                display: inline-block;
                .dropdown-menu[role=input] {
                    padding: 10px 1px 5px 20px;
                    input {
                        margin-right: 10px;
                    }
                }
                button {
                    height: 35px;
                }
            }
        }
    }
    form.edit-post-form{
        .checkbox{
            margin-left: 20px;
        }
    }
    .timeline {
        .timeline-item{
            .timeline-item-text{
                .post_gallery {
                    a {
                        opacity: 1;
                        img {
                            opacity: 1;
                        }
                    }
                }
            }
        }
        div.new-comment-container {
            margin: 5px 0 15px 0;
            img.img-sm {
                margin-top: 3px;
                width: 35px !important;
                height: 35px !important;
            }
            textarea {
                resize: none;
                height: 40px;
            }
            .btn {
                height: 40px;
                .valigner {
                    height: 100%;
                    vertical-align: middle;
                    width: 0px;
                    display: inline-block;
                }
                i {
                    vertical-align: middle;
                }
            }
        }
        div.timeline-item-text {
            img {
                max-width: 100%;
            }
            .post_gallery {
            }

            .last-discussion-comments{
                li div{
                    position: relative;
                    max-height: 100px;
                    overflow: hidden;
                    mix-blend-mode: hard-light;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 30px;
                        height: 75px;
                        width: 100%;
                        background: linear-gradient(transparent, gray);
                    }
                }
            }
            .categories-results {
                width: 100%;
                max-width: 790px;
                .members-in-category {
                    width: 100%;
                    td:nth-child(1), th:nth-child(1) {
                        width: 45px;
                        text-align: right;
                    }
                    td:nth-child(2), th:nth-child(2) {
                    }
                    td:nth-child(3), th:nth-child(3) {
                        width: 100px;
                        text-align: right;
                    }
                    td:nth-child(4), th:nth-child(4) {
                        width: 100px;
                        text-align: right;
                    }
                }
                &>tbody>tr {
                    border-top: 1px solid lightgrey
                }
                td, th {
                    vertical-align: top;
                    text-align: left;
                }
            }
        }
        .timeline-item>.public-post{
            color: #999;
            float: right;
            padding: 12px;
            font-size: 12px;
        }
        div.box-comments {
            margin-top: 20px;
            padding: 10px;
            max-height: 320px;
            border-top: 1px solid #eee;
            padding-top: 5px;
            .slim-scroll-wrap {
                max-height: 255px;
                div.comment-header {
                    height: 30px;
                    span.username {
                        padding: 4px;
                        position: relative;
                        left: 10px;
                    }
                }
                div.comment-body {
                    padding-bottom: 4px;
                    .text-muted {
                        margin-right: 10px;
                    }
                }
            }
            .list-inline {
                position: relative;
                top: -18px;
            }
        }
    }
}

