.survey{
    .surveyContainer{
    }
    .surveyContainerMe{
        position: relative;
        top: -41.5px;
        .filled-stars{
            color: #dd4b39;
        }
        .star{
            margin: 0 12px;
            position: relative;
            &:first-of-type{
                left:-1px;
            }
            &:nth-of-type(2){
                left:-0.5px;;
            }
            &:nth-of-type(3){
                left:-1px;
            }
            &:nth-of-type(4){
                left:-1px;
            }
            &:nth-of-type(5){
                left:0px;
            }
        }
        div.caption{
            position: relative;
            top: 5px;
            span.label{
                color:gray!important;
                background: none!important;
            }
        }
    }
}